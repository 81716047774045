import { Component } from "react";

export default class ReturnPage extends Component {
    render() {
        const { OnClick, title = 'Voltar' } = this.props
        return (
            <>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div></div>
                    <div>
                        <button className="btn btn-success" onClick={(e) => { OnClick(e) }}>
                           {title}
                        </button>
                    </div>
                </div>
            </>
        )
    }
}