import { Component } from "react";
import MessageDisplay from "../../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../../messages/load";
import { Form, BtnForm } from "../../../helpers/formData";
import Select from 'react-select'

export default class Convenios extends Component {
    constructor(props) {
        super(props)

        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            typePost: false,
            conveniosCompany: [],
            arrayConvenios: []
        }
    }

    handleSelectChange = (selectedOptions) => {
        let convenios = [...this.state.conveniosCompany];
        convenios = selectedOptions;
        this.setState(
            {
                conveniosCompany: convenios,
                arrayConvenios: convenios.map(option => option.value)
            });
    };

    componentDidUpdate(prevProps) {

        if (this.props.company !== prevProps.company) {
            if (this.props.company.convenios.length) {
                const convenios = new Array();
                this.props.company.convenios.forEach((e) => {
                    convenios.push({
                        value: e.value,
                        label: e.name
                    })
                });
                this.setState({
                    conveniosCompany: convenios,

                });
            }
        }
    }

    async submitConvenios(e) {
        e.preventDefault()
        const { company } = this.props
        const { conveniosCompany } = this.state
        const msg = new MessageDisplay();
        const tokenLocalStorage = window.localStorage.getItem("token");
        const tokenHash = window.localStorage.getItem("tokenHash");
        const login = window.localStorage.getItem("login");

        const form = e.target;
        const formData = new FormData(form);
        const serializedForm = Object.fromEntries(formData.entries());
        serializedForm._id = company._id
        serializedForm.convenios = conveniosCompany

        startLoad();

        fetch(`${process.env.REACT_APP_HTTP}/select-convenios/${login}/${tokenHash}`, {
            method: "POST",
            crossDomain: true,
            headers: { "Content-Type": "application/json", "Accept": "application/json", authorization: `Bearer ${tokenLocalStorage}` },
            body: JSON.stringify({
                ...serializedForm
            }),
        })
            .then((res) => res.json())
            .then(async (res) => {
                stopLoad();
                if (res.error) {
                    return msg.error("Convenios", res.data);
                }

                return msg.success("Convenios", res.data);

            })
            .catch((e) => {
                stopLoad();
                msg.error("Server", "Not connect");
            });
    }

    render() {
        const { conveniosCompany, auth } = this.state

        let arrayConvenios = []
        if (auth.level == 'master') {
            arrayConvenios = [
                { value: 'inss', label: 'INSS' },
                { value: 'seguros', label: 'SEGUROS' },
                { value: 'siape', label: 'SIAPE' },
                { value: 'gov', label: 'GOV' },
                { value: 'fgts', label: 'FGTS' },
            ]
        } else {
            if (auth.company_id?.convenios && auth.company_id.convenios.length > 0) {
                arrayConvenios = auth.company_id.convenios.map((e) => {
                    return ({
                        value: e.value,
                        label: e.name
                    })
                })
            }
        }


        return (
            <>
                <Form title={'Convênios Empresa'} onSubmit={(e) => this.submitConvenios(e)}>
                    <div className="col-12">
                        <label className="form-label">Selecionar Convênios</label>
                        <Select
                            formatGroupLabel={'Equipes'}
                            isMulti
                            name="teams"
                            options={arrayConvenios}
                            value={conveniosCompany.filter(option => {
                                return arrayConvenios.some(obj => obj.value === option.value);
                            })}
                            onChange={this.handleSelectChange}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                    </div>

                    <BtnForm name={'Atualizar Convênios'} />
                </Form>
            </>
        )
    }
}