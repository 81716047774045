import { Component } from "react";
import PageTitle from "../../../helpers/PageTitle";
import { Form, BtnNext, BtnForm, Input } from "../../../helpers/formData";
import CreateMailing from "../create";
import ReturnPage from "../../../helpers/ReturnPage";
import MessageDisplay from "../../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../../messages/load";
import DataMailing from "./data_mailing/data_mailing";
import CaracteristicasFGTS from "./fgts_components/caracteristicas";
import BeneficioFGTS from "./fgts_components/beneficios";

import Mailing from "../mailing";

export default class CreateMailingFgts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            newComponent: '',
            componentesList: '',
            show: false,
            auth: localStorage.getItem("user"),
            newComponent: "",
            step_limit: 8,
            type_mailing: 'fgts',
            step: 0,
            showCadastra: 0,
            componentes: [
                {
                    id: 'todos',
                    type: "Dados do Mailing",
                    icon: 'fa fa-home',
                    componemt: <DataMailing />,
                },
                {
                    id: 'caracteristicas',
                    type: "Caracteristicas FGTS",
                    icon: 'fa fa-user',
                    componemt: <CaracteristicasFGTS />,
                },
                // {
                //     id: 'beneficios',
                //     type: "Benefícios",
                //     icon: 'fa fa-gavel',
                //     componemt: <BeneficioFGTS />,
                // },
            ],
        }
    }

    async componentDidMount() {
        const stepCount = document.querySelectorAll('.count-step')
        console.log(stepCount)
        this.setState({
            listWidthStep: 100 / stepCount.length
        })
    }

    returPage = () => {
        this.setState({
            newComponent: <CreateMailing />
        })
    };

    submitForm(e) {
        e.preventDefault();
        const msg = new MessageDisplay();

        const form = e.target;
        const formData = new FormData(form);
        const serializedForm = Object.fromEntries(formData.entries());
        serializedForm.port = window.portList
        serializedForm.considerarBeneficios = window.considerarBeneficios
        serializedForm.desconsiderarBeneficios = window.desconsiderarBeneficios
        serializedForm.considerarBancos = window.considerarBancos
        serializedForm.desconsiderarBancos = window.desconsiderarBancos
        // startLoad();
        const tokenLocalStorage = localStorage.getItem("token");
        const tokenHash = localStorage.getItem("tokenHash");
        const login = localStorage.getItem("login");

        fetch(`${process.env.REACT_APP_HTTP}/create-mailing-fgts/${login}/${tokenHash}`, {
            method: 'POST',
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                authorization: `Bearer ${tokenLocalStorage}`
            },
            body: JSON.stringify({
                ...serializedForm
            })
        })
            .then((res) => res.json())
            .then(async (res) => {
                console.log(res)
                if (res.error) {
                    stopLoad();
                    return msg.error("Mailing", res.data);
                }

                stopLoad();
                this.setState({
                    newComponent: <Mailing />
                })
                return msg.success("Mailing", 'Mailing Criado com sucesso');


            })
            .catch(() => {
                stopLoad();
                msg.error("Server", "Not connect");
            });

        e.preventDefault();
    }

    next(id) {
        const requiredFields = document.querySelectorAll('.required')
        let next = true

        for (const field of requiredFields) {
            if (!field.value) {
                field.classList.add('alert_required');
                next = false
            } else {
                next = true
                field.classList.remove('alert_required');
            }
        }

        if (next) {
            const { step_limit } = this.state
            if (step_limit === id + 1) {
                this.setState({ showCadastra: 1, step: id });
            } else {
                this.setState({ step: id });
            }

            if (id + 1 < step_limit) {
                this.setState({ showCadastra: 0 });
            }
        }
    }

    render() {
        const { step, showCadastra, listWidthStep, componentes, newComponent, btnProx, type_mailing } = this.state
        const isHidden = step == 0
        return (
            <>
                {newComponent ? newComponent : (
                    <div className="page-body">
                        <div className="container-fluid">
                            <PageTitle
                                title={"Mailing FGTS"}
                            />
                        </div>

                        <div className="container-fluid">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <ReturnPage OnClick={this.returPage} />
                                </div>
                            </div>
                            <br />
                            <div className="edit-profile">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="f1">
                                            <div className="f1-steps">
                                                <div className="f1-progress" >
                                                    <div
                                                        className="f1-progress-line"
                                                        data-now-value="16.66"
                                                        data-number-of-steps="3"
                                                    ></div>
                                                </div>
                                                <>
                                                    {componentes ? (
                                                        componentes.map((e, index) => {
                                                            const itemNumber = index - 1;

                                                            return (
                                                                <>

                                                                    <div className={`f1-step count-step ${step > itemNumber ? 'active' : ''}`} style={{ width: listWidthStep + '%' }}>
                                                                        <div className="f1-step-icon">
                                                                            <i className={e.icon}></i>
                                                                        </div>
                                                                        <p>{e.type}</p>
                                                                    </div>

                                                                </>
                                                            )
                                                        })
                                                    ) : ''}
                                                </>
                                            </div>
                                        </div>
                                        <Form title={'Mailing para (FGTS)'} onSubmit={(e) => this.submitForm(e)} row={false}>
                                            <Input
                                                type={'hidden'}
                                                valueInput={type_mailing}
                                                classInput={"col-sm-6 col-md-6 col-xl-6"}
                                                name={'type'}
                                                placeholder={"Tipo Mailing"}
                                            />


                                            {componentes ? (
                                                componentes.map((e, index) => {
                                                    return (
                                                        <>

                                                            <div className="row" style={{ display: step === index ? '' : 'none' }}>
                                                                {e.componemt}
                                                            </div>

                                                        </>
                                                    )
                                                })
                                            ) : ''}

                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <BtnNext
                                                    className="btn btn-primary btn-previous"
                                                    onClick={() => this.next(step - 1)}
                                                    isHidden={isHidden} name="Voltar"
                                                />&nbsp;
                                                {(showCadastra === 1) && (
                                                    <></>
                                                )}
                                                {step === componentes.length - 1 ? (
                                                    <BtnForm name={'Cadastrar Mailing'} />
                                                ) : ''}
                                                {step < componentes.length - 1 ? (
                                                    <BtnNext isHidden={showCadastra} onClick={() => this.next(step + 1)} name="Proximo" />
                                                ) : ''}
                                            </div>
                                        </Form>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}