import { Component } from "react";
import Table from 'react-bootstrap/Table';
import { formatBank, formatDate, formatarValorEmDinheiro, listBanks } from "../../../helpers/FuncHelpers";
import { ApiRequest } from "../../../helpers/Api";
import MessageDisplay from "../../../messages/messageDisplay";
import { OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";

export default class RubricasInss extends Component {



    render() {
        const { emprestimos } = this.props
        const bankList = listBanks()

        return (<>
            <div style={{ flexBasis: '100%' }}>
                <div className=" ficha card">
                    <div className="card-header" style={{ padding: "10px" }}>
                        <h6>CONTRATOS ({emprestimos && emprestimos.length > 0 ? emprestimos.length : 0})</h6>
                    </div>

                    <Table responsive>
                        <tr style={{ fontSize: "0.775em" }}>
                            <th >CONTRATO</th>
                            <th >BANCO</th>
                            <th >INÍCIO</th>
                            <th >FIM</th>
                            <th >TAXA</th>
                            <th >VL CONTRATADO</th>
                            <th >PRAZO</th>
                            <th >PARCELA</th>
                            <th >SALDO DEVEDOR</th>
                            <th >VL BRUTO</th>
                        </tr>
                        <tbody>
                            {emprestimos && emprestimos.length > 0 ? emprestimos.map((c) => {
                                const bankName = c?.banco_codigo && c?.banco_codigo !== null ? bankList.filter((e) => e.value == formatBank(c?.banco_codigo))[0] : null
                                return (<>
                                    <tr style={{ fontSize: "0.775em" }}>
                                        <td>{c?.contrato}</td>
                                        <td><b>{c?.banco_codigo && formatBank(c?.banco_codigo)}</b> -
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        {bankName?.label}
                                                    </Tooltip>
                                                }
                                            >
                                                <button className="btn btn-xs btn-primary"><i className="fa fa-info"></i>
                                                </button>
                                            </OverlayTrigger>

                                        </td>
                                        <td>{c?.competenciaInicioDesconto ? formatDate(c?.competenciaInicioDesconto) : null}</td>
                                        <td>{c?.competenciaFimDesconto ? formatDate(c?.competenciaFimDesconto) : null}</td>
                                        <td>
                                            {c?.taxa !== 'false' ? `${c?.taxa}%` : null} {c.statusTaxa != 'false' && (<>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Taxa atualizada com sucesso
                                                        </Tooltip>
                                                    }
                                                >
                                                    <button className="btn btn-xs btn-success"><i className="fa fa-check"></i>
                                                    </button>
                                                </OverlayTrigger>

                                            </>)}
                                        </td>
                                        <td>{c?.valorEmprestado ? formatarValorEmDinheiro(c?.valorEmprestado) : null}</td>
                                        <td>{c?.quantidadeParcelas - c?.quantidadeParcelasEmAberto}/{c?.quantidadeParcelas}</td>
                                        <td>{c?.valorParcela ? formatarValorEmDinheiro(c?.valorParcela) : null}</td>
                                        <td>{c?.saldoQuitacao ? formatarValorEmDinheiro(c.saldoQuitacao) : null}</td>
                                        <td>{c?.valorParcela ? formatarValorEmDinheiro(c?.valorParcela * c.quantidadeParcelas) : null}</td>
                                    </tr>
                                </>)
                            }) : <>
                                <tr scope="col">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <p>Esse cliente não possui contratos <b>ATIVOS</b></p>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </>}
                        </tbody>
                    </Table>

                </div>
            </div>
        </>)
    }
}