import { Component } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

export class BtnTooltip extends Component {

    render() {
        const { name, desc, color, icon = false, onClick, id, dataClient, dataType } = this.props
        console.log(dataClient)
        return (
            <>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip">
                            {desc}
                        </Tooltip>
                    }
                >
                    <button
                        data-type={dataType}
                        data-client={dataClient}
                        className={`btn btn-sm btn-${color}`}
                        style={{ marginRight: "5px" }}
                        onClick={(e) => { onClick(e) }}
                        id={id}
                    >
                        {icon && (<><i style={{ marginRight: '5px' }} className={icon}></i></>)}
                        {name}
                    </button>
                </OverlayTrigger>
            </>
        )
    }
}