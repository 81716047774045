import { Component } from "react";
import { FaNetworkWired } from "react-icons/fa";
import { formatDate, formatarBeneficio, formatarCPF, listBeneficios } from "../../../helpers/FuncHelpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
export default class BeneficioInss extends Component {

    render() {
        const { beneficio } = this.props
        let repreLegal = false;
        if (beneficio.possuiRepresentanteLegalProcurador &&
            beneficio.possuiRepresentanteLegalProcurador != false &&
            beneficio.possuiRepresentanteLegalProcurador != 'false'
        )
            repreLegal = true

        const beneficioName = listBeneficios()
        const bName = beneficio?.especie_codigo && beneficio?.especie_codigo !== null ? beneficioName.filter((e) => e.value === beneficio.especie_codigo)[0] : null

        let NPossui = <span style={{ fontSize: '0.875em' }}>NÃO</span>
        return (
            <>
                <div className="col-sm-6 col-xl-6">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5><FaNetworkWired /> Benefício</h5>
                            <div>
                                <span><b>{beneficio.beneficio ? formatarBeneficio(beneficio.beneficio) : null}</b>
                                </span>
                            </div>
                        </div>
                        <div className="card-body" style={{ padding: '5px 10px' }}>
                            <div className="d-flex justify-content-between align-items-center ficha_item">
                                <div className="ficha_item_title">
                                    <span>SITUAÇÃO</span>
                                </div>
                                <div className="ficha_item_desc">
                                    <span>{beneficio?.situacaoBeneficio === 'ATIVO' ? (
                                        <>
                                            <span className="text-success"><i className="fa fa-check"></i> ATIVO</span>
                                        </>
                                    ) : beneficio?.situacaoBeneficio === 'INATIVO' ? (
                                        <>
                                            <span className="text-danger"><i className="fa fa-close"></i> INATIVO</span>
                                        </>
                                    ) : ''}</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center ficha_item">
                                <div className="ficha_item_title">
                                    <span>ESPÉCIE</span>
                                </div>
                                <div className="ficha_item_desc">
                                    <span>{bName?.value} -</span>
                                    <span style={{ marginLeft: '10px' }}>
                                        <OverlayTrigger style={{ width: '600px' }} placement="top" overlay={<Tooltip id="tooltip" >
                                            {bName?.label}
                                        </Tooltip>
                                        }>
                                            <button className="btn btn-xs btn-info"><i className="fa fa-info"></i>
                                            </button>
                                        </OverlayTrigger>
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center ficha_item">
                                <div className="ficha_item_title">
                                    <span>DIB  <span>{beneficio?.dib ? formatDate(beneficio?.dib) : null}</span></span>
                                </div>
                                <div className="ficha_item_desc">

                                </div>
                                <div className="ficha_item_title">
                                    <span>DDB <span>{beneficio?.ddb ? formatDate(beneficio?.ddb) : null}</span></span>
                                </div>
                                <div className="ficha_item_desc">

                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center ficha_item">
                                <div className="ficha_item_title">
                                    <span>REPRESENTANTE LEGAL</span>
                                </div>
                                <div className="ficha_item_desc">
                                    <span>{repreLegal ? 'POSSUI' : 'NÃO POSSUI'}</span>
                                    {repreLegal && (<>
                                        <span style={{ marginLeft: '10px' }} >
                                            <OverlayTrigger style={{ width: '600px' }} placement="top" overlay={<Tooltip id="tooltip" >
                                                <hr />
                                                <span>CPF: <b>{beneficio?.dadosRepresentanteLegalProcurador?.doc ? formatarCPF(beneficio.dadosRepresentanteLegalProcurador.doc) : 'Não Informado'}</b></span>
                                                <hr />
                                                <span>NOME: <b>{beneficio?.dadosRepresentanteLegalProcurador?.nome ? beneficio.dadosRepresentanteLegalProcurador.nome : 'Não Informado'}</b></span>
                                                <hr />
                                                <span>NASCIMENTO: <b>{beneficio?.dadosRepresentanteLegalProcurador?.dataNascimento ? formatDate(beneficio.dadosRepresentanteLegalProcurador.dataNascimento) : 'Não Informado'}</b></span>
                                                <hr />
                                                <span>RG: <b>{beneficio?.dadosRepresentanteLegalProcurador?.rg.numero ? beneficio.dadosRepresentanteLegalProcurador.rg.numero : 'Não Informado'}
                                                    /
                                                    {beneficio?.dadosRepresentanteLegalProcurador?.rg.ufEmissor ? beneficio.dadosRepresentanteLegalProcurador.rg.ufEmissor : 'Não Informado'}</b></span>
                                                <hr />
                                                <span>SEXO: <b>{beneficio?.dadosRepresentanteLegalProcurador?.sexo ? beneficio.dadosRepresentanteLegalProcurador.sexo : 'Não Informado'}</b></span>
                                                <hr />
                                                <span>MÃE: <b>{beneficio?.dadosRepresentanteLegalProcurador?.nomeMae ? beneficio.dadosRepresentanteLegalProcurador.nomeMae : 'Não Informado'}</b></span>
                                                <hr />
                                            </Tooltip>
                                            }>
                                                <button className="btn btn-xs btn-primary"><i className="fa fa-info"></i>
                                                </button>
                                            </OverlayTrigger>
                                        </span>
                                    </>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        )
    }
}