
import React, { useEffect, useState } from 'react';
import { AnimationLottie } from "../animations/animationLottie";

const MessageScreen = ({show, onShowChange, showButton = true, showCloseButton = true, buttonColor, buttonText, typeImage = 'lottie', fileName, heightImage, title, message, footerMessage}) => {

  const hideScreen = () => {
    onShowChange(false)
  }

  const classNameButton = `btn btn-${buttonColor}`;

  const returnImageScreen = () => {
    if(typeImage == 'icon') {
      return ( <>
        ÍCONE
      </> )
    }
    else if (typeImage == 'lottie'){
      return ( <>
        <AnimationLottie fileJsonName={fileName} height={heightImage} />
      </> )
    }
  }

  return ( <>
    { show && (
      <div className="message-screen" style={{position: 'relative'}}>

        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.85)",
            zIndex: "9999991",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >

        {
          showCloseButton && (
            <button className='btn btn-offset-light' onClick={hideScreen} style={{position: 'absolute', right: '25px', top: '40px'}}>
              <i className="fa fa-times text-white fa-2x"></i>
            </button>
          )
        }

          <div className="col-md-6 col-xs-12 col-sm-12">
          
            <div className="text-center text-white">
                
                { 
                  returnImageScreen()
                }

                <h4 className="fw-bolder text-uppercase mb-2 mt-2 mb-1"> {title} </h4>
                <span className='p-0 m-0 fs-6'>{message}</span> 
                
                {
                  footerMessage ? (
                    <>
                      <h6 className="mt-4">{footerMessage}</h6>
                    </>
                  ) : ''
                }
                
                {
                  showButton && (
                    <div className='mt-5'>
                      <button className={classNameButton} onClick={hideScreen}>{buttonText}</button>
                    </div>
                  )
                }
            </div>
          </div>
        
        </div>
      </div>
    ) }
  </> );
}

export default MessageScreen;
