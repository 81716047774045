import { Component } from "react"
import { SelectForm, Input } from "../../../../helpers/formData"
import MessageDisplay from "../../../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../../../messages/load";
import Select from 'react-select';

export default class DataMailing extends Component {
    constructor(props) {
        super(props)
        this.state = {
            equipe: '',
            options: '',
            selectUfs: false
        }
        this.getTeams = this.getTeams.bind(this);
        this.changeTeam = this.changeTeam.bind(this);
    }

    componentDidMount() {

        this.getTeams();
    }

    async getTeams() {
        const msg = new MessageDisplay();
        startLoad();
        const tokenLocalStorage = window.localStorage.getItem("token");
        const tokenHash = window.localStorage.getItem("tokenHash");
        const login = window.localStorage.getItem("login");

        fetch(`${process.env.REACT_APP_HTTP}/teams-get-all/${login}/${tokenHash}`, {
            method: "GET",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                authorization: `Bearer ${tokenLocalStorage}`,
            },
        })
            .then((res) => res.json())
            .then(async (res) => {
                if (res.error) {
                    msg.error("Usuários", "Acesso Negado");
                }
                stopLoad();

                this.setState({ teamsData: res.data })
                const teamsMap = res.data.map(({ _id: value, name: label }) => ({
                    value,
                    label,
                }));

                this.setState({
                    teamsList: teamsMap,
                });
            })
            .catch(() => {
                stopLoad();
                msg.error("Server", "Not connect");
            });
    }

    changeTeam(event) {

        if (event.target.value.length == 0) {
            this.setState({
                selectUfs: false
            })
        } else {
            this.setState({
                selectUfs: true
            })
        }

        if (event.target.value) {
            const { teamsData } = this.state
            const team = teamsData.find((e) => e._id == event.target.value)

            let teamsUflist = team.uf_list
                .filter(e => e.uf.length > 0)
                .map(e => {
                    const ufs = e.uf.map(obj => obj.uf);
                    const ufsString = ufs.join(",");
                    return {
                        value: e._id,
                        label: e.name + " >>>>>>>>>>>>> " + ufsString
                    };
                });

            teamsUflist.unshift({
                label: 'Brasil',
                value: 'Brasil'
            });


            this.setState({
                options: teamsUflist,
                equipe: teamsUflist
            })
            setTimeout(() => {
                let a = document.getElementsByName('estado')
                a[0].classList.add('required')
            }, 1000)
        }
    }



    render() {
        const { options, teamsList, selectUfs } = this.state
        return (
            <>
                <SelectForm
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={"equipe"}
                    Required={true}
                    onChange={this.changeTeam}
                    labelName={'Selecione a Equipe'}
                    data={teamsList}
                />

                <Input
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={'nome'}
                    Required={true}
                    label={'Nome do Mailing'}
                    placeholder={"Nome do Mailing"}
                />


                {selectUfs ? (
                    <div style={{ width: "100%", marginRight: "10px" }}>
                        <label>Considerar Estado</label>
                        <Select
                            options={options}
                            name={'estado'}
                            // valueInput={this.state.estado}
                            // onChange={this.changeEstado}
                            // onChange={(e) => this.setState({ estado: e.target.value })}
                            isSearchable={true} // Habilita a pesquisa
                            placeholder="Considerar Estado ..." // Texto do placeholder
                            noOptionsMessage={() => 'Nenhuma Estado Encontrado'} // Mensagem exibida quando não há opções encontradas
                        />
                    </div>
                ) : ''}
            </>
        )
    }
}