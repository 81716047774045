import { Component } from "react";
import { formatDate } from "../../../helpers/FuncHelpers";

export default class DashCompany extends Component {
    render() {
        const { company } = this.props
        return (
            <>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-12">
                        <div className="card o-hidden">
                            <div className="card-body">
                                <div className="ecommerce-widgets media">
                                    <div className="media-body">
                                        <p className="f-w-500 font-roboto">Próxima Renovação</p>

                                        <h4 className="f-w-500 mb-0 f-20"><span className="counter">
                                            {company.level == 'empresa' ? formatDate(company?.date_renovation) : 'Eterna'}
                                        </span></h4>
                                    </div>
                                    <div className="ecommerce-box light-bg-primary"><i className="fa fa-calendar" aria-hidden="true"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-12">
                        <div className="card o-hidden">
                            <div className="card-body">
                                <div className="ecommerce-widgets media">
                                    <div className="media-body">
                                        <p className="f-w-500 font-roboto">Total Licenças</p>
                                        <h4 className="f-w-500 mb-0 f-20"><span className="counter">{company?.total_users}</span></h4>
                                    </div>
                                    <div className="ecommerce-box light-bg-primary"><i className="fa fa-users" aria-hidden="true"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-12">
                        <div className="card o-hidden">
                            <div className="card-body">
                                <div className="ecommerce-widgets media">
                                    <div className="media-body">
                                        <p className="f-w-500 font-roboto">Total Licenças Trial</p>
                                        <h4 className="f-w-500 mb-0 f-20"><span className="counter">{company?.total_users_trial ? company?.total_users_trial : 0}</span></h4>
                                    </div>
                                    <div className="ecommerce-box light-bg-primary"><i className="fa fa-users" aria-hidden="true"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </>
        )
    }
}