import { Component } from "react";
import PageTitle from "../../helpers/PageTitle";
import { Table, Tr, Td } from "../../helpers/Table";
import { startLoad, stopLoad } from "../../messages/load";
import MessageDisplay from "../../messages/messageDisplay";
import EditCompany from "./edit";
import UsersCompany from "./users/users";
import ButtonSearch from "../../helpers/ButtonSearch";
import Modal from 'react-bootstrap/Modal';
import { BtnForm, Form, Input, InputData } from "../../helpers/formData";
import { ApiRequest } from "../../helpers/Api";
import { formatDate } from "../../helpers/FuncHelpers";
import sweetAlert from "../../helpers/sweet";

export default class Company extends Component {
    constructor(props) {
        super(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            companyList: '',
            newComponent: '',
            show: false,
            idCompanyModal: '',
            type: '',
            api: new ApiRequest(),
            msg: new MessageDisplay()
        }
        this.getCompanies = this.getCompanies.bind(this)
        this.handleClick = this.handleClick.bind(this);
        this.changeCompany = this.changeCompany.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.setUpdateStatusCompany = this.setUpdateStatusCompany.bind(this);
        this.activeCompanyTrial = this.activeCompanyTrial.bind(this)

    }

    async handleShow(e) {
        const value = e.target.closest(".btn");
        console.log(value)
        const type = value.getAttribute('data-type')

        // const api = new ApiRequest();
        // const result = await api.get(`companie-total-users-disponivel`)


        this.setState({
            idCompanyModal: value.id,
            show: true,
            type: type
        })
    }

    async handleClose() {
        this.setState({
            show: false
        })
    }

    componentDidMount() {
        this.getCompanies();
    }

    async getCompanies() {
        const { auth } = this.state;
        const { status, typeView } = this.props

        let type = {};
        if (auth.level === 'master') {
            type = 'whitelabel'
        }

        if (auth.level === 'whitelabel' || auth.level === 'revenda') {
            type = 'empresa'
        }

        if (typeView) {
            type = 'revenda'
        }

        const msg = new MessageDisplay();
        const api = new ApiRequest();
        const result = await api.get(`companies-get-all/${status}/${type}`)
        if (result.error) {
            msg.error("Empresa", "Acesso Negado");
        }

        stopLoad();
        this.setState({
            companyList: result.data
        })
    }

    updateTotalUser(data) {
        const find = document.getElementsByClassName(`total_license_teste${data._id}`)
        find[0].innerHTML = data.total_users_trial
    }

    async changeCompany(e) {
        const sweet = new sweetAlert();
        sweet.info('Você deseja realmente ativar essa <b>Empresa</b>?', null, null, 'Confirmar').then(async (result) => {
            if (result === true) {
                const result = await this.state.api.get(`companie-change-status/${e._id}`)
                if (result.error) {
                    return this.state.msg.error("Empresa", result.data);
                }

                if (result.error == false) {
                    this.setUpdateStatusCompany(result.data)
                    return this.state.msg.success("Empresa", 'Empresa atualizada com sucesso');
                }
            }
        })
    }


    async setUpdateStatusCompany(company) {
        const IdCompany = document.getElementsByClassName(`findStatus${company._id}`)
        const icon = IdCompany[0].children[0]
        if (company.status == 'active') {
            IdCompany[0].className = `findStatus${company._id} btn btn-danger`
            IdCompany[0].title = 'Desativar Empresa'
            icon.className = 'fa fa fa-close'
        } else {
            IdCompany[0].className = `findStatus${company._id} btn btn-success`
            IdCompany[0].title = 'Ativar Empresa'
            icon.className = 'fa fa fa-check'
        }

        await this.getCompanies()

    }

    handleClick(e) {


        const value = e.target.closest(".btn");
        const attr = value.getAttribute('data-info')

        if (attr == 'edit') {
            this.setState({
                newComponent: <EditCompany id={value.id} />
            })
        }
        if (attr == 'users') {
            this.setState({
                newComponent: <UsersCompany id={value.id} />
            })
        }

    }


    async extendTrial(e) {
        console.log(e)
        const api = new ApiRequest();
        const sweet = new sweetAlert();

        sweet.info('Essa ação fará com que seu cliente tenha mais <b>3 dias</b> de teste', null, null, 'Estender Teste').then(async (result) => {
            if (result === true) {
                const msg = new MessageDisplay();
                // Restante do código de envio do formulário...

                startLoad();
                const result = await api.put(`extend-trial/${e}`, { e }, e)
                console.log(result)
                if (result) {
                    stopLoad();

                    if (result.error === true) {
                        return msg.error("Empresa", result.data);
                    }
                    await this.getCompanies();
                    return msg.success("Empresa", result.data);
                }

            }
        })
    }

    async activeCompanyTrial(e) {
        const result = await this.state.api.post(`companie-active-trial`, e)
        if (result.error) {
            return this.state.msg.error("Empresa", result.data);

        }
        this.state.msg.success('Empresa', 'Empresa efetivada com sucesso')
        this.setState({
            show: false
        })
        return this.getCompanies();
    }

    async desactiveCompany(e) {
        const api = new ApiRequest();
        const sweet = new sweetAlert();
        sweet.info(`Essa ação fará com que esse empresa seja desativada... <br/> Deseja realmente <b>Desativar</b> ?`, null, null, 'Confirmar')
            .then(async (result) => {
                if (result === true) {
                    const postData = {
                        id: e
                    }
                    const result = await api.post(`inactive-company-trial`, postData, false)
                    if (result.error) {
                        return this.state.msg.error("Empresa", result.data);
                    }

                    this.state.msg.error("Empresa", 'Empresa Desativada com sucesso');
                    return await this.getCompanies();
                }
            })
    }

    dataResult(e) {
        if (e !== '') {
            this.setState({
                companyList: e
            })
        }
    }

    render() {
        const { companyList, auth, newComponent, idCompanyModal, type } = this.state;
        const totalEMpresas = companyList.length
        const totalLicensas = Object.values(companyList).reduce((acumulador, objeto) => {
            return acumulador + objeto.total_users | 0;
        }, 0);
        // const totalLicensas = 0

        return (
            <>
                {newComponent ? newComponent : (
                    <div className="page-body">
                        <div className="container-fluid">
                            <PageTitle title={'Empresas'} auth={auth} />
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-4 col-lg-6 col-md-12">
                                    <div className="card o-hidden">
                                        <div className="card-body">
                                            <div className="ecommerce-widgets media">
                                                <div className="media-body">
                                                    <p className="f-w-500 font-roboto">TOTAL EMPRESAS</p>

                                                    <h4 className="f-w-500 mb-0 f-20"><span className="counter">
                                                        {totalEMpresas}
                                                    </span></h4>
                                                </div>
                                                <div className="ecommerce-box light-bg-primary"><i className="fa fa-home" aria-hidden="true"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-12">
                                    <div className="card o-hidden">
                                        <div className="card-body">
                                            <div className="ecommerce-widgets media">
                                                <div className="media-body">
                                                    <p className="f-w-500 font-roboto">Total Licenças</p>

                                                    <h4 className="f-w-500 mb-0 f-20"><span className="counter">
                                                        {totalLicensas}
                                                    </span></h4>
                                                </div>
                                                <div className="ecommerce-box light-bg-primary"><i className="fa fa-home" aria-hidden="true"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5>Lista de Empresas</h5>
                                                </div>
                                                <div>
                                                    <ButtonSearch onFunction={this.getCompanies} type={"companies"} onResult={(e) => { this.dataResult(e) }} />
                                                </div>
                                            </div>
                                        </div>
                                        {companyList && companyList.length > 0 ? (


                                            <Table>
                                                <Tr>
                                                    <Td >
                                                        <p>Nome</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>CNPJ</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>Resposável</p>
                                                    </Td>
                                                    {auth.level == 'empresa' ? (
                                                        <>
                                                            <Td classTd={"text-center"}>
                                                                <p>Próxima Renovação</p>
                                                            </Td>
                                                            <Td classTd={"text-center"}>
                                                                <p>Usuários</p>
                                                            </Td>
                                                        </>
                                                    ) : ''}
                                                    <Td classTd={"text-center"}>
                                                        <p>Level</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>Status</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>Total Licenças</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>Data Renovação</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>Convênios</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>Ações</p>

                                                    </Td>
                                                </Tr>
                                                {companyList.map((c) => {
                                                    return (<>
                                                        <Tr>
                                                            <Td>
                                                                <p>{c?.name}</p>
                                                            </Td>
                                                            <Td classTd={"text-center"}>
                                                                <p>{c?.cnpj}</p>
                                                            </Td>
                                                            <Td classTd={"text-center"}>
                                                                <p>{c?.responsable}</p>
                                                            </Td>

                                                            {auth.level == 'empresa' ? (
                                                                <>
                                                                    <Td classTd={"text-center"}>
                                                                        <p>{c?.date_renovation}</p>
                                                                    </Td>
                                                                    <Td classTd={"text-center"}>
                                                                        <p>{c?.total_users}</p>
                                                                    </Td>
                                                                </>
                                                            ) : ''}
                                                            <Td classTd={"text-center"}>
                                                                <p>{c.level}</p>
                                                            </Td>
                                                            <Td classTd={"text-center"}>
                                                                <p>{c.status}</p>
                                                            </Td>
                                                            {c.status == 'trial' ? (
                                                                <>
                                                                    <Td classTd={"text-center"}>
                                                                        <p>{c.total_users_trial ? c.total_users_trial : 0} </p>
                                                                        <span className="btn btn-xs btn-warning"> Teste</span>
                                                                    </Td>
                                                                </>) : (
                                                                <>
                                                                    <Td classTd={"text-center"}>
                                                                        <p>{c.total_users ? c.total_users : 0} </p>
                                                                        <span className="btn btn-xs btn-success"> Ativas</span>
                                                                    </Td>
                                                                </>
                                                            )}
                                                            <Td classTd={"text-center"}>
                                                                {c.status === 'trial' ? (<>
                                                                    {c.date_trial ? formatDate(c.date_trial) : null}
                                                                    <br />
                                                                    {c.trial_extend && (<>
                                                                        <button className="btn btn-xs btn-danger">Trial Extendido</button>
                                                                    </>)}
                                                                </>) : (<>
                                                                    <p>{c.date_renovation ? formatDate(c.date_renovation) : null}</p>
                                                                </>)}
                                                            </Td>
                                                            <Td classTd={"text-center"}>
                                                                {c.convenios.map ? c.convenios.map((con, index) => {
                                                                    return (
                                                                        <>
                                                                            {con.name}
                                                                            {index !== con.name.length - 1 && ","}
                                                                        </>
                                                                    )
                                                                }) : <p>Nenhum convênio habilitado</p>}
                                                            </Td>
                                                            <Td classTd={"text-center"}>
                                                                <div className="btn-group">

                                                                    {(!c.trial_extend && c.status === 'trial') && (<>
                                                                        <button
                                                                            className="btn btn-warning"
                                                                            title="Extender Trial"
                                                                            onClick={(e) => this.extendTrial(c._id)}
                                                                        >
                                                                            <i className="fa fa-calendar"></i>
                                                                            {/* <i className="fa fa-a"></i> */}
                                                                        </button>
                                                                    </>)}
                                                                    <button onClick={(e) => this.handleClick(e)} id={c._id} data-info={'edit'} className="btn btn-secondary" data-bs-original-title="" title="Editar Empresa"><i className="fa fa fa-edit"></i></button>
                                                                    {/* {c.level == 'empresa' ? (
                                                                        <button onClick={(e) => this.handleClick(e)} id={c._id} data-info={'users'} className="btn btn-info" data-bs-original-title="" title="Renovar Cliente"><i className="fa fa-calendar"></i></button>
                                                                    ) : ''} */}
                                                                    {/* <button onClick={(e) => this.handleClick(e)} id={c._id} data-info={'users'} className="btn btn-info" data-bs-original-title="" title="Licenças"><i className="fa fa-user"></i></button> */}
                                                                    {c?.status !== 'trial' && (<>
                                                                        {c.level === 'empresa' && (<>
                                                                            <button
                                                                                id={c._id}
                                                                                data-type={'renovar_company'}
                                                                                onClick={(e) => { this.handleShow(e) }}
                                                                                className="btn btn-sm btn-info"
                                                                                title="Renovar Empresa"
                                                                            >
                                                                                <i className="fa fa-calendar"></i>
                                                                                {/* <i className="fa fa-a"></i> */}
                                                                            </button>
                                                                        </>)}
                                                                        <button
                                                                            className={`findStatus${c._id} btn btn-${c.status === 'active' ? 'danger' : 'success'}`}
                                                                            data-bs-original-title=""
                                                                            title={`${c.status === 'active' ? 'Desativar Empresa' : 'Ativar Empresa'}`}
                                                                            onClick={(e) => this.changeCompany(c)}
                                                                        >
                                                                            <i id='filho' className={`fa fa fa-${c.status === 'active' ? 'close' : 'check'} `}></i>
                                                                        </button>
                                                                    </>)}
                                                                    {c?.status === 'trial' && (<>
                                                                        <button
                                                                            id={c._id}
                                                                            data-type={'efetivar_empresa'}
                                                                            className={`btn btn-success`}
                                                                            data-bs-original-title=""
                                                                            title={`Efetivar Empresa`}
                                                                            onClick={(e) => this.handleShow(e)}

                                                                        >
                                                                            <i id='filho' className={`fa fa fa-check`}></i>
                                                                        </button>
                                                                        <button
                                                                            className={`btn btn-danger`}
                                                                            data-bs-original-title=""
                                                                            title={`Empresa não efetivou`}
                                                                            onClick={() => this.desactiveCompany(c._id)}
                                                                        >
                                                                            <i id='filho' className={`fa fa fa-close `}></i>
                                                                        </button>
                                                                    </>)}



                                                                </div>
                                                            </Td>
                                                        </Tr >
                                                    </>)
                                                })}
                                            </Table>
                                        ) : <p className="text-center mt-3">Você ainda não possui empresas cadastrados</p>}
                                    </div>
                                </div >
                            </div>
                        </div>
                    </div >
                )
                }

                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    size="lg"
                    style={{ zIndex: '9998' }}
                >

                    <Modal.Header closeButton>
                        <Modal.Title>
                            {console.log(type)}
                            {type === 'renovar_company' ? 'Renovar Empresa' : 'Efetivar Empresa'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(e) => this.activeCompanyTrial(e)}>
                            <Input
                                classInput={"col-12"}
                                name={'id'}
                                type={'hidden'}
                                placeholder={"Total Usuários Efetivos"}
                                valueInput={idCompanyModal}
                            />

                            {type !== 'renovar_company' && (<>
                                <Input
                                    label={'Total Usuários'}
                                    classInput={"col-6"}
                                    name={'total_users'}
                                    type={'number'}
                                    placeholder={"Adicionar Licenças Efetivas"}
                                />
                            </>)}

                            <InputData
                                classInput={`col-${type === 'renovar_company' ? '12' : '6'}`}
                                name={"date_renovation"}
                                label={'Próxima Renovação'}
                                placeholder={"Próxima Renovação"}
                            />
                            <BtnForm name={`${type === 'renovar_company' ? 'Renovar Empresa' : 'Efetiar Empresa'}`} />
                        </Form>

                    </Modal.Body>
                </Modal>
            </>
        )
    }
}