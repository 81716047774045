
import { Component } from "react";
import Address from "../painel/callcenter/data_client/dados_cadastrais";

export class Tab extends Component {
    render() {
        const { children } = this.props
        return (
            <>
                <div
                    className="search-page"
                    style={{ padding: "5px" }}
                >
                    <div className="text-center">
                        {children}
                    </div>
                </div>

            </>
        )
    }
}

export class UlTab extends Component {
    render() {
        const { children } = this.props
        return (
            <>
                <ul
                    className="nav nav-tabs search-list"
                    id="top-tab"
                    role="tablist"
                    style={{ border: "nome !important" }}
                >

                    {children}

                </ul>
            </>)
    }
}

export class LiTab extends Component {
    render() {
        const { active = false, id, href, name, onTypeCLick, view } = this.props

        return (
            <>
                <li className="nav-item" style={{ display: `${view ? 'block' : 'none'}` }}>
                    <a
                        className={`nav-link ${active ? 'active' : ''}`}
                        id={id}
                        data-bs-toggle="tab"
                        href={`#${href}`}
                        onClick={(e) => { onTypeCLick(e) }}
                        role="tab"
                        aria-selected="true"
                        data-bs-original-title=""
                        title=""
                    >
                        {name}
                    </a>
                </li>
            </>
        )
    }
}

export class DivTab extends Component {
    render() {
        const { active = false, id, href } = this.props
        return (
            <>
                <div className="tab-content" id="top-tabContent">
                    <div
                        className={`search-links tab-pane ${active ? 'active' : ''} fade show`}
                        id={id}
                        role="tabpanel"
                        aria-labelledby={href}
                    >
                        <br />
                        <div className="d-flex">
                            {/* {<DadosCadstrais client={data[0]} />} */}
                            {< Address />}
                        </div>
                        <div className="d-flex">
                            {/* {<Phones />} */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}