import { useState } from "react"
import { listBanks } from "../../../../helpers/FuncHelpers";
import Select from 'react-select';

const DadosBancosSeguroVida = () => {

    const [isDisabledBeneficio, setIsDisabledBeneficio] = useState(false);
    const [isDisableDesconsiderarBeneficio, setIsDisableDesconsiderarBeneficio] = useState(false);
    const [bancosConsiderar, setBancosConsiderar] = useState([]);
    const [bancosDesconsiderar, setBancosDesconsiderar] = useState([]);

    const handleSelectChangeConsiderar = (selectedOptions) => {
        changeConsidera(selectedOptions)
        window.desconsiderarBancos = [];
        window.considerarBancos = selectedOptions.map(option => option.value)
        setBancosConsiderar(selectedOptions.map(option => option.value))
        console.log('considerar', window.considerarBancos)
    }


    const handleSelectChangeDesconsiderar = (selectedOptions) => {
        hangeDesconsidera(selectedOptions)
        window.considerarBancos = [];
        window.desconsiderarBancos = selectedOptions.map(option => option.value)
        setBancosDesconsiderar(selectedOptions.map(option => option.value))

    }

    const hangeDesconsidera = (e) => {
        setIsDisabledBeneficio(true)

        if (e.length <= 0) {
            setIsDisabledBeneficio(false)
        }
    }

    const changeConsidera = (e) => {
        setIsDisableDesconsiderarBeneficio(true)
        if (e.length <= 0) {
            setIsDisableDesconsiderarBeneficio(false)
        }
    }

    const bancoList = listBanks();

    return (<>
        <div style={{ width: "100%", marginRight: "10px" }}>
            <label>Considerar</label>
            <Select
                classNames={"col-sm-6 col-md-6 col-xl-6"}
                isMulti
                isDisabled={isDisabledBeneficio}
                options={bancoList}
                value={bancoList.filter(option => bancosConsiderar.includes(option.value))}
                onChange={(e) => { handleSelectChangeConsiderar(e) }}
                isSearchable={true} // Habilita a pesquisa
                placeholder="Considerar Benefícios" // Texto do placeholder
                noOptionsMessage={() => 'Considerar Benefícios'} // Mensagem exibida quando não há opções encontradas
            />

            <label>Desconsiderar</label>
            <Select
                classNames={"col-sm-6 col-md-6 col-xl-6"}
                isMulti
                isDisabled={isDisableDesconsiderarBeneficio}
                options={bancoList}
                value={bancoList.filter(option => bancosDesconsiderar.includes(option.value))}
                onChange={(e) => { handleSelectChangeDesconsiderar(e) }}
                isSearchable={true} // Habilita a pesquisa
                placeholder="Desconsiderar Benefícios" // Texto do placeholder
                noOptionsMessage={() => 'Desconsiderar Benefícios'} // Mensagem exibida quando não há opções encontradas
            />
        </div>
    </>)
}

export default DadosBancosSeguroVida