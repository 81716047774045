import { Component } from "react";
import LogoDefault from "../../assets/images/logo/login.png";
import MessageDisplay from "../../components/messages/messageDisplay";
import { BtnForm, Form, Input } from "../helpers/formData";
import { ApiRequest } from '../helpers/Api'
import Login from "../login";
import { Helmet } from "react-helmet";
export default class UpdatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            whitelabelSettings: JSON.parse(localStorage.getItem('whiteLabelSettings')),
            captcha: "",
            newComponent: ''
        };
        this.loginSubmit = this.submitForm.bind(this);
    }

    async submitForm(e) {
        const api = new ApiRequest();

        e.preventDefault();
        const msg = new MessageDisplay();
        const result = await api.post('check-current-password', e)

        if (result.error === true && result) {
            msg.error('Usuário', result.data)
            return;
        }

        msg.success('Usuário', 'Senha atualizada com sucesso')
        this.setState({
            newComponent: <Login />
        });
        return;
    }



    render() {
        const { newComponent, whitelabelSettings } = this.state
        const { id } = this.props
        return (

            <>
                <Helmet>
                    {/* <link rel="icon" type="image/png" href={whitelabelSettings?.logo ? whitelabelSettings?.logo : null} /> */}
                    <title>{whitelabelSettings?.name ? whitelabelSettings?.name : 'RVXTECH'} - Senha Expirada</title>
                </Helmet>
                {newComponent ? newComponent : (
                    <body className="light">
                        <div className="tap-top">
                            <svg xmlns="http://www.w/3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="feather feather-chevrons-up"><polyline points="17 11 12 6 7 11"></polyline><polyline points="17 18 12 13 7 18"></polyline></svg>
                        </div>

                        <div className="page-wrapper">
                            <div className="container-fluid p-0">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="login-card">
                                            <div>
                                                <div style={{ maxWidth: "520px", margin: "0 auto" }}>
                                                    <div>
                                                        <a className="logo text-start" href="./">
                                                            <img
                                                                className="img-fluid for-light"
                                                                src={whitelabelSettings?.logo ? whitelabelSettings.logo : LogoDefault}
                                                                style={{ width: "30%" }}
                                                                alt="looginpage"
                                                            />
                                                            <img
                                                                className="img-fluid for-dark"
                                                                src={whitelabelSettings?.logo ? whitelabelSettings.logo : LogoDefault}
                                                                style={{ width: "30%" }}
                                                                alt="looginpage"
                                                            />
                                                        </a>
                                                    </div>
                                                    <Form title={'Redefinir Senha'} onSubmit={(e) => this.submitForm(e)}>
                                                        <Input
                                                            valueInput={id}
                                                            classInput={"col-12"}
                                                            type={'hidden'}
                                                            name={'id'}
                                                        />
                                                        <Input
                                                            classInput={"col-12"}
                                                            label={'Senha Atual'}
                                                            placeholder={'********'}
                                                            type={'password'}
                                                            name={'currentpassword'}
                                                        />
                                                        <Input
                                                            classInput={"col-12"}
                                                            label={'Nova Senha'}
                                                            placeholder={'********'}
                                                            type={'password'}
                                                            name={'password'}
                                                        />
                                                        <Input
                                                            classInput={"col-12"}
                                                            label={'Repita Nova Senha'}
                                                            placeholder={'********'}
                                                            type={'password'}
                                                            name={'repeat_password'}
                                                        />
                                                        <BtnForm name={'Nova Senha'} />
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </body>
                )}
            </>
        )
    }
}