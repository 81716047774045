import React, { Component } from "react";
import Sad from "../../assets/images/other-images/sad.png";
import logOut from "./logout";
export default class TeamsNotFound extends Component {
    logout(){
        logOut()
    }
    render() {
     
        const { desc } = this.props
        return (
            <>
                <div className="tap-top"><i data-feather="chevrons-up"></i></div>
                <div className="page-wrapper compact-wrapper" id="pageWrapper">
                    <div className="error-wrapper">
                        <div className="container"><img className="img-100" src={Sad} alt="" />
                            <div className="error-heading">
                                <h2 className="headline font-danger">Equipe</h2>
                            </div>
                            <div className="col-md-8 offset-md-2">
                                <p className="sub-content">
                                    Desculpe mais seu usuário não esta atribuido a nenhuma <b>EQUIPE</b> fala com seu Administrador
                                </p>
                                <p className="sub-content">
                                    {desc}
                                </p>
                            </div>
                            <div><a onClick={this.logout} className="btn btn-danger-gradien btn-lg" href="/">Voltar ao Menu</a></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}