import autobahn from 'autobahn';
import logOut from '../auth/logout';
const urlSocket = 'wss://socket.proconsig.app/wss/';
console.log(urlSocket)

export default class _WebSocket {

    constructor() {
        this.autobahn = autobahn;
        this.url = urlSocket;
        this.realm = 'realm1';
        this.authmethods = ['ipauth'];
    }

    connect(callback) {
        const conn = new this.autobahn.Connection({
            url: this.url,
            realm: this.realm,
            authmethods: this.authmethods
        });
        conn.onopen = function (session) {
            console.log('connected to websocket')
            if (callback) {
                callback(session);
            }

            session.publish('ping', []);
        }
        conn.onclose = function (reason, details) {
            console.log(reason, details)
            if (details.retry_count === 3) {
                logOut();
            }

            if (callback) {
                callback(false);
            }

        };

        conn.open();
    }

}

export class Routeres {
    constructor() {
        this.routes = {
            login: "login",
            logout: "logout.",
            restartToken: "restartoken.",
            newtoken: "newtoken.",
            isLogin: "islogin",
            atendiment: 'atendiment.',
            checkTotalUsers: 'checkttotalusers.',
            checkTotalUsersTrue: 'checkttotaluserstrue'
        }
    }
}
// Listening to websocket data
export class OnSocket extends Routeres {
    constructor(socket, token) {
        super();
        this.socket = socket;
        this.token = token;
        this.SendSocket = new SendSocket(socket, token);
    }
    // Routes
    onLogout(send, callback, token) {
        if (!token) { token = this.token; }
        this.socket.subscribe(`${this.routes.logout}${token}`, (data) => {
            if (typeof send === 'object') {
                return this.SendSocket.sendLogout(send);
            }
            callback(data);
        })
    }

    // onRestartToken(send, callback, token) {
    //     if (!token) { token = this.token; }
    //     this.socket.subscribe(`${this.routes.restartToken}${token}`, (data) => {
    //         if (typeof send === 'object') {
    //             // return this.SendSocket.sendRestartToken(send);
    //         }
    //         callback(data);
    //     })
    // }

    onNewToken(send, callback, token) {
        if (!token) { token = this.token; }
        this.socket.subscribe(`${this.routes.newtoken}${token}`, (data) => {
        console.log(`2aqui.......`)
            if (typeof send === 'object') {
                return this.SendSocket.sendNewToken(send);
            }
            callback(data);
        })
    }

    onLogin(send, callback) {
        this.socket.subscribe(`${this.routes.login}`, (data) => {
            if (typeof send === 'object') {
                return this.SendSocket.sendLogin(send);
            }
            callback(data);
        });
    }

    onIsLogin(send, callback) {
        this.socket.subscribe(this.routes.isLogin, (data) => {
            if (typeof send === 'object') {
                return this.SendSocket.sendIsLogin(send);
            }
            callback(data);
        });
    }

    onAtendiment(send, callback, token) {
        if (!token) {
            token = this.token;
        }
        this.socket.subscribe(`${this.routes.atendiment}${token}`, (data) => {
            if (typeof send === 'object') {
                return this.SendSocket.sendAtendiment(send);
            }
            callback(data);
        });
    }

    onCheckTotalUsers(send, callback, token) {
        if (!token) {
            token = this.token;
        }
        this.socket.subscribe(`${this.routes.checkTotalUsers}${token}`, (data) => {
            if (typeof send === 'object') {
                return this.SendSocket.sendCheckTotalUsersTrue(send);
            }
            callback(data);
        });
    }
}

// Send to websocket data
export class SendSocket extends Routeres {
    constructor(socket, token) {
        super();
        this.socket = socket;
        this.token = token;
    }

    sendLogout(data, token) {
        if (!token) { token = this.token; }
        this.socket.publish(`${this.routes.logout}${token}`, data);
    }

    sendRestartToken(data, token) {
        if (!token) { token = this.token; }
        this.socket.publish(`${this.routes.restartToken}${token}`, data);
    }

    sendNewToken(data, token) {
        if (!token) { token = this.token; }
        this.socket.publish(`${this.routes.newtoken}${token}`, data);
    }

    sendAtendiment(data, token) {
        if (!token) { token = this.token; }
        this.socket.publish(`${this.routes.atendiment}${token}`, data);
    }

    sendLogin(data) {
        this.socket.publish(this.routes.login, data);
    }

    sendIsLogin(data) {
        this.socket.publish(this.routes.isLogin, data);
    }

    sendCheckTotalUsers(data) {
        this.socket.publish(this.routes.checkTotalUsers, data);
    }

    sendCheckTotalUsersTrue(data) {
        this.socket.publish(this.routes.checkTotalUsersTrue, data);
    }
}


