import { Component } from "react";
import { Table, Tr, Td } from "../../../../helpers/Table";
import Modal from 'react-bootstrap/Modal';
import MessageDisplay from "../../../../messages/messageDisplay";
import InputCurrency, { Form, Input, SelectForm, BtnNext, InputData, BtnForm } from "../../../../helpers/formData";
import Select from 'react-select';
import { listBanks } from "../../../../helpers/FuncHelpers";
window.portList = [];
export default class PortabilidadeInss extends Component {

    constructor(props) {
        super(props)
        this.state = {
            show: false,
            bank: '',
            plano_de: '',
            plano_ate: '',
            taxa_de: '',
            taxa_ate: '',
            parcelas_de: '',
            parcelas_ate: '',
            valor_parcela_de: '',
            valor_parcela_ate: '',
            saldo_devedor_de: '',
            saldo_devedor_ate: '',
            portList: []
        }

        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.add = this.add.bind(this)
        this.removeItemPort = this.removeItemPort.bind(this)
    }

    add(e) {

        e.preventDefault()
        const msg = new MessageDisplay();
        const { bank,
            plano_de,
            plano_ate,
            taxa_de,
            taxa_ate,
            parcelas_de,
            parcelas_ate,
            valor_parcela_de,
            valor_parcela_ate,
            saldo_devedor_de,
            saldo_devedor_ate } = this.state


        if (!bank) {
            return msg.error('Desculpe', 'Mais o banco não pode ser vazio')
        }

        let addPort = {
            bank,
            plano_de,
            plano_ate,
            taxa_de,
            taxa_ate,
            parcelas_de,
            parcelas_ate,
            valor_parcela_de,
            valor_parcela_ate,
            saldo_devedor_de,
            saldo_devedor_ate,
        }

        if (window.portList.find((item) => item.bank == addPort.bank)) {
            return msg.error('Desculpe', 'Mais ess bando já foi adicionado')
        }

        window.portList.push({
            ...addPort
        })

        this.setState({
            portList: window.portList
        })

    }

    handleClose() {
        this.setState({
            show: false
        })
    }

    handleShow(e) {

        this.setState({
            show: true
        })
    }

    removeItemPort(e) {
        const id = e.target.id
        window.portList.splice(id, 1)
        this.setState({
            portList: window.portList
        })
    }

    render() {
        const { portList } = this.state
        const bancoList = listBanks();
        return (
            <>
                <div className="text-end">
                    <button onClick={this.handleShow} type="button" className="btn btn-sm btn-danger">Adicionar Bancos</button>
                </div>
                <br />
                <br />
                <br />
                <div>
                    <Table>
                        <Tr>
                            <Td>Id</Td>
                            <Td>Bancos</Td>
                            <Td>Plano</Td>
                            <Td>Taxa</Td>
                            <Td>Parcelas Pagas</Td>
                            <Td>Valor de Parcelas</Td>
                            <Td>Saldo Devedor</Td>
                            <Td>Deletar</Td>
                        </Tr>

                        {portList && portList.length > 0 ? (
                            portList.map((e, index) => {
                                return (
                                    <>
                                        <Tr>
                                            <Td>{index}</Td>
                                            <Td>{e.bank}</Td>
                                            <Td>{`de ${e.plano_de} até ${e.plano_ate}`}</Td>
                                            <Td>{`de ${e.taxa_de} até ${e.taxa_ate}`}</Td>
                                            <Td>{`de ${e.parcelas_de} até ${e.parcelas_ate}`}</Td>
                                            <Td>{`de ${e.valor_parcela_de} até ${e.valor_parcela_ate}`}</Td>
                                            <Td>{`de ${e.saldo_devedor_de} até ${e.saldo_devedor_ate}`}</Td>
                                            <Td><button id={index} onClick={(e) => { this.removeItemPort(e) }} className="btn btn-sm btn-danger"><i className="fa fa fa-close"></i></button></Td>
                                        </Tr>
                                    </>
                                )
                            })

                        ) : ''}
                    </Table>
                </div>

                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    size="lg"
                    style={{ zIndex: '9998' }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Adicionar Bancos</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="row">
                                <div style={{ width: "100%", marginBottom: "15px" }}>
                                    <label>Considerar</label>
                                    <Select
                                        options={bancoList}
                                        name={'bank'}
                                        onChange={(e) => this.setState({ bank: e.value })}
                                        isSearchable={true} // Habilita a pesquisa
                                        placeholder="Considerar" // Texto do placeholder
                                        noOptionsMessage={() => 'Nenhum Banco Encontrado'} // Mensagem exibida quando não há opções encontradas
                                    />
                                </div>

                                <Input
                                    classInput={"col-sm-6 col-md-6 col-xl-6 plano_de"}
                                    label={'Plano de'}
                                    name={'plano_de'}
                                    type={'number'}
                                    onChange={(e) => this.setState({ plano_de: e.target.value })}
                                    placeholder={"Plano até"}
                                />
                                <Input
                                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                                    label={'Plano de'}
                                    name={'plano_ate'}
                                    type={'number'}
                                    onChange={(e) => this.setState({ plano_ate: e.target.value })}
                                    placeholder={"Plano até"}
                                />

                                <Input
                                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                                    label={'Taxa de'}
                                    name={'taxa_de'}
                                    mask={'9.99%'}
                                    type={'text'}
                                    onChange={(e) => this.setState({ taxa_de: e.target.value })}
                                    placeholder={"Taxa até"}
                                />
                                <Input
                                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                                    label={'Taxa de'}
                                    name={'taxa_ate'}
                                    mask={'9.99%'}
                                    onChange={(e) => this.setState({ taxa_ate: e.target.value })}
                                    placeholder={"Taxa até"}
                                />

                                <Input
                                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                                    label={'Parcelas Pagas de'}
                                    name={'parcelas_de'}
                                    type={'number'}
                                    onChange={(e) => this.setState({ parcelas_de: e.target.value })}
                                    placeholder={"Parcelas Pagas até"}
                                />
                                <Input
                                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                                    label={'Parcelas Pagas de'}
                                    name={'parcelas_ate'}
                                    type={'number'}
                                    onChange={(e) => this.setState({ parcelas_ate: e.target.value })}
                                    placeholder={"Parcelas Pagas até"}
                                />

                                <InputCurrency
                                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                                    name={'valor_parcela_de'}
                                    label={'Valor de Parcelas Pagas de'}
                                    onChange={(e) => this.setState({ valor_parcela_de: e.target.value })}
                                    placeholder={"Valor de Parcelas Pagas até"}
                                />
                                <InputCurrency
                                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                                    name={'valor_parcela_ate'}
                                    label={'Valor Parcelas Pagas de'}
                                    onChange={(e) => this.setState({ valor_parcela_ate: e.target.value })}
                                    placeholder={"Parcelas Pagas até"}
                                />

                                <InputCurrency
                                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                                    name={'saldo_devedor_de'}
                                    label={'Saldo devedor de'}
                                    onChange={(e) => this.setState({ saldo_devedor_de: e.target.value })}
                                    placeholder={"Saldo devedor até"}
                                />
                                <InputCurrency
                                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                                    name={'saldo_devedor_ate'}
                                    label={'Saldo devedor de'}
                                    onChange={(e) => this.setState({ saldo_devedor_ate: e.target.value })}
                                    placeholder={"Saldo devedor até"}
                                />

                            </div>
                            <div className="mt-3" style={{ float: 'right' }}>
                                <button className="btn btn-primary" onClick={this.add}>Adicionar Bancos</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}