import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLoadDash = () => {

    return (
        <>
            <div className='row'>

                <Skeleton containerClassName='col-3' height={100} borderRadius="0.5rem"/>
                <Skeleton containerClassName='col-3' height={100} borderRadius="0.5rem"/>
                <Skeleton containerClassName='col-3' height={100} borderRadius="0.5rem"/>
                <Skeleton containerClassName='col-3' height={100} borderRadius="0.5rem"/>
            </div>

            <div className='row mt-3'>
                <Skeleton containerClassName='col-6' height={225} borderRadius="0.5rem"/>
                <Skeleton containerClassName='col-6' height={225} borderRadius="0.5rem"/>
            </div>

            <div className='row mt-3'>
                <Skeleton height={30} count={4} borderRadius="0.5rem"/>
            </div>
        </>
    )
}

export default SkeletonLoadDash;