import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import 'bootstrap-notify';

import "./assets/css/font-awesome.css";
import "./assets/css/vendors/icofont.css";
import "./assets/css/vendors/themify.css";
import "./assets/css/vendors/flag-icon.css";
import "./assets/css/vendors/feather-icon.css";
import "./assets/css/vendors/scrollbar.css";
import "./assets/css/style.css";
import "./assets/css/color-1.css";
import "./assets/css/responsive.css";

import "./assets/css/proconsig/style.css";
import "./assets/css/proconsig/simulator.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
reportWebVitals();
