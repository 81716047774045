import "../../assets/css/App.css";
import { AnimationLottie } from "../animations/animationLottie";

const loadingText = () => {
    return (
        <>
             <div className="container vh-100 d-flex flex-column justify-content-center align-items-center">
            <AnimationLottie fileJsonName="server_working2" height={200} />
                <div className="typing-text">
                    <span>Aguarde, carregando...</span>
                </div>
            </div>
        </>
    );
}

export default loadingText;