import React, { Component } from "react";
import PageTitle from "../../helpers/PageTitle";
import { Table, Tr, Td } from "../../helpers/Table";
import MessageDisplay from "../../messages/messageDisplay";
import { ApiRequest } from "../../helpers/Api";
import { startLoad, stopLoad } from "../../messages/load";
import Atendiment from "../callcenter/atendiment";
import { formatarCPF, formatarDataHora } from "../../helpers/FuncHelpers";
export default class AtendimentList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: '',
            newComponent: ''
        }
        this.getAtendiments = this.getAtendiments.bind(this)
        this.getAtendimentCpf = this.getAtendimentCpf.bind(this)
    }

    componentDidMount() {
        this.getAtendiments();
    }

    async getAtendiments() {
        const msg = new MessageDisplay();
        startLoad();
        const api = new ApiRequest();
        const result = await api.get(`atendiments-list`)

        if (result.error === true && result) {
            msg.error('Atendimento', result.data)
            return;
        }
        stopLoad();
        this.setState({
            data: result.data
        })
    }

    async getAtendimentCpf(e) {
        const cpf = e.target.id;
        const msg = new MessageDisplay();
        startLoad();
        const api = new ApiRequest();
        const result = await api.get(`atendiment-list/${cpf}`)
        if (result.error === true && result) {
            console.log(result)
            msg.error('Atendimento', result.data)
            return;
        }
        stopLoad();
        this.setState({
            newComponent: <Atendiment data={result.data} />
        })


    }

    render() {
        const { auth } = this.props
        const { data, newComponent } = this.state

        return (
            <>
                {newComponent ? newComponent : (<>
                    <div className="page-body">
                        <div className="container-fluid">
                            <PageTitle title={'Lista de Atendimentos'} auth={auth} />
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h5>Últimos Atendimentos</h5>
                                        </div>
                                        <Table>
                                            {data && data.length > 0 ? data.map((a) => {
                                                return (<>
                                                    <Tr>
                                                        <Td classTd={"text-center"}>
                                                            <p>ID-{a._id}</p>
                                                        </Td>
                                                        <Td classTd={"text-center"}>
                                                            <p>{formatarDataHora(a.created_at)}</p>
                                                        </Td>
                                                        <Td classTd={"text-center"}>
                                                            <p>{a.name}</p>
                                                        </Td>
                                                        <Td classTd={"text-center"}>
                                                            <p>{formatarCPF(a.document)}</p>
                                                        </Td>
                                                        <Td classTd={"text-center"}>
                                                            <button id={a.document} onClick={(e) => { this.getAtendimentCpf(e) }} className="btn btn-sm btn-info">ABRIR FICHA</button>
                                                        </Td>
                                                    </Tr>
                                                </>)
                                            }) : <p className="text-center" style={{ padding: '15px' }}>Você ainda não possui atendimentos</p>}
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </>)}
            </>)
    }
}
