import { Component } from "react";
export default class ResetPassword extends Component {
    render() {
        return (

            <>
                <form className="theme-form">
                    <h4>Redefinir sua Senha</h4>
                    <div className="form-group">
                        <label className="col-form-label">Informe o Código</label>
                        <div className="form-input position-relative">
                            <input className="form-control" type="text" name="code" required="" placeholder="" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-form-label">Nova Senha</label>
                        <div className="form-input position-relative">
                            <input className="form-control" type="password" name="login[password]" required="" placeholder="*********" />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="col-form-label">Repita Nova Senha</label>
                        <input className="form-control" type="password" name="login[password]" required="" placeholder="*********" />
                    </div>
                    <div className="form-group mb-0">
                        <button className="btn btn-primary btn-block w-100" type="submit">Nova Senha</button>
                    </div>
                </form>
            </>
        )
    }
}