import { Component } from "react";
export class Table extends Component {
    render() {
        const { children } = this.props
        return (
            <>
                <div>
                    <div className="card-block row">
                        <div className="col-sm-12 col-lg-12 col-xl-12">
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <tbody>
                                        {children}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </>)
    }
}

export class Tr extends Component {
    render() {
        const { children, className } = this.props
        return (
            <>
                <tr className={className}>
                    {children}
                </tr>
            </>)
    }
}

export class Td extends Component {
    render() {
        const { children, classTd } = this.props
        return (
            <>
                <td className={classTd}>
                    {children}
                </td>
            </>)
    }
}