import { Component } from "react";
import PageTitle from "../../helpers/PageTitle";
import { Form, Input, SelectForm, InputFile, BtnForm } from "../../helpers/formData";
import MessageDisplay from "../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../messages/load";
import NoCompany from '../../../assets/images/proconsig/no_login.png'
import Company from "./companies";



export default class CreateCompany extends Component {
    constructor(props) {
        super(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            name: 'PROCONSIG',
            cnpj: '20.495.051/0001-49',
            responsavel: 'Geovane Cataneo Rodrigues',
            email: 'ge.cataneo@gmail.com',
            phone: '',
            logo: '',
            level: '',
            status: 'inactive',
            trial: false,
            trial_active: false,
            viewLogo: false,
            newComponent: ''
        }

        this.submitForm = this.submitForm.bind(this);
    }

    submitForm(e) {
        e.preventDefault();

        // Restante do código de envio do formulário...
        const msg = new MessageDisplay();

        const form = e.target;
        const formData = new FormData(form);
        const serializedForm = Object.fromEntries(formData.entries());
        serializedForm.company_id = this.state.auth.company_id._id
        serializedForm.logo = this.state.logo


        const tokenLocalStorage = window.localStorage.getItem("token");
        const tokenHash = window.localStorage.getItem("tokenHash");
        const login = window.localStorage.getItem("login");

        startLoad();

        fetch(`${process.env.REACT_APP_HTTP}/companie-create/${login}/${tokenHash}`, {
            method: "POST",
            crossDomain: true,
            headers: { "Content-Type": "application/json", "Accept": "application/json", authorization: `Bearer ${tokenLocalStorage}` },

            body: JSON.stringify({
                ...serializedForm
            }),
        })
            .then((res) => res.json())
            .then(async (res) => {

                if (res.error) {
                    stopLoad();
                    return msg.error("Empresa", res.data);
                }

                stopLoad();
                this.setState({
                    newComponent: <Company />
                })
                return msg.success("Empresa", 'Empresa cadastrada com sucesso');


            })
            .catch(() => {
                stopLoad();
                msg.error("Server", "Not connect");
            });
    }

    handleBlur = (e) => {
        const { type } = this.props;
        // Aplica a máscara de validação de e-mail se o tipo for 'email'
        if (type === 'email') {
            const inputValue = e.target.value;
            const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            if (inputValue && !emailRegex.test(inputValue)) {
                e.target.setCustomValidity('Por favor, insira um e-mail válido.');
            } else {
                e.target.setCustomValidity('');
            }
        }
    }

    selectTipeCompany(e) {
        const value = e.target.value
        this.setState({
            level: value,
            viewLogo: false,
            trial: false
        })

        if (value === 'whitelabel') {
            this.setState({
                viewLogo: true
            })
        }

        if (value === 'empresa') {
            this.setState({
                trial: true
            })
        }
    }

    testActive(e) {
        const value = e.target.value
        this.setState({
            trial_active: false,
        })

        if (value == 'active') {
            this.setState({
                trial_active: true,
            })
        }

    }

    handleImageChange(event) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64 = reader.result;
                this.setState({ logo: base64 });
            };
            reader.readAsDataURL(file);
        } else {
            this.setState({ logo: null });
        }
    };


    render() {
        const { viewLogo, logo, auth, newComponent, trial, trial_active } = this.state
        const type_company = [
            {
                value: 'whitelabel',
                label: 'WHITELABEL',
                role: ['master']
            },
            {
                value: 'revenda',
                label: 'REVENDA',
                role: ['whitelabel']
            }
            ,
            {
                value: 'empresa',
                label: 'EMPRESA',
                role: ['revenda', 'whitelabel']
            },

        ]

        const company_trial = [
            {
                value: 'active',
                label: 'Ativo',
            },
            {
                value: 'inactive',
                label: 'Inativo',
            }
        ]


        return (
            <>
                {newComponent ? newComponent : (
                    <div className="page-body">

                        <div className="container-fluid">
                            <PageTitle title={"Cadastrar Empresa"} auth={auth} />
                        </div>
                        <div className="container-fluid">
                            <div className="edit-profile">
                                <div className="row">
                                    {this.state.auth.level == 'master' ? (
                                        <div className="col-12">
                                            <div className="text-center mb-5">
                                                <img src={logo ? logo : NoCompany} style={logo ? { width: '20%' } : { width: '5%' }} alt="logo empresa" />
                                            </div>
                                        </div>
                                    ) : ''}
                                    <div className="col-xl-12">
                                        <Form title={'Nova Empresa'} onSubmit={(e) => this.submitForm(e)}>
                                            <Input
                                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                name={"name"}
                                                label={'Nome Empresa'}
                                                type={'text'}
                                                placeholder={"Nome da Empresa"}
                                            />
                                            <Input
                                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                // valueInput={cnpj}
                                                name={"cnpj"}
                                                mask={"99.999.999/9999-99"}
                                                type={'text'}
                                                label={'CNPJ'}
                                                placeholder={"Cnpj da empresa"}
                                            />
                                            <Input
                                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                name={"responsable"}
                                                type={'text'}
                                                label={'Responsável'}
                                                placeholder={"Resposável"}
                                            />
                                            <Input
                                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                name={"phone"}
                                                mask={"(99) 9-9999-9999"}
                                                type={'text'}
                                                label={'Telephone'}
                                                placeholder={"telefone / whatsapp"}
                                            />
                                            <Input
                                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                type={'email'}
                                                name={"email"}
                                                label={'E-mail Válido'}
                                                placeholder={"E-mail valído da empresa"}
                                            />

                                            <SelectForm
                                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                name={"level"}
                                                level={auth.level}
                                                labelName={'Tipo de Empresa'}
                                                onChange={(e) => { this.selectTipeCompany(e) }}
                                                data={type_company}>
                                            </SelectForm>

                                            <Input
                                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                type={'text'}
                                                name={"usuario"}
                                                label={'Usuário'}
                                                placeholder={"Usuário da Empresa"}

                                            />


                                            {viewLogo === true ? (
                                                <InputFile
                                                    classInput={"col-sm-6 col-md-6 col-xl-8"}
                                                    name={"logo"}
                                                    title={'Logo Empresa'}
                                                    onChange={(e) => { this.handleImageChange(e) }}
                                                />
                                            ) : ''}

                                            {trial === true ? (
                                                <>
                                                    <SelectForm
                                                        classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                        name={"trial"}
                                                        labelName={'Teste'}
                                                        onChange={(e) => { this.testActive(e) }}
                                                        data={company_trial}>
                                                    </SelectForm>
                                                    {trial_active ? (
                                                        <Input
                                                            classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                            type={'number'}
                                                            max={'3'}
                                                            name={"days_trial"}
                                                            label={'Total de Dias'}
                                                            placeholder={"Total de Dias de Teste"}
                                                        />
                                                    ) : ''}
                                                </>
                                            ) : ''}
                                            <BtnForm name={'Cadastrar Empresa'} />

                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}