import { Component } from "react";
import PageTitle from "../../helpers/PageTitle";
import { Form, Input, Select, BtnForm, InputFile } from "../../helpers/formData";
import MessageDisplay from "../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../messages/load";

export default class Perfil extends Component {
    constructor(props) {
        super(props)
        const auth = window.localStorage.getItem("user")

        this.state = {
            auth: JSON.parse(localStorage.getItem("user")),
        }

        this.submitForm = this.submitForm.bind(this);
    }

    submitForm(e) {

        e.preventDefault();
        const msg = new MessageDisplay();

        const tokenLocalStorage = window.localStorage.getItem("token");
        const login = window.localStorage.getItem("login");
        const tokenHash = window.localStorage.getItem("tokenHash");

        const form = e.target;
        const formData = new FormData(form);
        const serializedForm = Object.fromEntries(formData.entries());
        serializedForm._id = this.state.auth._id;


        startLoad();
        console.log(serializedForm)
        fetch(`${process.env.REACT_APP_HTTP}/user-edit/${login}/${tokenHash}`, {
            method: "PUT",
            crossDomain: true,
            headers: { "Content-Type": "application/json", "Accept": "application/json", authorization: `Bearer ${tokenLocalStorage}` },
            body: JSON.stringify({
                ...serializedForm
            }),
        })
            .then((res) => res.json())
            .then(async (res) => {

                if (res.error) {
                    stopLoad();
                    return msg.error("Perfil", res.data);
                }
                stopLoad();
                return msg.success("Perfil", 'Perfil atualizado com Sucesso');

            })
            .catch(() => {
                stopLoad();
                msg.error("Server", "Not connect");
            });
    }

    render() {
        const { auth } = this.state
        return (
            <>
                <div className="page-body">
                    <div className="container-fluid">
                        <PageTitle title={`Perfil ( ${auth.name} )`} />
                    </div>
                    <div className="container-fluid">
                        <div className="edit-profile">
                            <div className="row">
                                <div className="col-xl-12">
                                    <Form title={'Editar Perfil'} onSubmit={(e) => this.submitForm(e)}>
                                        <Input
                                            classInput={"col-sm-6 col-md-6 col-xl-6"}
                                            valueInput={auth.name}
                                            label={'Nome do Usuário'}
                                            type={'text'}
                                            name={'name'}
                                            placeholder={"Nome do Usuário"}
                                        />
                                        <Input
                                            classInput={"col-sm-6 col-md-6 col-xl-6"}
                                            valueInput={auth.login}
                                            type={'text'}
                                            label={'Login de Acesso'}
                                            name={'login'}
                                            placeholder={"Login"}
                                        />
                                        <Input
                                            classInput={"col-sm-6 col-md-6 col-xl-6"}
                                            label={'Senha de Acesso'}
                                            type={'password'}
                                            name={'password'}
                                            placeholder={"Senha de Acesso"}
                                        />
                                        <Input
                                            classInput={"col-sm-6 col-md-6 col-xl-6"}
                                            label={'Repetir Senha'}
                                            type={'password'}
                                            name={'password_confirm'}
                                            placeholder={"Repetir Senha"}
                                        />
                                        <InputFile
                                            title={'Foto Perfil'}
                                            onChange={(e) => this.setState({ logo: e })}
                                        />
                                        <BtnForm name={'Atualizar Perfil'} />
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}