import { Component } from "react";
import LogoDefault from "../../assets/images/proconsig/logo.png";
import Logout from '../auth/logout';
import NoAvatar from '../../assets/images/proconsig/no_avatar.jpg';
export default class LeftHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem("user")),
            companies: null
        }

    }

    render() {
        const { handleMenuClick, clickColor, menuSidebar } = this.props
        const { companies } = this.state
        const iconColorMenu = localStorage.getItem('iconColorMenu')
        const { auth } = this.state

        return (
            <>
                <div className="page-header close_icon">
                    <div className="header-wrapper row m-0">
                        <div className="header-logo-wrapper col-auto p-0">
                            <div className="logo-wrapper">
                                <a href="#" data-bs-original-title="" title="">
                                    <img className="img-fluid" src={LogoDefault} alt="" /></a>
                            </div>
                            <div className="toggle-sidebar" onClick={(e) => { menuSidebar(e) }}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    className="feather feather-align-center status_toggle middle sidebar-toggle"
                                >
                                    <line x1="18" y1="10" x2="6" y2="10" />
                                    <line x1="21" y1="6" x2="3" y2="6" />
                                    <line x1="21" y1="14" x2="3" y2="14" />
                                    <line x1="18" y1="18" x2="6" y2="18" />
                                </svg>
                            </div>
                        </div>
                        <div className="left-header col horizontal-wrapper ps-0">
                            <ul className="horizontal-menu">
                                <li className="level-menu outside">
                                    <ul className="header-level-menu menu-to-be-close" style={{ display: "none" }}>
                                        <li><a href="file-manager.html" data-original-title="" title="" data-bs-original-title="">                               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="feather feather-git-pull-request"><circle cx="18" cy="18" r="3"></circle><circle cx="6" cy="6" r="3"></circle><path d="M13 6h3a2 2 0 0 1 2 2v7"></path><line x1="6" y1="9" x2="6" y2="21"></line></svg><span>File manager    </span></a></li>
                                        <li><a href="#!" data-original-title="" title="" data-bs-original-title="">                               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg><span>Users</span></a>
                                            <ul className="header-level-sub-menu">
                                                <li><a href="file-manager.html" data-original-title="" title="" data-bs-original-title="">                               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg><span>User Profile</span></a></li>
                                                <li><a href="file-manager.html" data-original-title="" title="" data-bs-original-title="">                               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="feather feather-user-minus"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="23" y1="11" x2="17" y2="11"></line></svg><span>User Edit</span></a></li>
                                                <li><a href="file-manager.html" data-original-title="" title="" data-bs-original-title="">                               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="feather feather-user-check"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg><span>Users Cards</span></a></li>
                                            </ul>
                                        </li>
                                        <li><a href="kanban.html" data-original-title="" title="" data-bs-original-title="">                               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="feather feather-airplay"><path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path><polygon points="12 15 17 21 7 21 12 15"></polygon></svg><span>Kanban Board</span></a></li>
                                        <li><a href="bookmark.html" data-original-title="" title="" data-bs-original-title="">                               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg><span>Bookmark</span></a></li>
                                        <li><a href="social-app.html" data-original-title="" title="" data-bs-original-title="">                               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg><span>Social App                     </span></a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="nav-right col-8 pull-right right-header p-0">
                            <ul className="nav-menus">
                                {/* <div style={{ width: "50%", marginRight: "10px" }}>
                                    <Select
                                        options={companies}
                                        onChange={(e) => { this.handleChange(e) }}
                                        isSearchable={true} // Habilita a pesquisa
                                        placeholder="Pesquisar Empresas ..." // Texto do placeholder
                                        noOptionsMessage={() => 'Nenhuma Empresa Encontrada'} // Mensagem exibida quando não há opções encontradas
                                    />
                                </div> */}
                                <li>
                                    <div className="mode" onClick={(e) => { clickColor(e) }}><i id={'iconColorPainel'} className={iconColorMenu ? iconColorMenu : 'fa fa-lightbulb-o'}></i></div>
                                </li>
                                <li className="profile-nav onhover-dropdown p-0 me-0">
                                    <div className="media profile-media">
                                        <img className="b-r-10" style={{ width: "40px" }} src={NoAvatar} alt="" />
                                        <div className="media-body">
                                            <span>{auth.name} <i className="middle fa fa-angle-down"></i></span>
                                            <p className="mb-0 fw-lighter font-roboto text-capitalize">{auth.level}</p>
                                            {/* <p>
                                                <span className="fw-lighter text-white" style={{background: '#ADB5BD', borderRadius: '5px', padding: '1px 6px', fontSize: '0.7rem'}}>{auth.level}</span>
                                            </p> */}
                                        </div>
                                    </div>
                                    <ul className="profile-dropdown onhover-show-div">
                                        <li menukey="perfil" onClick={handleMenuClick.bind(this, "perfil")}><a href="#" data-bs-original-title="" title=""><i className="fa fa-user" style={{ marginRight: '8px' }}></i><span> Perfil </span></a></li>
                                        {/* {auth && auth.level == 'empresa' ? (
                                            <li menukey="api" onClick={handleMenuClick.bind(this, "api")}><a href="#" data-bs-original-title="" title=""><i className="fa fa-download" style={{ marginRight: '8px' }}></i><span> Api </span></a></li>
                                        ) : ''} */}
                                        {auth && auth.level == 'operador' ? (
                                            <li menukey="ramal" onClick={handleMenuClick.bind(this, "ramal")}><a href="#" data-bs-original-title="" title=""><i className="fa fa-headphones" style={{ marginRight: '8px' }}></i><span>Ramal </span></a></li>
                                        ) : ''}
                                        <li onClick={(e) => { Logout() }}>
                                            <a href="#" data-bs-original-title="" title="" >
                                                <icon style={{ marginRight: '8px' }} className='fa fa-sign-out'></icon>
                                                <span>Sair</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}