import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { Component } from "react";
import Login from "../components/login";
import ForgetPassWord from "../components/auth/forget-password";
import UpdatePassword from "../components/auth/update-password";
import ResetPassword from "../components/auth/reset-password";
import NotFoundPage from "../components/auth/404";
import Painel from "../components/painel/painel"
import ServerErrorPage from "../components/auth/500";
import ForbiddenPage from "../components/auth/403";
import { startLoad, stopLoad } from "../components/messages/load";
import MessageDisplay from "../components/messages/messageDisplay";
import logOut from "../components/auth/logout";
import _WebSocket, { SendSocket, OnSocket } from '../components/helpers/socket';
import sessionClient from '../components/helpers/session';
import { ApiRequest } from "../components/helpers/Api";
import loadingText from "../components/messages/loadingText";

export default class PanelRouters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true,
            error: false,
            socket: false,
            errorSocket: false,
            statusWebsocketConnect: null,
            whiteLabelSettings: null,
        };
    }

    async getInfoClient() {

        const tokenLocalStorage = window.localStorage.getItem("token");
        const loginLocalStorage = window.localStorage.getItem("login");
        const tokenHash = window.localStorage.getItem('tokenHash');
        try {
            const response = await fetch(`${process.env.REACT_APP_HTTP}/user-data/${tokenLocalStorage}/${loginLocalStorage}/${tokenHash}`, {
                method: "GET",
                crossDomain: true,
                headers: { "Content-Type": "application/json", Accept: "application/json", authorization: `Bearer ${tokenLocalStorage}` },
            });

            const dataUser = await response.json();
            if (dataUser.token) {
                window.localStorage.setItem('user', JSON.stringify(dataUser.data))
                window.localStorage.setItem("token", dataUser.token);
            } else {
                if (loginLocalStorage) {
                    const msg = new MessageDisplay()
                    msg.error("Login", dataUser.data);
                    setTimeout(logOut, 1000);

                    return;
                }
            }
            this.setState({ data: dataUser, loading: false });

        } catch {
            setTimeout(logOut, 1000);
            this.setState({ data: null, loading: false, error: true });
        }
    }

    async componentDidMount() {
        await this.getCompanieWhiteLabel().then(() => {
            startLoad();
            setTimeout(async () => {
                this.connectSocket();
                await this.getInfoClient();
                stopLoad();
            }, 2000)
        });
    }


    async getCompanieWhiteLabel() {
        const currentDomain = window.location.hostname;
        const api = new ApiRequest()
        const result = await api.get(`companie-whitelabel/${currentDomain}`);

        if (result.error == false) {
            localStorage.setItem('whiteLabelSettings', JSON.stringify(result.company_white))
        }
    }


    connectSocket() {
        const tokenHashLocalStorage = window.localStorage.getItem('tokenHash');
        window.connSocket = new _WebSocket();
        window.connSocket.connect(async (socket) => {
            if (socket) {

                console.log('Connectado ao Socket');
                await sessionClient.newSession("socket");
                await sessionClient.addInfoSession("socket", {
                    server: socket
                });
                if (tokenHashLocalStorage) {
                    await sessionClient.newSession(tokenHashLocalStorage);
                    await sessionClient.addInfoSession(tokenHashLocalStorage, {
                        sendSocket: new SendSocket(socket, tokenHashLocalStorage),
                        onSocket: new OnSocket(socket, tokenHashLocalStorage)
                    });

                    const tokenLocalStorage = window.localStorage.getItem("token");
                    const loginLocalStorage = window.localStorage.getItem("login");

                    const client = await sessionClient.getSession(tokenHashLocalStorage);

                    client.onSocket.onIsLogin(false, (data) => {
                        const [{ login }] = data;
                        if (login) {

                            client.sendSocket.sendLogin([
                                {
                                    login: loginLocalStorage,
                                    token: tokenLocalStorage,
                                    tokenHash: tokenHashLocalStorage
                                }
                            ]);
                        }
                    });

                    client.onSocket.onNewToken(false, (data) => {
                        if (!data.length) {
                            return;
                        }
                        const [{ newtoken }] = data;
                        window.localStorage.setItem("token", newtoken);
                    });

                    client.onSocket.onCheckTotalUsers([{ token_acces: tokenHashLocalStorage }]);
                }

                this.setState({ socket: true, errorSocket: false, statusWebsocketConnect: true });


            } else {
                this.setState({ socket: true, errorSocket: false, statusWebsocketConnect: false });

            }
        })
    }

    render() {
        const { isLoggeIn } = this.props;
        const { data, loading, socket, error, errorSocket, statusWebsocketConnect } = this.state;
        
        if ((!error && !errorSocket && !socket) || (!error && !errorSocket && loading)) {
            return loadingText();
        }

        if (error || errorSocket) {
            const desc = error ? "Servidor backend está fora" : "Servidor socket está fora";
            return ServerErrorPage(desc);
        }
        
        stopLoad();

        if (data.error && window.location.pathname === "/painel") {
            const msg = new MessageDisplay()
            msg.error("Erro", 'Desculpe, ocorreu um um erro no painel. Tente novamente mais tarde');
            // setTimeout(logOut, 1000);
        }

        return (
            <Router>
                <Routes>
                    {/* <Route exact path="/dash" element={isLoggeIn === "true" ? <Painel /> : <Login />} /> */}
                    <Route path="/" element={isLoggeIn === "true" ? <Painel statusWebsocketConnect={statusWebsocketConnect} /> : <Login />} />
                    <Route path="/forget-password" element={<ForgetPassWord />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/update-password" element={<UpdatePassword />} />
                    <Route path="/forbidden" element={<ForbiddenPage />} status={403} />
                    <Route path="/server-error" element={<ServerErrorPage />} status={500} />
                    <Route path="*" element={<NotFoundPage />} status={404} />
                </Routes>
            </Router>
        )
    }
}


