
import { Component } from "react";
import { notification, Alert } from 'antd';

export default class MessageDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // inicializa o estado vazio
    };
  }

  showMessage = (type, title, msg) => {
    notification.config({maxCount: 1})
    notification[type]({
      message: title,
      description: msg,
      // duration: 2.5,
      placement: 'topRight',
      getContainer: () => document.body,
      style: {
        zIndex: 999999
      }
    });
  };

  success = (title, msg) => {
    this.showMessage('success', title, msg);
  };

  error = (title, msg) => {
    this.showMessage('error', title, msg);
  };

  warning = (title, msg) => {
    this.showMessage('warning', title, msg);
  };

  info = (title, msg) => {
    this.showMessage('info', title, msg);
  };

  render() {
    // renderiza um componente vazio
    return null;
  }
}