import { Component } from "react";
import PageTitle from "../../helpers/PageTitle";
import { Form, Input, BtnForm, InputFile } from "../../helpers/formData";
import MessageDisplay from "../../messages/messageDisplay";
import { ApiRequest } from "../../helpers/Api";

export default class WhiteLabel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            logo: false,
            favicon: false,
        }
        this.handleImageChange = this.handleImageChange.bind(this)
    }

    handleImageChange(event) {
        const value = event.target.name
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64 = reader.result;
                if (value == 'logo') {
                    this.setState({ logo: base64 });
                }

                if (value == 'favicon') {
                    this.setState({ favicon: base64 });
                }
            };
            reader.readAsDataURL(file);
        } else {
            this.setState({ logo: null });
        }
    };

    async submitForm(e) {

        const { logo, favicon } = this.state
        const files = {
            logo: logo,
            favicon: favicon
        }

        const msg = new MessageDisplay();
        const api = new ApiRequest();
        const result = await api.post(`companie-update-whitelabel`, e, true, files)
        console.log(result)
        if (result.error) {
            return msg.error("Empresa", result.data);
        }

        msg.success('Empresa', 'Sua empresa foi atualizada com sucesso')
        this.setState({
            
        })

    }


    render() {
        const { auth, logo, favicon } = this.state
        const logoCompany = logo ? logo : auth.company_id?.logo ? auth.company_id?.logo : null
        const favIconCompany = favicon ? favicon : auth.company_id?.favicon ? auth.company_id?.favicon : null
        return (<>
            <div className="page-body">
                <div className="container-fluid">
                    <PageTitle title={"Editar Empresa"} auth={auth} />
                </div>
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-xl-12 col-md-12 box-col-12">
                            <div className="file-content">
                                <div className="card">
                                    <div className="card-body file-manager">
                                        <h4 className="mb-3">Logos da Empresa</h4>
                                        <h6>Logo e FavIcon</h6>
                                        <ul className="files">
                                            <li className="file-box" style={{ width: '50%' }}>
                                                {logoCompany ? (<>
                                                    <img style={{ width: '45%', maxWidth: '45%' }} src={logoCompany} title="FavIcon" alt="FavIcon" />
                                                </>)
                                                    : (<>
                                                        <div className="file-top">
                                                            <i className="fa fa-file-image-o txt-primary"></i>
                                                        </div>
                                                    </>
                                                    )}
                                                <div className="file-bottom">
                                                    <h6>Logo</h6>
                                                </div>
                                            </li>
                                            <li className="file-box" style={{ width: '50%' }}>

                                                {favIconCompany ?
                                                    (<>
                                                        <img style={{ width: '45%', maxWidth: '15%' }} src={favIconCompany} title="FavIcon" alt="FavIcon" />
                                                    </>
                                                    ) : (<>
                                                        <div className="file-top">
                                                            <i className="fa fa-file-photo-o txt-secondary"></i>
                                                        </div>
                                                    </>)}
                                                <div className="file-bottom">
                                                    <h6>FavIcon</h6>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="edit-profile">
                        <div className="row">
                            <div className="col-xl-12">
                                <Form title={`Atualizar Empresa ${auth.company_id.name}`} onSubmit={(e) => this.submitForm(e)}>
                                    <Input
                                        classInput={"col-sm-12"}
                                        label={'URL Domínio'}
                                        valueInput={auth.company_id._id}
                                        type={'hidden'}
                                        name={'id'}
                                        placeholder={"Domínio da Empresa"}
                                    />
                                    <Input
                                        classInput={"col-sm-12"}
                                        label={'URL Domínio'}
                                        valueInput={auth.company_id.domain}
                                        type={'text'}
                                        name={'domain'}
                                        placeholder={"Domínio da Empresa"}
                                    />
                                    <Input
                                        classInput={"col-sm-6 col-md-6 col-xl-4"}
                                        label={'Nome da Empresa'}
                                        valueInput={auth.company_id.name}
                                        type={'text'}
                                        name={'name'}
                                        placeholder={"Nome da Empresa"}
                                    />
                                    <InputFile
                                        classInput={"col-sm-6 col-md-6 col-xl-4"}
                                        title={'Logo Tamanho 150x150'}
                                        name={'logo'}
                                        id={`logo`}
                                        onChange={(e) => { this.handleImageChange(e) }}

                                    />
                                    <InputFile
                                        classInput={"col-sm-6 col-md-6 col-xl-4"}
                                        title={'FavIcon 150x150'}
                                        name={'favicon'}
                                        id={`favicon`}
                                        onChange={(e) => { this.handleImageChange(e) }}
                                    />

                                    <BtnForm name={'Atualizar Configurações'} />
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
}