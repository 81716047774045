import { Component } from "react";
import PageTitle from "../../helpers/PageTitle";
import { Form, Input, BtnForm } from "../../helpers/formData";
import { ApiRequest } from "../../helpers/Api";
import MessageDisplay from "../../messages/messageDisplay";

export default class RamalOper extends Component {
    constructor(props) {
        super(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem("user")),
        }
        this.submitForm = this.submitForm.bind(this)
    }


    async submitForm(e) {
        e.preventDefault();
        const api = new ApiRequest();

        const msg = new MessageDisplay();
        const result = await api.post(`user-update-ramal`, e)
        if (result.error === true && result) {
            msg.error('Usuários', result.data)
            return;
        }

        if (result !== false) {
            msg.success('Usuários', 'Ramal atualizado com Sucesso')
            return;
        }
    }


    render() {
        const { auth } = this.state
        return (
            <>
                <div className="page-body">
                    <div className="container-fluid">
                        <PageTitle title={`Ramal ( ${auth.name} )`} />
                    </div>
                    <div className="container-fluid">
                        <div className="edit-profile">
                            <div className="row">
                                <div className="col-xl-12">
                                    <Form title={'Ramal Discadora'} onSubmit={(e) => this.submitForm(e)}>

                                        <Input
                                            classInput={"col-sm-12 col-md-12 col-xl-12"}
                                            valueInput={auth._id}
                                            type={'hidden'}
                                            name={'_id'}
                                            placeholder={"Ramal do Usuário"}
                                        />

                                        <Input
                                            classInput={"col-sm-12 col-md-12 col-xl-12"}
                                            valueInput={auth.ramal}
                                            label={'Ramal Usuário'}
                                            type={'text'}
                                            name={'ramal'}
                                            placeholder={"Ramal do Usuário"}
                                        />
                                        <BtnForm name={'Atualizar Ramal'} />
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}