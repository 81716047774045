import React, { useState, useEffect } from "react";
import PageTitle from "../../../helpers/PageTitle";
import CoefficientModal from "./CoefficientModal";
import ConfirmationModal from "./ConfirmationModal";
import { formatBank, listBanks } from "../../../helpers/FuncHelpers";

const CoefficientList = () => {
  const [coefficients, setCoefficients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCoefficient, setSelectedCoefficient] = useState(null);
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedTipo, setSelectedTipo] = useState("");
  const [selectedConvenio, setSelectedConvenio] = useState("");
  const [selectedEquipe, setSelectedEquipe] = useState("");
  const [coefficientsList, setCoefficientsList] = useState([]);

  const tokenLocalStorage = window.localStorage.getItem("token");
  const tokenHash = window.localStorage.getItem("tokenHash");
  const login = window.localStorage.getItem("login");
  const auth = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    getCoefficients();
  }, []);

  const getCoefficients = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HTTP}/coefficients-get-all/${login}/${tokenHash}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            authorization: `Bearer ${tokenLocalStorage}`,
          },
        }
      );
      const data = await response.json();
      setCoefficients(data.data);
    } catch (error) {
      console.log("Erro ao buscar coeficientes", error);
    }
  };

  const handleCreateCoefficient = () => {
    setShowCreateModal(true);
  };

  const handleModalClose = () => {
    setShowCreateModal(false);
    setShowEditModal(false);
    setShowDeleteModal(false);
    setSelectedCoefficient(null);
  };

  const handleCoefficientCreate = async (formData) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HTTP}/coefficient-create/${login}/${tokenHash}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            authorization: `Bearer ${tokenLocalStorage}`,
          },
          body: JSON.stringify({
            company_id: auth.company_id._id,
            ...formData,
          }),
        }
      );

      const data = await response.json();
      setLoading(false);

      if (data.error) {
        // console.log("Erro ao criar coeficiente", data.data);
      } else {
        getCoefficients();
        setShowCreateModal(false);
      }
    } catch (error) {
      // console.log("Erro ao criar coeficiente", error);
      setLoading(false);
    }
  };

  const handleCoefficientEdit = (coefficient) => {
    setSelectedCoefficient(coefficient);
    setShowEditModal(true);
  };

  const handleCoefficientUpdate = async (formData) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HTTP}/coefficient-edit/${selectedCoefficient._id}/${login}/${tokenHash}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            authorization: `Bearer ${tokenLocalStorage}`,
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();
      setLoading(false);

      if (data.error) {
        console.log("Erro ao atualizar coeficiente", data.data);
      } else {
        getCoefficients();
        setShowEditModal(false);
      }
    } catch (error) {
      console.log("Erro ao atualizar coeficiente", error);
      setLoading(false);
    }
  };

  const handleCoefficientDelete = (coefficient) => {
    setSelectedCoefficient(coefficient);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HTTP}/coefficient-delete/${selectedCoefficient._id}/${login}/${tokenHash}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            authorization: `Bearer ${tokenLocalStorage}`,
          },
        }
      );

      const data = await response.json();
      setLoading(false);

      if (data.error) {
        console.log("Erro ao excluir coeficiente", data.data);
      } else {
        getCoefficients();
        setShowDeleteModal(false);
      }
    } catch (error) {
      console.log("Erro ao excluir coeficiente", error);
      setLoading(false);
    }
  };

  const handleBankFilter = (e) => {
    const { value } = e.target;
    setSelectedBank(value);
  };

  const handleTipoFilter = (e) => {
    const { value } = e.target;
    setSelectedTipo(value); // Set the selectedTipo state to the _id of the selected type
  };

  const handleConvenioFilter = (e) => {
    const { value } = e.target;
    setSelectedConvenio(value); // Set the selectedConvenio state to the _id of the selected convenio
  };

  const handleEquipeFilter = (e) => {
    const { value } = e.target;
    console.log('..........', value)
    setSelectedEquipe(value);
  };

  useEffect(() => {
    const coefficientsListFn = async () => {
      try {
        const auth = JSON.parse(localStorage.getItem("user"));

        const mapBankValue = (coefficient) => {
          const bank = listBanks();
          coefficient.bankValue = bank.find(
            (b) => b.value === formatBank(coefficient.banco)
          );
        };

        const mapEquipe = (coefficient) => {
          coefficient.equipe = auth?.teams?.find((t) => t?._id === coefficient?.teams_id)
            ? auth.teams.find((t) => t._id === coefficient.teams_id).name
            : "";
        };

        const mapTipo = (coefficient) => {
          const tipos = [
            {
              _id: "margem",
              name: "Margem",
            },
            {
              _id: "cartao",
              name: "Cartão",
            },
            {
              _id: "portabilidade",
              name: "Portabilidade",
            },
          ];
          coefficient.tipo = tipos.find((t) => t?._id === coefficient?.tipo)
            ? tipos.find((t) => t._id === coefficient.tipo).name
            : "";
        };

        const mapConvenio = (coefficient) => {
          coefficient.convenio = auth?.company_id?.convenios?.find(
            (c) => c?._id === coefficient?.convenio
          )
            ? auth.company_id.convenios.find((c) => c._id === coefficient.convenio).name
            : "";
        };

        const applyFilters = (coefficient) => {
          if (
            (selectedBank !== "" && coefficient.bankValue?.value !== selectedBank) ||
            (selectedTipo !== "" && coefficient.tipo !== selectedTipo) ||
            (selectedConvenio !== "" && coefficient.convenio !== selectedConvenio) ||
            (selectedEquipe !== "" && coefficient.equipe !== selectedEquipe)
          ) {
            return null;
          }
          return coefficient;
        };

        const coefficientsCopy = JSON.parse(JSON.stringify(coefficients));
        coefficientsCopy.forEach((coefficient) => {
          mapBankValue(coefficient);
          mapEquipe(coefficient);
          mapTipo(coefficient);
          mapConvenio(coefficient);
        });

        const filteredCoefficients = coefficientsCopy
          .filter(applyFilters)
          .filter((coefficient) => coefficient !== null);

        setCoefficientsList(filteredCoefficients);
      } catch (error) {
        console.log("Error processing coefficients list", error);
      }
    };

    coefficientsListFn();
  }, [
    coefficients,
    selectedBank,
    selectedTipo,
    selectedConvenio,
    selectedEquipe,
  ]);

  return (
    <div className="page-body">
      <div className="container-fluid">
        <PageTitle
          title={"Listagem de Coeficientes"}
          nameCompany={"Proconsig"}
        />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div>
                    <h5>Coeficientes</h5>
                  </div>
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={handleCreateCoefficient}
                    >
                      Criar Novo
                    </button>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor="bankFilter">Filtrar por Banco:</label>
                        <select
                          id="bankFilter"
                          className="form-control"
                          value={selectedBank}
                          onChange={handleBankFilter}
                        >
                          <option value="">Todos</option>
                          {listBanks().map((bank) => (
                            <option key={bank.value} value={bank.value}>
                              {bank.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor="tipoFilter">Filtrar por Tipo:</label>
                        <select
                          id="tipoFilter"
                          className="form-control"
                          value={selectedTipo}
                          onChange={handleTipoFilter}
                        >
                          <option value="">Todos</option>
                          {[
                            {
                              _id: "margem",
                              name: "Margem",
                            },
                            {
                              _id: "cartao",
                              name: "Cartão",
                            },
                            {
                              _id: "portabilidade",
                              name: "Portabilidade",
                            },
                          ].map((convenio, i) => (
                            <option key={i} value={convenio.name}>
                              {convenio.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor="equipeFilter">
                          Filtrar por Equipe:
                        </label>
                        <select
                          id="equipeFilter"
                          className="form-control"
                          value={selectedEquipe}
                          onChange={handleEquipeFilter}
                        >
                          <option value="">Todos</option>
                          {auth.teams.map((team) => (
                            <option key={team._id} value={team.name}>
                              {team.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor="convenioFilter">
                          Filtrar por Convênio:
                        </label>
                        <select
                          id="convenioFilter"
                          className="form-control"
                          value={selectedConvenio}
                          onChange={handleConvenioFilter}
                        >
                          <option value="">Todos</option>
                          {auth?.company_id?.convenios?.map((convenio) => (
                            <option key={convenio._id} value={convenio.name}>
                              {convenio.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {coefficientsList.length > 0 ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" className="text-center">
                        Nome
                      </th>
                      <th scope="col" className="text-center">
                        Banco
                      </th>
                      <th scope="col" className="text-center">
                        Fator
                      </th>
                      <th scope="col" className="text-center">
                        Prazo
                      </th>
                      <th scope="col" className="text-center">
                        Tipo
                      </th>
                      <th scope="col" className="text-center">
                        Equipe
                      </th>
                      <th scope="col" className="text-center">
                        Convenio
                      </th>
                      <th scope="col" className="text-center">
                        Mínimo Portado
                      </th>
                      <th scope="col" className="text-center">
                        Ações
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {coefficientsList.map((coefficient, i) => (
                      <>
                        <tr key={i}>
                          <td className="text-center">{coefficient.nome}</td>
                          <td className="text-center">
                            {coefficient.bankValue?.label}
                          </td>
                          <td className="text-center">{coefficient.fator}</td>
                          <td className="text-center">{coefficient.prazo}</td>
                          <td className="text-center">{coefficient.tipo}</td>
                          <td className="text-center">{coefficient.equipe}</td>
                          <td className="text-center">
                            {coefficient.convenio}
                          </td>
                          <td className="text-center">
                            {coefficient.minimoPortado}
                          </td>
                          <td className="text-center">
                            <div className="btn-group">
                              {/* <button onClick={() => handleCoefficientEdit(coefficient)} className="btn btn-secondary" title="Editar Coeficiente">
                              <i className="fa fa fa-edit"></i>
                            </button> */}
                              <button
                                onClick={() =>
                                  handleCoefficientDelete(coefficient)
                                }
                                className="btn btn-xs btn-danger"
                                title="Excluir Coeficiente"
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-center mt-3">
                  Não há coeficientes cadastrados
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      {showCreateModal && (
        <CoefficientModal
          show={showCreateModal}
          onClose={handleModalClose}
          onCreate={handleCoefficientCreate}
        />
      )}
      {showEditModal && (
        <CoefficientModal
          show={showEditModal}
          onClose={handleModalClose}
          onCreate={handleCoefficientUpdate}
          initialData={selectedCoefficient}
        />
      )}
      {showDeleteModal && (
        <ConfirmationModal
          show={showDeleteModal}
          onClose={handleModalClose}
          onConfirm={handleConfirmDelete}
          message="Deseja realmente excluir o coeficiente?"
        />
      )}
      {loading && <div className="loading">Carregando...</div>}
    </div>
  );
};

export default CoefficientList;
