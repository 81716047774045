import { Component } from "react";
import PageTitle from "../../../helpers/PageTitle";
import { Form, Input } from "../../../helpers/formData";
import CreateMailing from "../create";
import ReturnPage from "../../../helpers/ReturnPage";

export default class CreateMailingGov extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            newComponent: ''
        }
    }

    returPage = () => {
        this.setState({
            newComponent: <CreateMailing />
        })
    };
    render() {
        const { name, newComponent } = this.state
        return (
            <>
                {newComponent ? newComponent : (
                    <div className="page-body">
                        <div className="container-fluid">
                            <PageTitle title={"Mailing Governo"} nameCompany={"Proconsig"} />
                        </div>
                        <div className="container-fluid">
                        <ReturnPage OnClick={this.returPage} />
                            <div className="edit-profile">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <Form title={'Nova Empresa'} onSubmit={(e) => this.submitForm(e)}>
                                            <Input
                                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                valueInput={name}
                                                label={'Name'}
                                                onChange={(e) => this.setState({ name: e })}
                                                placeholder={"Nome da Empresa"}
                                            />
                                            <Input
                                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                valueInput={name}
                                                label={'Name'}
                                                onChange={(e) => this.setState({ name: e })}
                                                placeholder={"Nome da Empresa"}
                                            />
                                            <Input
                                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                valueInput={name}
                                                label={'Name'}
                                                onChange={(e) => this.setState({ name: e })}
                                                placeholder={"Nome da Empresa"}
                                            />
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}