import { Component } from "react";
import PageTitle from "../../helpers/PageTitle";
import MessageDisplay from "../../messages/messageDisplay";
import UsersList from "../../helpers/UsersList";
import { startLoad, stopLoad } from "../../messages/load";
import EditUser from './edit';
import ButtonSearch from "../../helpers/ButtonSearch";

export default class ListUser extends Component {

    constructor(props) {
        super(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            newComponent: false,
            data: [],
            typeConsult: 'active',
        }
        this.getUsers = this.getUsers.bind(this);
    }


    componentDidMount() {
        this.getUsers()
    }

    async getUsers() {
        const { typeConsult } = this.state
        const msg = new MessageDisplay();
        // Restante do código de envio do formulário...
        startLoad();
        const tokenLocalStorage = window.localStorage.getItem("token");
        const tokenHash = window.localStorage.getItem("tokenHash");
        const login = window.localStorage.getItem("login");

        fetch(`${process.env.REACT_APP_HTTP}/users-get-all/${typeConsult}/${login}/${tokenHash}`, {
            method: "GET",
            crossDomain: true,
            headers: { "Content-Type": "application/json", "Accept": "application/json", authorization: `Bearer ${tokenLocalStorage}` },
        })
            .then((res) => res.json())
            .then(async (res) => {

                if (res.error) {
                    msg.error("Usuários", "Acesso Negado");
                }
                stopLoad();
                this.setState({
                    data: res.data
                })

            })
            .catch(() => {
                stopLoad();
                msg.error("Server", "Not connect");
            });
    }

    dataResult(e) {
        console.log('asdfasdfasfasd', e)
        if (e !== '') {
            this.setState({
                data: e
            })
        }
    }

    async typeConsult(e) {
        console.log(e)
        if (e == 'active') {
            this.setState({
                typeConsult: 'inactive'
            }, () => this.getUsers())
        }
        if (e == 'inactive') {
            this.setState({
                typeConsult: 'active'
            }, () => this.getUsers())
        }
    }

    render() {

        const { data, newComponent, typeConsult, auth } = this.state

        return (
            <>
                {newComponent ? newComponent : (
                    <div className="page-body">
                        <div className="container-fluid">
                            <PageTitle title={'Usuários'} auth={auth} />
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5>Usuários</h5>
                                                </div>
                                                <div>
                                                    <button onClick={(e) => this.typeConsult(typeConsult)} className={`btn btn-sm btn-${typeConsult == 'active' ? 'danger' : 'success'}`} style={{ marginRight: '5px' }}>{typeConsult == 'active' ? 'Inativos' : 'Ativos'}</button>
                                                    <ButtonSearch onFunction={this.getUsers} type={"users"} onResult={(e) => { this.dataResult(e) }} />
                                                </div>
                                            </div>
                                        </div>
                                        {data ? (
                                            <UsersList data={data} />
                                        ) : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                )}
            </>
        )
    }
}

