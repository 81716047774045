import { Component } from "react";
import { SelectForm, InputData, InputCurrency, Input } from "../../../../helpers/formData";

export default class CaracteristicasInss extends Component {
    render() {
        return (
            <>
                <Input
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={'idade_de'}
                    label={'Idade de'}
                    placeholder={"Idade de"}
                />
                <Input
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={'idade_ate'}
                    label={'Idade até'}
                    placeholder={"Idade até"}
                />

                <InputData
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={'concessao_de'}
                    label={'Consessão de:'}
                    placeholder={"Consessão de:"}
                />

                <InputData
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={'concessao_ate'}
                    label={'Consessão até:'}
                    placeholder={"Consessão até:"}
                />
                <Input
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    type={'number'}
                    min={0}
                    name={'quantidade_emprestimos_de'}
                    label={'Quantidade de Empréstimos De'}
                    placeholder={"Quantidade de Empréstimos De"}
                />
                <Input
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    type={'number'}
                    min={0}
                    name={'quantidade_emprestimos_ate'}
                    label={'Quantidade de Empréstimos Até'}
                    placeholder={"Quantidade de Empréstimos Até"}
                />

                <InputCurrency
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={'renda_de'}
                    label={'Renda de:'}
                    placeholder={"Renda de:"}
                />


                <InputCurrency
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={'renda_ate'}
                    label={'Renda até:'}
                    placeholder={"Renda até:"}
                />

                {/* <SelectForm
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={"sexo"}
                    optionLebel="Selecione o Sexo:"
                    labelName={'Sexo'}
                    data={[
                        {
                            label: 'Ambos',
                            value: 'ambos'
                        },
                        {
                            label: 'Masculino',
                            value: 'MASCULINO'
                        },
                        {
                            label: 'Feminino',
                            value: 'FEMININO'
                        }
                    ]}>
                </SelectForm> */}

                <SelectForm
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={"formas_recebimento"}
                    optionLebel="Forma de Recebimento:"
                    labelName={'Forma de Recebimento'}
                    data={[
                        {
                            label: 'Ambos',
                            value: 'ambos'
                        },
                        {
                            label: 'Conta Corrente',
                            value: 'CONTA CORRENTE'
                        },
                        {
                            label: 'Cartão Magnético',
                            value: 'CARTÃO MAGNETICO'
                        }
                    ]}>
                </SelectForm>

                <SelectForm
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    optionLebel="Selecione a opção:"
                    name={"beneficio_bloqueado"}
                    labelName={'Beneficio Bloqueado'}
                    data={[
                        {
                            label: 'Ambos',
                            value: 'ambos'
                        },
                        {
                            label: 'Não',
                            value: 'no'
                        },
                        {
                            label: 'Sim',
                            value: 'yes'
                        }
                    ]}>
                </SelectForm>

                <SelectForm
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={"representante_legal"}
                    labelName={'Representante Legal'}
                    data={[
                        {
                            label: 'Ambos',
                            value: 'ambos'
                        },
                        {
                            label: 'Não',
                            value: 'no'
                        },
                        {
                            label: 'Sim',
                            value: 'yes'
                        }
                    ]}>
                </SelectForm>
            </>
        )
    }
}