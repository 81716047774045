import React, { Component } from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/team-talking.json';

export class TeamTalkingLottie extends Component{

    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };

        const { height, width } = this.props;
        const heightUsed = height ? height : 290;
        const widthUsed = width ? width : 300;

        return (
            <Lottie 
                options={defaultOptions}
                height={heightUsed}
                width={widthUsed}
            />
        )
    }
}