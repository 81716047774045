import { Component } from "react";
import Select from 'react-select';
import { listBeneficios } from "../../../../helpers/FuncHelpers";
window.considerarBeneficios = [];
window.desconsiderarBeneficios = [];
export default class BeneficiosInss extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isDisabledBeneficio: false,
            isDisableDesconsiderarBeneficio: false,
            beneficiosConsiderar: [],
            beneficiosDesconsiderar: []
        }

        this.hangeDesconsidera = this.hangeDesconsidera.bind(this)
        this.changeConsidera = this.changeConsidera.bind(this)
        this.handleSelectChangeConsiderar = this.handleSelectChangeConsiderar.bind(this);
        this.handleSelectChangeDesconsiderar = this.handleSelectChangeDesconsiderar.bind(this);
    }

    handleSelectChangeConsiderar(selectedOptions) {
        this.changeConsidera(selectedOptions)
        window.desconsiderarBeneficios = [];
        window.considerarBeneficios = selectedOptions.map(option => option.value)
        this.setState({
            beneficiosConsiderar: selectedOptions.map(option => option.value)
        });
        console.log('considerar', window.considerarBeneficios)
    }


    handleSelectChangeDesconsiderar(selectedOptions) {
        this.hangeDesconsidera(selectedOptions)
        window.considerarBeneficios = [];
        window.desconsiderarBeneficios = selectedOptions.map(option => option.value)
        this.setState({
            beneficiosDesconsiderar: selectedOptions.map(option => option.value)
        });

        console.log('desconsiderar', window.desconsiderarBeneficios)
    }

    hangeDesconsidera(e) {
        this.setState({
            isDisabledBeneficio: true
        })
        if (e.length <= 0) {
            this.setState({
                isDisabledBeneficio: false
            })
        }
    }

    changeConsidera(e) {
        this.setState({
            isDisableDesconsiderarBeneficio: true
        })
        if (e.length <= 0) {
            this.setState({
                isDisableDesconsiderarBeneficio: false
            })
        }
    }

    render() {
        const { isDisabledBeneficio, isDisableDesconsiderarBeneficio, beneficiosConsiderar, beneficiosDesconsiderar } = this.state

        const beneficiosList = listBeneficios();
        return (
            <>
                <div style={{ width: "100%", marginRight: "10px" }}>
                    <label>Considerar</label>
                    <Select
                        classNames={"col-sm-6 col-md-6 col-xl-6"}
                        isMulti
                        isDisabled={isDisabledBeneficio}
                        options={beneficiosList}
                        value={beneficiosList.filter(option => beneficiosConsiderar.includes(option.value))}
                        onChange={(e) => { this.handleSelectChangeConsiderar(e) }}
                        isSearchable={true} // Habilita a pesquisa
                        placeholder="Considerar Benefícios" // Texto do placeholder
                        noOptionsMessage={() => 'Considerar Benefícios'} // Mensagem exibida quando não há opções encontradas
                    />

                    <label>Desconsiderar</label>
                    <Select
                        classNames={"col-sm-6 col-md-6 col-xl-6"}
                        isMulti
                        isDisabled={isDisableDesconsiderarBeneficio}
                        options={beneficiosList}
                        value={beneficiosList.filter(option => beneficiosDesconsiderar.includes(option.value))}
                        onChange={(e) => { this.handleSelectChangeDesconsiderar(e) }}
                        isSearchable={true} // Habilita a pesquisa
                        placeholder="Desconsiderar Benefícios" // Texto do placeholder
                        noOptionsMessage={() => 'Desconsiderar Benefícios'} // Mensagem exibida quando não há opções encontradas
                    />
                </div>
            </>
        )
    }
}

