import { Component } from "react";
import PageTitle from "../../../helpers/PageTitle";
import ReactApexChart from "react-apexcharts";
import { ApiRequest } from "../../../helpers/Api";
import MessageDisplay from "../../../messages/messageDisplay";
export default class ReportsAtendiment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            auth: localStorage.getItem('users'),
            chartData: this.Chart()
        }
    }


    async ReportsAtendiment() {
        const api = new ApiRequest();
        const msg = new MessageDisplay();

        const result = await api.get(`reports-atendiment`)
    }


    Chart() {
        let chartData = {
            series: [{
                name: "Desktops",
                data: [10, 41, 35, 51, 49, 62, 69, 91, 10]
            }],
            options: {
                chart: {
                    height: 350,
                    type: 'area',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                }
            },
        }

        return chartData
    }

    render() {
        const { auth, chartData } = this.state
        console.log(chartData)
        return (
            <>
                <div className="page-body">
                    <div className="container-fluid">
                        <PageTitle title={'Relatório de Empresas Renovação'} auth={auth} />
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <h5>Atendimentos</h5>
                                            </div>
                                            <div>
                                                {/* <ButtonSearch onFunction={this.getCompanies} type={"companies"} onResult={(e) => { this.dataResult(e) }} /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: '100% !important' }}>
                                        <ReactApexChart options={chartData.options} type={chartData.options.chart.type} series={chartData.series} height={chartData.options.chart.height} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div >
            </>
        )
    }
}