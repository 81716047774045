import { Component } from "react";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { formatBank, listBanks } from "../../../helpers/FuncHelpers";

export default class DadosBancarios extends Component {
    render() {
        const { dadosBancarios } = this.props
        const bankList = listBanks()
        const bankname = dadosBancarios?.banco_codigo && dadosBancarios?.banco_codigo !== null ? bankList.filter((e) => e.value == dadosBancarios.banco_codigo)[0] : null

        return (
            <>
                <div className="col-sm-6 col-xl-6">
                    <div className="card">
                        <div className="card-header">
                            <h5><FaMoneyCheckAlt /> Dados Bancários</h5>
                        </div>
                        <div className="card-body" style={{ padding: '5px 20px' }}>
                            <div className="d-flex justify-content-between align-items-center ficha_item">
                                <div className="ficha_item_title">
                                    <span>BANCO</span>
                                </div>
                                <div className="ficha_item_desc">
                                    <span>{bankname?.label}</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center ficha_item">
                                <div className="ficha_item_title">
                                    <span>AG. BANCO</span>
                                </div>
                                <div className="ficha_item_desc">
                                    <span>{dadosBancarios?.agencia_codigo}</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center ficha_item">
                                <div className="ficha_item_title">
                                    <span>CONTA</span>
                                </div>
                                <div className="ficha_item_desc">
                                    <span>{dadosBancarios?.banco_numero}</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center ficha_item">
                                <div className="ficha_item_title">
                                    <span>RECEBIMENTO</span>
                                </div>
                                <div className="ficha_item_desc">
                                    <span>{dadosBancarios?.banco_tipo}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}