import React, { useEffect, useState } from 'react';

const DashboardEmpresa = () => {

    return (
        <>
            EMPRESA
        </>
    )
}

export default DashboardEmpresa;