import { Component } from "react";
import PageTitle from "../../../helpers/PageTitle";
import { Form, Input, BtnForm } from "../../../helpers/formData";
import MessageDisplay from "../../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../../messages/load";
import Teams from "./teams";

export default class CreateTeams extends Component {
    constructor(props) {
        super(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
        }

        this.submitForm = this.submitForm.bind(this);
    }

    submitForm(e) {

        e.preventDefault();

        const form = e.target;

        const formData = new FormData(form);
        const serializedForm = Object.fromEntries(formData.entries());
        const msg = new MessageDisplay();
        startLoad();

        const tokenLocalStorage = localStorage.getItem("token");
        const tokenHash = localStorage.getItem("tokenHash");
        const login = localStorage.getItem("login");


        fetch(`${process.env.REACT_APP_HTTP}/team-create/${login}/${tokenHash}`, {
            method: 'POST',
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                authorization: `Bearer ${tokenLocalStorage}`
            },
            body: JSON.stringify({
                ...serializedForm
            })
        })
            .then((res) => res.json())
            .then(async (res) => {

                if (res.error) {
                    stopLoad();
                    return msg.error("Equipe", res.data);
                }

                stopLoad();
                this.setState({
                    newComponent: <Teams />
                })
                return msg.success("Equipe", 'Equipe cadastrada com sucesso');


            })
            .catch(() => {
                stopLoad();
                msg.error("Server", "Not connect");
            });

        e.preventDefault();

    }

    render() {
        const { newComponent } = this.state
        return (
            <>
                {newComponent ? newComponent : (
                    <div className="page-body">
                        <div className="container-fluid">
                            <PageTitle title={"Criar Equipe"} nameCompany={"Proconsig"} />
                        </div>
                        <div className="container-fluid">
                            <div className="edit-profile">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <Form title={'Nova Equipe'} onSubmit={(e) => this.submitForm(e)}>
                                            <Input
                                                classInput={"col-12"}
                                                type={'text'}
                                                name={'name'}
                                                label={'Nome'}
                                                onChange={(e) => this.setState({ name: e })}
                                                placeholder={"Nome da Equipe"}
                                            />
                                            <BtnForm name={'Cadastrar Equipe'} />
                                        </Form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}