import { Component, CSSProperties } from "react";
import PageTitle from "../../helpers/PageTitle";
import { Form, Input, SelectForm, BtnForm } from "../../helpers/formData";
import MessageDisplay from "../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../messages/load";
import ListUser from "./list";
import Select from 'react-select'
import { ApiRequest } from "../../helpers/Api";

export default class CreateUser extends Component {
    constructor(props) {
        super(props)
        const auth = localStorage.getItem('user')
        this.state = {
            auth: JSON.parse(auth),
            newComponent: '',
            selectTeam: false,
            selectMultipleTeam: false,
            teamsList: '',
            teams: []
        }
        this.getTeams = this.getTeams.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    handleSelectChange(selectedOptions) {
        console.log(selectedOptions)
        this.setState({
            teams: selectedOptions.map(option => option.value)
        });
    }

    componentDidMount() {
        this.getTeams();
    }

    async getTeams() {
        const api = new ApiRequest();
        const msg = new MessageDisplay();
        const result = await api.get(`teams-get-all`)
        if (result.error) {
            msg.error("Usuários", "Acesso Negado");
        }
        stopLoad();

        const teamsData = new Array();

        result.data.forEach((e) => {
            teamsData.push({
                value: e._id,
                label: e.name
            })
        });

        this.setState({
            teamsList: teamsData
        })

    }


    selectTeams(e) {
        const value = e.target.value
        this.setState({
            selectMultipleTeam: false,
            selectTeam: false,
            teams: []
        })


        if (value == 'operador') {
            this.setState({
                selectTeam: true
            })
        }

        if (value == 'supervisor') {
            this.setState({
                selectMultipleTeam: true,
            })
        }
    }

    handleImageChange(event) {
        console.log(event.target.files)

        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                const base64 = reader.result;
                this.setState({ thumb: base64 });
                // this.props.onChange(base64);
            };

            reader.readAsDataURL(file);
        } else {
            this.setState({ thumb: null });
            // this.props.onChange(null);
        }

        // this.setState({ logo: event.target.files })
    };


    submitForm(e) {
        e.preventDefault();
        const msg = new MessageDisplay();

        const form = e.target;

        const formData = new FormData(form);
        const serializedForm = Object.fromEntries(formData.entries());
        serializedForm.company_id = this.state.auth.company_id._id
        serializedForm.thumb = this.state.thumb
        serializedForm.teams = this.state.teams
        startLoad();

        const tokenLocalStorage = localStorage.getItem("token");
        const tokenHash = localStorage.getItem("tokenHash");
        const login = localStorage.getItem("login");

        fetch(`${process.env.REACT_APP_HTTP}/user-create/${login}/${tokenHash}`, {
            method: 'POST',
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                authorization: `Bearer ${tokenLocalStorage}`
            },
            body: JSON.stringify({
                ...serializedForm
            })
        })
            .then((res) => res.json())
            .then(async (res) => {

                if (res.error) {
                    stopLoad();
                    return msg.error("Usuário", res.data);
                }

                stopLoad();
                this.setState({
                    newComponent: <ListUser />
                })
                return msg.success("Usuário", 'Empresa cadastrada com sucesso');


            })
            .catch(() => {
                stopLoad();
                msg.error("Server", "Not connect");
            });

    }

    render() {
        const levelUser = [
            {
                value: 'master',
                label: 'MASTER',
                role: ['master']
            },
            {
                value: 'whitelabel',
                label: 'WHITE LABEL',
                role: ['whitelabel']
            },
            {
                value: 'revenda',
                label: 'REVENDA',
                role: ['revenda']
            },
            {
                value: 'empresa',
                label: 'EMPRESA',
                role: ['empresa']
            },
            {
                value: 'supervisor',
                label: 'SUPERVISOR',
                role: ['empresa']
            },
            {
                value: 'operador',
                label: 'OPERADOR',
                role: ['empresa', 'supervisor']
            }

        ]

        // const teams = new Array;
        // auth.team.forEach( => {

        // });
        const { newComponent, auth, selectTeam, selectMultipleTeam, teamsList, teams } = this.state

        return (

            <>
                {newComponent ? newComponent : (
                    <div className="page-body">
                        <div className="container-fluid">

                            <PageTitle title={"Cadastrar Usuário"} nameCompany={"Proconsig"} />
                        </div>
                        <div className="container-fluid">
                            <div className="edit-profile">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <Form title={'Criar Usuário'} onSubmit={(e) => this.submitForm(e)}>

                                            <Input
                                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                label={'Nome do Usuário'}
                                                type={'text'}
                                                name={'name'}
                                                placeholder={"Nome do Usuário"}
                                            />

                                            <Input
                                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                label={'E-mail'}
                                                type={'mail'}
                                                name={'email'}
                                                placeholder={"E-mail"}
                                            />

                                            <Input
                                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                label={'Login de Acesso'}
                                                type={'text'}
                                                name={'login'}
                                                placeholder={"Login"}
                                            />
                                            <Input
                                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                label={'Senha de Acesso'}
                                                type={'password'}
                                                name={'password'}
                                                placeholder={"Senha de Acesso"}
                                            />

                                            <Input
                                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                label={'Senha de Acesso'}
                                                type={'password'}
                                                name={'password_confirm'}
                                                placeholder={"Senha de Acesso"}
                                            />

                                            <SelectForm
                                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                                name={"level"}
                                                level={auth.level}
                                                onChange={(e) => { this.selectTeams(e) }}
                                                labelName={'Level Usuário'}
                                                data={levelUser}>
                                            </SelectForm>

                                            {selectTeam ? (
                                                <SelectForm
                                                    classInput={"col-12"}
                                                    name={"teamsUser"}
                                                    level={auth.level}
                                                    labelName={'Equipes'}
                                                    data={teamsList}>
                                                </SelectForm>

                                            ) :
                                                ''}
                                            {selectMultipleTeam ? (
                                                <div className="col-12">
                                                    <label className="form-label">Equipes</label>
                                                    <Select
                                                        formatGroupLabel={'Equipes'}
                                                        isMulti
                                                        options={teamsList}
                                                        value={teamsList.filter(option => teams.includes(option.value))}
                                                        onChange={this.handleSelectChange}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                    />
                                                </div>

                                            ) :
                                                ''}

                                            <BtnForm name={'Cadastrar Usuário'} />
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}