import { Component } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ApiRequest } from "../../../helpers/Api";
import { BadgeTypeMailing } from '../generic_components/badge-type-mailing';
import { BadgeConvenioMailing } from '../generic_components/badge-convenio-mailing';


window.portList = [];
export default class FilterMailing extends Component {
    constructor(props) {
        super(props)
        const auth = window.localStorage.getItem("user")
        this.state = {
            auth: JSON.parse(auth),
            idMailing: props.idMailing,
            typeMailing: props.typeMailing,
            loading: false,
            mailingData: false,
        }
    }

    componentDidMount() {
        this.getMailingById();
    }

    async getMailingById() {
        const { idMailing, typeMailing } = this.state;
        this.setState({ loading: true });

        const api = new ApiRequest();
        const result = await api.getById('get-model-mailing-by-id', idMailing, typeMailing);

        setTimeout(() => {
            this.setState({ mailingData: result.data, loading: false });
        }, 1500);
    }

    handleObjectFilterValue = (value) => {
        console.log(value)
        let valueReturn = 'invalid';

        const propertiesHiddenArray = ['_id', 'estados', 'port', 'mailings', 'type_mailing', 'teams_id', 'name', 'type', 'created_at', 'updated_at', 'total_worked', '__v']

        if (propertiesHiddenArray.includes(value[0]))
            return valueReturn

        const indicatorHandle = value[0].replace(/_/g, ' ');
        if (Array.isArray(value[1]) === false && typeof value[1] === 'object')
            valueReturn = [indicatorHandle, value[1].$numberDecimal === '999999' ? 'Máximo' : value[1].$numberDecimal];
        else if (Array.isArray(value[1]) === false)
            valueReturn = [indicatorHandle, value[1] === '' ? 'Nenhum' : value[1]];
        else
            valueReturn = [indicatorHandle, value[1].length === 0 ? 'Nenhum' : value[1].join(', ')];

        return valueReturn
    }

    render() {
        const { auth, loading, idMailing, mailingData } = this.state
        const filterMailingArr = Object.entries(mailingData)

        return (
            <>
                <div className="page-body">
                    {loading && !mailingData
                        ? <div>
                            <div className="row">
                                <div className="col-6">
                                    <Skeleton height={23} />
                                    <Skeleton height={18} width={250} />
                                </div>
                                <div className="col-6 align-items-start justify-content-end d-flex">
                                    <Skeleton height={28} width={70} style={{ marginRight: '5px' }} />
                                    <Skeleton height={28} width={100} />
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-5">
                                    <Skeleton count={11} width="100%" height={16} />
                                </div>
                                <div className="col-5 offset-2">
                                    <Skeleton count={11} width="100%" height={16} />
                                </div>
                            </div>
                            <Skeleton height={100} className="mt-1" />
                        </div>
                        : <div>

                            <div className="text-uppercase fs-5 float-end">
                                <BadgeConvenioMailing convenioMailing={mailingData.type_mailing} />
                                <BadgeTypeMailing typeMailing={mailingData.type} />
                            </div>
                            <h5 className="text-uppercase mb-0">
                                {mailingData.name}
                            </h5>
                            <div className="fs-6 mb-3">
                                <i className="fa fa-map-marker"></i>
                                <span> {mailingData.estados ?? 'Brasil'} </span>
                            </div>

                            <div className="row mt-2">
                                <div className="col-5">
                                    {
                                        filterMailingArr && filterMailingArr.map((objectValue, index) => {
                                            if (index < 18) {
                                                const objectFilterHandle = this.handleObjectFilterValue(objectValue);
                                                return (
                                                    <div>
                                                        {objectFilterHandle !== 'invalid' ?
                                                            <>
                                                                <span className="fw-bold text-capitalize">{objectFilterHandle[0]}: </span>
                                                                <span> {objectFilterHandle[1]} </span>
                                                            </>
                                                            : ''}
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                                <div className="col-5 offset-2">
                                    {
                                        filterMailingArr && filterMailingArr.map((objectValue, index) => {
                                            if (index >= 17) {
                                                const objectFilterHandle = this.handleObjectFilterValue(objectValue);
                                                return (
                                                    <div>
                                                        {objectFilterHandle !== 'invalid' ?
                                                            <>
                                                                <span className="fw-bold text-capitalize">{objectFilterHandle[0]}: </span>
                                                                <span> {objectFilterHandle[1]} </span>
                                                            </>
                                                            : ''}
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>

                            {/* portabilidade */}
                            {mailingData && mailingData?.port && mailingData?.port.length === 0
                                ? <> <span className="fw-bold">Portabilidade: </span> Nenhum filtro de portabilidade </>
                                : mailingData && mailingData?.port && mailingData.port.map((portabilidade, index) => {
                                    return (
                                        <>
                                            <hr />
                                            <div className="mt-1">
                                                <div className="fw-bold"><i className="fa fa-university"></i> Portabilidade #{index + 1} </div>
                                                <div className="fw-bold">Banco: {portabilidade.bank}</div>
                                                <div> <span className="fw-bold">Plano: </span> {portabilidade.plano_de} a {portabilidade.plano_ate}</div>
                                                <div><span className="fw-bold">Parcelas: </span> {portabilidade.parcelas_de} a {portabilidade.parcelas_ate}</div>
                                                <div><span className="fw-bold">Valor parcela: </span> {portabilidade.valor_parcela_de.$numberDecimal} a {portabilidade.valor_parcela_ate.$numberDecimal}</div>
                                                <div><span className="fw-bold">Taxa: </span> {portabilidade.taxa_de.$numberDecimal} a {portabilidade.taxa_ate.$numberDecimal}</div>
                                                <div><span className="fw-bold">Saldo devedor: </span> {portabilidade.saldo_devedor_de.$numberDecimal} a {portabilidade.saldo_devedor_ate.$numberDecimal}</div>
                                            </div>
                                        </>
                                    )
                                })

                            }
                        </div>
                    }
                </div>
            </>
        );
    }
}


