import React, { Component } from "react";
import NoAvatar from '../../../assets/images/proconsig/no_avatar.jpg';
import sessionClient from "../../helpers/session";
import { startLoad, stopLoad } from "../../messages/load";
import PageTitle from "../../helpers/PageTitle";
import { SpinnerRoundOutlined } from 'spinners-react';
import Atendiment from "./atendiment";
import { ApiRequest } from "../../helpers/Api";
import { formatarCPF, formatarTelefone, formatDate, calcularIdade } from "../../helpers/FuncHelpers";
import sweetAlert from "../../helpers/sweet";
import Modal from 'react-bootstrap/Modal';
import MessageDisplay from "../../messages/messageDisplay";
import logOut from "../../auth/logout";

export default class ClientCallCenter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            client: '',
            newComponemt: '',
            dadosFicha: '',
            data: '',
            phoneAtendiment: '',
            show: false,
        }
        this.isHeroEqual = {};
        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    handleClose() {
        this.setState({
            show: false
        })
    }

    handleShow(e) {
        this.setState({
            show: true,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.statusWebsocketConnect) {
            this.conectSocktAtendiment()
        }
    }

    async componentDidMount() {
        this.conectSocktAtendiment()
    }

    async conectSocktAtendiment() {
        const token = window.localStorage.getItem('tokenHash');
        const session = await sessionClient.getSession(token);
        const sweet = new sweetAlert();
        if (session) {
            session.onSocket.onAtendiment(false, (data) => {
                if (!Object.is(this.isHeroEqual, data[0].phoneAtendiment)) {
                    this.isHeroEqual = data[0].phoneAtendimen;
                    if (data[0].error && data[0].block) {
                        sweet.warning('Identificamos comportamento suspeito na utilização do sistema portanto sua Empresa foi bloqueada entre em contato com o Administrador', 6000, true)
                        setTimeout(() => {
                            logOut();
                        }, 6000)
                    }

                    if (data[0].error && !data[0].block) {
                        sweet.info(`Desculpe mais não foi possível localizar o cliente verifique com seu Supervisor`)
                    }

                    if (data && data[0]?.client) {
                        startLoad()
                        setTimeout(() => {
                            this.setState({
                                newComponemt: true,
                                data: data[0].client[0],
                                client: data[0].client[0].pessoa[0],
                                phoneAtendiment: data[0].phoneAtendiment,
                                dadosFicha: false
                            })
                            stopLoad();
                        }, 2000)
                    }
                }
            });
        }
    }


    async clientLocalization(e) {
        const value = e.target.closest('.btn')
        const { phoneAtendiment, data } = this.state
        const client = JSON.parse(value.id)
        const msg = new MessageDisplay();
        const postData = {
            name: client.nome,
            document: client.doc,
            phone: phoneAtendiment,
            id_client: client._id
        }

        startLoad();
        const api = new ApiRequest();
        const result = await api.post(`atendiment-create`, postData, false)
        if (result.error === true && result) {
            msg.error('Atendimento', result.data)
            return;
        }

        setTimeout(() => {
            msg.success('Atendimento', 'Cliente localizado com sucesso')
            this.setState({
                show: false,
                dadosFicha: <Atendiment data={data} />
            }, () => {
                setTimeout(() => {
                    stopLoad();
                }, 500)
            })

        }, 1000)

    }

    render() {
        const { newComponemt, client, dadosFicha, phoneAtendiment } = this.state

        return (
            <>
                {newComponemt ? (
                    <>
                        {dadosFicha ? dadosFicha
                            : (<>
                                <div className="page-body">
                                    <div className="container-fluid">
                                        <PageTitle title={`Módulo Call Center`} />
                                    </div>
                                    <div className="container-fluid">
                                        <div className="user-profile">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="card hovercard text-center">
                                                        <div className="cardheader">
                                                        </div>
                                                        <div className="user-image">
                                                            <div className="avatar"><img alt="" src={NoAvatar} /></div>
                                                        </div>
                                                        <div className="info">
                                                            <div className="row">
                                                                <div className="col-sm-6 col-lg-4 order-sm-1 order-xl-0">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="ttl-info text-start">
                                                                                <h6><i className="fa fa-user"></i>&nbsp;&nbsp;&nbsp;IDADE</h6><span>{calcularIdade(formatDate(client.dataNascimento))}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="ttl-info text-start">
                                                                                <h6><i className="fa fa-calendar"></i>&nbsp;&nbsp;&nbsp;NASCIMENTO</h6><span>{formatDate(client.dataNascimento)}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12 col-lg-4 order-sm-0 order-xl-1">
                                                                    <div className="user-designation">
                                                                        <div className="title">
                                                                            <span>{client.nome}</span>
                                                                        </div>
                                                                        <div className="desc">{formatarCPF(client.doc)}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-lg-4 order-sm-2 order-xl-2">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="ttl-info text-start">
                                                                                <h6><i className="fa fa-phone"></i>&nbsp;&nbsp;&nbsp;Tel. Atendimento</h6><span>{formatarTelefone(phoneAtendiment)}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="ttl-info text-start">
                                                                                <h6><i className="fa fa-bolt"></i>&nbsp;&nbsp;&nbsp;Sexo</h6><span>{client?.sexo ? client.sexo : 'Não Informado'}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="follow" style={{ padding: "20px" }}>
                                                                <div className="row">
                                                                    <div className="col-6 text-md-end border-right">
                                                                        <div className="follow-num counter">

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 text-md-end">
                                                                        <div className="follow-num counter">
                                                                            <button onClick={this.handleShow} className="btn btn-pill btn-success btn-lg" type="button" title="" data-bs-original-title="btn btn-pill btn-success btn-lg" data-original-title="btn btn-pill btn-success btn-lg">
                                                                                <i data-feather="check-circle"></i>
                                                                                Cliente Localizado</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>)}
                    </>
                ) : (

                    <div className="page-body">
                        <div className="container-fluid">
                            <PageTitle title={`Módulo Call Center`} />
                        </div>
                        <div className="container-fluid">
                            <div className="edit-profile">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card" style={{ textAlign: 'center' }}>
                                            <div className="spinner-container">
                                                <SpinnerRoundOutlined
                                                    style={{ textAlign: 'center' }}
                                                />
                                                <p>Arguardando novo cliente .........</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )}

                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    size="lg"
                    style={{ zIndex: '9998' }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{'Cliente Localizado'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Você tem certeza que esse telefone <b>*** PERTENCE ***</b> a <b>{client.nome}</b></p>
                        <p>Lembre-se que essa ação nos ajuda a entregar um melhor telefone do cliente. se você tem certeza clique em sim!</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button id={JSON.stringify(client)} onClick={(e) => { this.clientLocalization(e) }} className="btn btn-success"><i className="fa fa-location-arrow"></i> Sim</button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}


