"use strict";
exports.__esModule = true;
exports.DialerEnum = void 0;
var DialerEnum;
(function (DialerEnum) {
    DialerEnum["Softcall"] = "softcall";
    DialerEnum["Ipbox"] = "ipbox";
    DialerEnum["MaisVoip"] = "mais_voip";
    DialerEnum["Argus"] = "argus";
    DialerEnum["Disc3cPlus"] = "3c_plus";
    DialerEnum["FastTelecom"] = "fast_telecom";
    DialerEnum["Callix"] = "callix";
})(DialerEnum = exports.DialerEnum || (exports.DialerEnum = {}));
