import { Component } from "react";
import Margem from "./margem";
import BeneficioInss from "./beneficio";
import DadosBancarios from "./dados_bancarios";
import RubricasInss from "./rubricas";
import CartaoRMC from "./cartaormc";
import CartaoRCC from "./cartaorcc";
import sweetAlert from "../../../helpers/sweet";

class MyDocument extends Component {
    render() {
        return (
            <>
            </>
        )
    }
}




export default class BeneficioListInss extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }


    async pdfCreator(e) {
        const sweet = new sweetAlert();
        return sweet.info('Esse módulo está sendo implementado e você poderá utlizado em breve', 3000, true)
    }

    render() {
        const { beneficios, emprestimos, cartaoRmc, cartaoRcc, dadosBancarios } = this.props
        return (
            <>
                <div className="tab-content" style={{ padding: '0px' }} id="top-tabContent">
                    <div
                        className="search-links tab-pane fade show"
                        id="data-inss"
                        role="tabpanel"
                        aria-labelledby="datainss"
                    >
                        {beneficios ? beneficios.map((b, index) => {
                            let count = index + 1
                            const emp = emprestimos !== null ? emprestimos.filter((e) => e.beneficio === b.beneficio) : null
                            const cartRmc = cartaoRmc !== null ? cartaoRmc.filter((cart) => cart.beneficio === b.beneficio) : null
                            const cartRcc = cartaoRcc !== null ? cartaoRcc.filter((cart) => cart.beneficio === b.beneficio) : null
                            const banco = dadosBancarios !== null ? (dadosBancarios.filter((dadosB) => dadosB.beneficio === b.beneficio))[0] : null
                            return (
                                <>

                                    <div className="list_beneficio" >
                                        <h1 className="list_ben_title">- BENEFÍCIO #{count} - <button id={JSON.stringify(b)} onClick={(e) => this.pdfCreator(e)} className="btn btn-sm btn-danger">PDF</button></h1>
                                        {<Margem beneficio={b} emprestimos={emp} />}

                                        <div className="row">
                                            {<BeneficioInss beneficio={b} />}
                                            {<DadosBancarios dadosBancarios={banco} />}
                                        </div>
                                        <div className="d-flex">
                                            {<RubricasInss emprestimos={emp} />}
                                        </div>
                                        <div className="d-flex">
                                            {<CartaoRMC cartaoRmc={cartRmc} />}
                                        </div>
                                        <div className="d-flex">
                                            {<CartaoRCC cartaoRcc={cartRcc} />}
                                        </div>
                                    </div>


                                </>
                            )

                        }) : <p style={{ padding: "15px" }}>Esse cliente não possui benefícios INSS em nossa base de dados</p>}
                    </div >
                </div >
            </>
        )
    }
}