import moment from "moment/moment";
import formatNumber from 'format-number';
export function getTypeColorMailing(value) {
    let color = {
        'cartao': 'danger',
        'margem': 'success',
        'portabilidade': 'primary',
        'todos': 'Todos',
        'entrantes': 'Entrantes',
        'cartao_beneficio': 'Cartão Benefício',
        'aumento_margem': 'Aumento de Margem',
        // 'transmitido' => 'FGTS',
    };
    return color[value];
}

export function getStatusMailing(value) {
    let color = {
        'criado': 'success',
        'abastecendo': 'warning',
        'abastecido': 'info',
        'error': 'danger',
    };
    return color[value];
}


export function getTypeColorConvenio(value) {
    let color = {
        'inss': 'danger',
        'fgts': 'success',
        'siape': 'primary',
        'gov': 'Todos',
    };
    return color[value];
}
export function formatarValorEmDinheiro(valor) {
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });

    const valorEmReais = formatter.format(valor);

    return valorEmReais
}

export function typeConvenio(value) {
    return value.mailings.hasOwnProperty('inss') ? 'inss' :
        value.mailings.hasOwnProperty('fgts') ? 'fgts' :
            value.mailings.hasOwnProperty('siape') ? 'siape' :
                value.mailings.hasOwnProperty('gov') ? 'gov' :
                    null
}

export function formatarCPF(cpf) {
    const digitsOnly = cpf.replace(/\D/g, '');
    return digitsOnly.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

export function formatBank(bank) {
    return (`000${bank}`).slice(-3).trim();
}

export function formatarBeneficio(beneficio) {
    const digitsOnly = beneficio.replace(/\D/g, '');
    return digitsOnly.replace(/(\d{3})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
}

export function formatarCEP(cep) {
    if (!!cep && cep !== null) {
        const digitsOnly = cep.replace(/\D/g, '');
        return digitsOnly.replace(/(\d{5})(\d{3})/, '$1-$2');
    }
    return 0
}

export function calcularIdade(dataNascimento) {
    if (!!dataNascimento && dataNascimento != null) {
        const partesData = dataNascimento.split('/');
        const diaNasc = parseInt(partesData[0], 10);
        const mesNasc = parseInt(partesData[1], 10) - 1;
        const anoNasc = parseInt(partesData[2], 10);

        const hoje = new Date();
        const dataNasc = new Date(anoNasc, mesNasc, diaNasc);

        let idade = hoje.getFullYear() - dataNasc.getFullYear();
        const mesAtual = hoje.getMonth();
        const diaAtual = hoje.getDate();

        if (
            mesAtual < mesNasc ||
            (mesAtual === mesNasc && diaAtual < diaNasc)
        ) {
            idade--;
        }

        return idade;
    }
    return null
}

export function formatarTelefone(telefone) {
    const numeroLimpo = telefone.replace(/\D/g, '');

    if (numeroLimpo.length === 11) {
        const ddd = numeroLimpo.slice(0, 2);
        const parte1 = numeroLimpo.slice(2, 3);
        const parte2 = numeroLimpo.slice(3, 7);
        const parte3 = numeroLimpo.slice(7, 11);

        return `(${ddd}) ${parte1}-${parte2}-${parte3}`;
    } else {
        return telefone;
    }
}


export function formatDate(value) {
    if (typeof (value) === 'string') value = new Date(value)
    // const date = moment(value).format('DD/MM/YYYY')
    const DATE_FORMATS = ['YYYY-MM-DD HH:mm:ss', 'YYYYMMDD', 'YYYY-MM-DD', 'YYYYMM', 'YYYY-MM', 'DDMMYYYY', 'DD/MM/YYYY', 'MMYYYY', 'MM/YYYY', 'YYYY-MM-DDT00:00:00.000Z', 'YYYY-MM-DD'];
    const date = moment.utc(value, DATE_FORMATS, true);
    if (date.isValid()) return date.startOf('day').format('DD/MM/YYYY');
    return null;
}


export function listBeneficios() {
    let beneficios = [
        { value: '08', label: '08 - Aposentadoria por idade do trabalhador rural' },
        { value: '07', label: '07 - Aposentadoria por idade do empregador rural' },
        { value: '41', label: '41 - Aposentadoria por idade' },
        { value: '52', label: '52 - Aposentadoria por idade (Extinto Plano Básico)' },
        { value: '81', label: '81 - Aposentadoria por idade compulsória' },
        { value: '78', label: '78 - Aposentadoria por idade de ex-combatente marítimo' },
        { value: '04', label: '04 - Aposentadoria por invalidez do trabalhador rural' },
        { value: '06', label: '06 - Aposentadoria por invalidez do empregador rural' },
        { value: '32', label: '32 - Aposentadoria por invalidez previdenciária' },
        { value: '33', label: '33 - Aposentadoria por invalidez de aeronauta' },
        { value: '34', label: '34 - Aposentadoria por invalidez de ex-combatente marítimo' },
        { value: '51', label: '51 - Aposentadoria por invalidez (Extinto Plano Básico)' },
        { value: '83', label: '83 - Aposentadoria por invalidez (Ex-SASSE)' },
        { value: '42', label: '42 - Aposentadoria por tempo de contribuição previdenciária' },
        { value: '43', label: '43 - Aposentadoria por tempo de contribuição de ex-combatente' },
        { value: '44', label: '44 - Aposentadoria por tempo de contribuição de aeronauta' },
        { value: '46', label: '46 - Aposentadoria por tempo de contribuição especial' },
        { value: '45', label: '45 - Aposentadoria por tempo de contribuição de jornalista profissional' },
        { value: '49', label: '49 - Aposentadoria por tempo de contribuição ordinária' },
        { value: '57', label: '57 - Aposentadoria por tempo de contribuição de professor' },
        { value: '72', label: '72 - Apos. por tempo de contribuição de ex-combatente marítimo' },
        { value: '82', label: '82 - Aposentadoria por tempo de contribuição' },
        { value: '01', label: '01 - Pensão por morte do trabalhador rural' },
        { value: '03', label: '03 - Pensão por morte do empregador rura' },
        { value: '21', label: '21 - Pensão por morte previdenciária' },
        { value: '23', label: '23 - Pensão por morte de ex-combatente' },
        { value: '27', label: '27 - Pensão por morte de servidor público federal com dupla aposentadoria' },
        { value: '28', label: '28 - Pensão por morte do Regime Geral (Decreto nº 20.465/31)' },
        { value: '29', label: '29 - Pensão por morte de ex-combatente marítimo' },
        { value: '55', label: '55 - Pensão por morte (Extinto Plano Básico)' },
        { value: '84', label: '84 - Pensão por morte (Ex-SASSE)' },
        { value: '13', label: '13 - Auxílio-doença do trabalhador rural' },
        { value: '15', label: '15 - Auxílio-reclusão do trabalhador rural' },
        { value: '25', label: '25 - Auxílio-reclusão' },
        { value: '31', label: '31 - Auxílio-doença previdenciário' },
        { value: '36', label: '36 - Auxílio Acidente' },
        { value: '50', label: '50 - Auxílio-doença  (Extinto Plano Básico)' },
        { value: '02', label: '02 - Pensão por morte por acidente do trabalho do trabalhador rural' },
        { value: '05', label: '05 - Aposentadoria por invalidez por acidente do trabalho do trabalhador Rural' },
        { value: '10', label: '10 - Auxílio-doença por acidente do trabalho do trabalhador rural' },
        { value: '91', label: '91 - Auxílio-doença por acidente do trabalho' },
        { value: '92', label: '92 - Aposentadoria por invalidez por acidente do trabalho' },
        { value: '93', label: '93 - Pensão por morte por acidente do trabalho' },
        { value: '94', label: '94 - Auxílio-acidente por acidente do trabalho' },
        { value: '95', label: '95 - Auxílio-suplementar por acidente do trabalho' },
        { value: '12', label: '12 - Renda mensal vitalícia por idade do trabalhador rural (Lei nº 6.179/74)' },
        { value: '11', label: '11 - Renda mensal vitalícia por invalidez do trabalhador rural (Lei nº 6.179/74)' },
        { value: '30', label: '30 - Renda mensal vitalícia por invalidez (Lei nº 6179/74)' },
        { value: '40', label: '40 - Renda mensal vitalícia por idade (Lei nº 6.179/74)' },
        { value: '85', label: '85 - Pensão mensal vitalícia do seringueiro (Lei nº 7.986/89)' },
        { value: '86', label: '86 - Pensão mensal vitalícia do dep.do seringueiro (Lei nº 7.986/89)' },
        { value: '87', label: '87 - Amparo assistencial ao portador de deficiência (LOAS)' },
        { value: '88', label: '88 - Amparo assistencial ao idoso (LOAS)' },
        { value: '47', label: '47 - Abono de permanência em serviço 25%' },
        { value: '79', label: '79 - Abono de servidor aposentado pela autarquia empr.(Lei 1.756/52)' },
        { value: '48', label: '48 - Abono de permanência em serviço 20%' },
        { value: '68', label: '68 - Pecúlio especial de aposentadoria' },
        { value: '80', label: '80 - Salário-maternidade' },
        { value: '22', label: '22 - Pensão por morte estatutária' },
        { value: '26', label: '26 - Pensão Especial (Lei nº 593/48)' },
        { value: '37', label: '37 - Aposentadoria de extranumerário da União' },
        { value: '38', label: '38 - Aposentadoria da extinta CAPIN' },
        { value: '54', label: '54 - Pensão especial vitalícia (Lei nº 9.793/99)' },
        { value: '56', label: '56 - Pensão mensal vitalícia por síndrome de talidomida (Lei nº 7.070/82)' },
        { value: '58', label: '58 - Aposentadoria excepcional do anistiado (Lei nº 6.683/79)' },
        { value: '59', label: '59 - Pensão por morte excepcional do anistiado (Lei nº 6.683/79)' },
        { value: '60', label: '60 - Pensão especial mensal vitalícia (Lei 10.923, de 24/07/2004)' },
        { value: '76', label: '76 - Salário-família estatutário da RFFSA (Decreto-lei nº 956/69)' },
        { value: '89', label: '89 - Pensão especial aos depedentes de vítimas fatais p/ contaminação na hemodiálise' },
        { value: '96', label: '96 - Pensão especial às pessoas atingidas pela hanseníase (Lei nº 11.520/2007)' },
        { value: '18', label: '18 - Auxilio Inclusão' },
    ]

    return beneficios

}

export function listBanks() {
    let banks = [
        { value: '001', label: '001 - Banco do Brasil S.A.' },
        { value: '033', label: '033 - Banco Santander' },
        { value: '341', label: '341 - Itaú Unibanco S.A.' },
        { value: '652', label: '652 - Itaú Unibanco Holdin' },
        { value: '389', label: '389 - Banco Mercantil do B' },
        { value: '359', label: '359 - Zema Crédito, Financiamento e Investimento S/A' },
        { value: '104', label: '104 - Caixa Econômica Fede' },
        { value: '399', label: '399 - HSBC Bank Brasil S. ' },
        { value: '453', label: '453 - Banco Rural S.A.' },
        { value: '633', label: '633 - Banco Rendimentos S.A' },
        { value: '246', label: '246 - Banco ACB Brasil S.A' },
        { value: '641', label: '641 - Banco Alvorada S.A.' },
        { value: '025', label: '025 - Banco Alfa S.A.' },
        { value: '029', label: '029 - Banco Itaú Consig' },
        { value: '756', label: '756 - Banco Cooperativo do' },
        { value: '000', label: '000 - Banco Bankpar' },
        { value: '038', label: '038 - Banco Banestado S.A.' },
        { value: '041', label: '041 - Banco do Estado do R' },
        { value: '748', label: '748 - Banco Cooperativo Si' },
        { value: '003', label: '003 - BANCO Da Amazônia S.' },
        { value: '004', label: '004 - Banco do Nordeste do' },
        { value: '021', label: '021 - Banestes S.A. Banco ' },
        { value: '047', label: '047 - Banco do Estado de S' },
        { value: '074', label: '074 - Banco J. Safra S.A.' },
        { value: '063', label: '063 - Banco Ibi S.A. Banco' },
        { value: '070', label: '070 - BRB – Banco de Brasí' },
        { value: '077', label: '077 - Banco Intermedium S.' },
        { value: '121', label: '121 - Banco Agibank S.A.' },
        { value: '213', label: '213 - Banco Arbi S.A.' },
        { value: '218', label: '218 - Banco Bonsucesso S.A' },
        { value: '233', label: '233 - Banco Cifra S.A.' },
        { value: '250', label: '250 - BCV - Banco de Crédi' },
        { value: '254', label: '254 - Parana Banco S. A.' },
        { value: '320', label: '320 - Banco Industrial e C' },
        { value: '318', label: '318 - Banco BMG S.A.' },
        { value: '263', label: '263 - Banco Cacique S. A.' },
        { value: '394', label: '394 - Banco Bradesco Finan' },
        { value: '422', label: '422 - BANCO Safra S.A.' },
        { value: '604', label: '604 - Banco Industrial do ' },
        { value: '623', label: '623 - Banco Panamericano S' },
        { value: '655', label: '655 - Banco Votorantim S.A' },
        { value: '654', label: '654 - Banco A.J. Renner S.' },
        { value: '707', label: '707 - BANCO Daycoval S.A.' },
        { value: '901', label: '901 - Corretora Souza Barr' },
        { value: '743', label: '743 - Banco Semear S.A.' },
        { value: '739', label: '739 - Banco Cetelem' },
        { value: '002', label: '002 - Banco de Teste' },
        { value: '212', label: '212 - Banco Original' },
        { value: '336', label: '336 - C6 Bank' },
        { value: '626', label: '626 - C6 Consignados' },
        { value: '955', label: '955 - Ole Bonsucesso Consi' },
        { value: '935', label: '935 - Facta Financeira S/A' },
        { value: '905', label: '905 - Financeira Alfa S.A.' },
        { value: '934', label: '934 - Agiplan Financeira S' },
        { value: '753', label: '753 - Nubank' },
        { value: '921', label: '921 - Credifar S/A' },
        { value: '069', label: '069 - BPN Brasil (Crefisa)' },
        { value: '237', label: '237 - Bradesco' },

    ]
    return banks
}


export function listUfs() {
    let ufs = [
        { value: 'AC', label: 'AC' },
        { value: 'AL', label: 'AL' },
        { value: 'AM', label: 'AM' },
        { value: 'AP', label: 'AP' },
        { value: 'BA', label: 'BA' },
        { value: 'CE', label: 'CE' },
        { value: 'DF', label: 'DF' },
        { value: 'ES', label: 'ES' },
        { value: 'GO', label: 'GO' },
        { value: 'MA', label: 'MA' },
        { value: 'MG', label: 'MG' },
        { value: 'MS', label: 'MS' },
        { value: 'MT', label: 'MT' },
        { value: 'PA', label: 'PA' },
        { value: 'PI', label: 'PI' },
        { value: 'PB', label: 'PB' },
        { value: 'PE', label: 'PE' },
        { value: 'PR', label: 'PR' },
        { value: 'RN', label: 'RN' },
        { value: 'RJ', label: 'RJ' },
        { value: 'RO', label: 'RO' },
        { value: 'RR', label: 'RR' },
        { value: 'RS', label: 'RS' },
        { value: 'SC', label: 'SC' },
        { value: 'SE', label: 'SE' },
        { value: 'TO', label: 'TO' },
        { value: 'SP', label: 'SP' },
    ]

    return ufs

}


export function formatarDataHora(dataHora) {

    const data = new Date(dataHora);
    const ano = data.getUTCFullYear();

    const mes = ("0" + (data.getUTCMonth() + 1)).slice(-2);
    const dia = ("0" + data.getUTCDate()).slice(-2);

    const horas = ("0" + data.getUTCHours()).slice(-2);
    const minutos = ("0" + data.getUTCMinutes()).slice(-2);
    const segundos = ("0" + data.getUTCSeconds()).slice(-2);
    const milissegundos = ("00" + data.getUTCMilliseconds()).slice(-3);

    return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
}