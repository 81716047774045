import React, { Component } from "react";
import DataClient from "./data_client/client";
import DadosCadstrais from "./data_client/dados_cadastrais";
import Address from "./data_client/address";
import Phones from "./data_client/phones";

import Fgts from "./data_fgts/fgts";
import BeneficioFGTS from "./data_fgts/benefico";
import EmpresaFGTS from "./data_fgts/empresa";

import BeneficioSIAPE from "./data_siape/beneficio";
import DadosBancariosSIAPE from "./data_siape/dados_bancarios";
import MargemSIAPE from "./data_siape/margem";
import RubricasSIAPE from "./data_siape/rubricas";
import CartaoRMCSIAPE from "./data_siape/cartaormc";
import CartaoRCCSIAPE from "./data_siape/cartaorcc";
import PageTitle from "../../helpers/PageTitle";

import { Tab, UlTab, LiTab, DivTab } from "../../helpers/Tab";
import BeneficioListInss from "./data_inss/data_beneficio";
import { ApiRequest } from "../../helpers/Api";
import MessageDisplay from "../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../messages/load";
import { Modal } from "react-bootstrap";
import Search from "../search/search";
import SegurosComponente from "./data_seguros/seguros";

export default class Atendiment extends Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      auth: JSON.parse(localStorage.getItem('user')),
      newComponent: null,
      showModal: false,
      values: true,
      fullscreen: true,
      show: false,
      typeFicha: false,
      client: data.pessoa && data.pessoa[0] ? data.pessoa[0] : null,
      dadosBancarios: data.dados_bancarios && data.dados_bancarios[0] ? data.dados_bancarios : null,
      address:
        data.enderecos && data.enderecos.length > 0
          ? data.enderecos
          : null,
      phones:
        data.contatos && data.contatos.length > 0
          ? data.contatos
          : null,
      representanteLegalContato:
        data?.representanteLegalContato && data?.representanteLegalContato[0] && data?.representanteLegalContato[0]?.contatos
          ? data?.representanteLegalContato[0]?.contatos
          : null,
      inss:
        data.inss && data.inss.length > 0
          ? data.inss
          : null,
      emprestimos: data.emprestimos && data.emprestimos.length > 0
        ? data.emprestimos
        : null,
      cartaoRmc: data.cartaoRmc && data.cartaoRmc.length > 0
        ? data.cartaoRmc
        : null,
      cartaoRcc: data.cartaoRcc && data.cartaoRcc.length > 0
        ? data.cartaoRcc
        : null,
      fgts: "",
      siape: "",
      listTab: [
        {
          name: "cliente",
          active: true,
          id: "dataclient",
          href: "data-client",
          view: true
        },
        { name: "inss", active: false, id: "datainss", href: "data-inss", view: false },
        { name: "seguros", active: false, id: "dataseguros", href: "data-seguros", view: false },
        // { name: 'fgts', active: false, id: 'datafgts', href: 'data-fgts' },
        // { name: 'siape', active: false, id: 'datasiape', href: 'data-siape' },
      ],
      inssView: false,
      seguroView: false,


    };
    this.atualizacaoBeneficios = this.atualizacaoBeneficios.bind(this);
  }

  componentDidMount() {
    const { auth, listTab } = this.state
    const list = listTab.map((e) => console.log(e))
    const convenios = auth.company_id.convenios.map((e) => e.value)
    const find = listTab.filter((e) => {
      this.setState({
        listTab: listTab.map(item => ({
          ...item,
          view: convenios.includes(item.name) ? true : item.view
        }))
      });
    })


  }

  handleShow = (breakpoint) => {
    this.setState({ fullscreen: breakpoint, show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  getInfoType(e) {
    this.setState({
      typeFicha: e,
    });
  }

  handleModalOpen = () => {
    this.setState({
      showModal: true,
    });
  };

  handleModalClose = () => {
    this.setState({
      showModal: false,
    });
  };

  async atualizacaoBeneficios(e) {
    const value = e.target.closest('.btn')

    const type = value.getAttribute("data-type");
    const client = JSON.parse(value.getAttribute("data-client"));
    const api = new ApiRequest();
    const msg = new MessageDisplay();
    const { listTab } = this.state;

    const body = {
      type,
      client,
    };
    startLoad();
    const result = await api.post(`atendiment-update`, body, false);
    if (result.error === true && result) {
      msg.error("Atualização", result.data);
      return;
    }
    stopLoad();

    for (const li of listTab) {
      if (li.name == "inss") {
        li.active = true;
      }
    }

    this.setState({
      inss: result.data.inss,
      dadosBancarios: result.data.dados_bancarios,
      emprestimos: result.data.emprestimos,
      cartaoRmc: result.data.cartaoRmc,
      cartaoRcc: result.data.cartaoRcc,
      listTab: listTab,
    });
    msg.success("Atualização", "Hiscon Off atualizado com sucesso");
  }

  async atendimentManulClient(e) {
    const id = e.target.id
    const api = new ApiRequest();
    const msg = new MessageDisplay();
    const result = await api.get(`get-client-atendiment-manual/${id}`);

    startLoad();
    if (result.error === true && result) {
      stopLoad();
      msg.error('Mailings', result.data)
      return;
    }

    stopLoad();
    this.setState({
      newComponent: <Atendiment
        data={result.data} mailing={id}
      />
    })
    msg.success('Atendimento', 'Próximo cliente localizado com sucesso')
    return;

  }

  async obterTaxa(e) {
    const value = e.target.closest('.btn')
    const doc = value.id
    const api = new ApiRequest()
    const msg = new MessageDisplay();

    const postData = {
      doc: doc
    }
    const result = await api.post(`obter-taxa`, postData, false, false)
    if (result.error === true && result) {
      msg.error('Emprestimos', result.data)
      return;
    }
    msg.success('Emprestimos', 'Taxas obtidas com sucesso')
    this.setState({
      emprestimos: result.data
    })
    return;
  }

  render() {
    const {
      newComponent,
      typeFicha,
      client,
      phones,
      representanteLegalContato,
      dadosBancarios,
      address,
      inss,
      emprestimos,
      auth,
      cartaoRmc,
      cartaoRcc,
      fgts,
      siape,
      listTab,
    } = this.state;


    const { mailing } = this.props





    return (
      <>
        {newComponent ? (
          newComponent
        ) : (
          <>
            <div className="page-body">
              <div className="container-fluid">
                <PageTitle title={`Módulo Call Center`} />
              </div>
              <div className="d-flex w-100 mb-3 align-items-center justify-content-end">
                <button
                  class="btn btn-sm btn-info"
                  onClick={this.handleModalOpen}
                >
                  <i className="fa fa-search"></i>  Pesquisar
                </button>
                {mailing && (<>
                  <button
                    style={{ 'marginLeft': '10px' }}
                    class="btn btn-sm btn-success"
                    id={mailing}
                    onClick={(e) => { this.atendimentManulClient(e) }}
                  >
                    <i className="fa fa-arrow-circle-right"></i>  Proximo Contato
                  </button>
                </>)}
              </div>
              <Tab>
                <UlTab>
                  {listTab
                    ? listTab.map((e) => {
                      return (
                        <>
                          <LiTab
                            onTypeCLick={() => this.getInfoType(e.name)}
                            name={e.name}
                            active={e.active}
                            id={e.id}
                            href={e.href}
                            view={e.view}
                          />
                        </>
                      );
                    })
                    : ""}
                </UlTab>

                {
                  <DataClient
                    client={client}
                    beneficios={inss}
                    emprestimos={emprestimos}
                    type={typeFicha}
                    auth={auth}
                    atualizacaoBeneficios={(e) => {
                      this.atualizacaoBeneficios(e);
                    }}
                    obterTaxa={(e) => {
                      this.obterTaxa(e)
                    }}
                  />
                }


                <div className="tab-content" id="top-tabContent">
                  <div
                    className="search-links tab-pane active fade show"
                    id="data-client"
                    role="tabpanel"
                    aria-labelledby="dataclient"
                  >
                    <br />
                    <div className="row">
                      {<DadosCadstrais client={client} />}
                      {<Address address={address} />}
                    </div>
                    <div className="row">{<Phones phones={phones} representanteLegalContato={representanteLegalContato} />}</div>
                  </div>
                </div>


                <SegurosComponente
                  dadosBancarios={dadosBancarios}
                />

                <BeneficioListInss
                  beneficios={inss}
                  dadosBancarios={dadosBancarios}
                  emprestimos={emprestimos}
                  cartaoRmc={cartaoRmc}
                  cartaoRcc={cartaoRcc}
                />


              </Tab>
            </div>
            {/* Modal */}
            <Modal
              size="xl"
              show={this.state.showModal}
              onHide={this.handleModalClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>{'Pesquiser Cliente'}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Search
                  titleFomr={false}
                  colSearch={'col-12'}
                  colResult={'col-12'}
                  disabledTitle={true}
                  fnGetClientAtendiment={(e) => {
                    e.preventDefault();
                    const msg = new MessageDisplay();
                    startLoad();
                    const value = e.target.closest(".btn");
                    const data = JSON.parse(value.id);

                    setTimeout(() => {
                      this.setState({
                        newComponent: (
                          <Atendiment
                            data={data}
                            getClientAtendiment={this.getClientAtendiment}
                          />
                        ),
                      });
                      stopLoad();
                    }, 1000);
                    this.handleModalClose();
                    msg.success("Atendimento", "Atendimento Aberto com sucesso");
                    return;
                  }}
                />
              </Modal.Body>
            </Modal>
          </>
        )}
      </>
    );
  }
}
