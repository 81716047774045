import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const NewAgendaModalFicha = ({ createAgenda, handleClose = () => {}, client = {} }) => {
  const [formData, setFormData] = useState({
    name: client.nome || "",
    document: client.doc || "",
    phone: "",
    id_client: client._id || "",
    content: "",
    dataschedule: "",
  });
  const [msgAlert, setMsgAlert] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setFormData(old=>({
      ...old,
      name: client.nome || "",
      document: client.doc || "",
      id_client: client._id || "",
    }))
  }, [client])

  const handleSubmit = async (e) => {
    setMsgAlert('');
    // Validar os dados aqui, se necessário
    // Exemplo de validação: verificar se os campos obrigatórios foram preenchidos
    if (!formData.name || !formData.document || !formData.phone || !formData.dataschedule) {
      // Mostrar uma mensagem de erro ou realizar a ação adequada para tratar a validação falhada
      setMsgAlert('Preencha todos os campos obrigatórios');
      return;
    }

    // Chamar a função de criação de agenda passando os dados do formulário
    try {
      await createAgenda(formData);
      handleClose();
      setFormData({
        name: client.nome || "",
        document: client.doc || "",
        phone: "",
        id_client: client._id || "",
        content: "",
        dataschedule: "",
      });
    } catch (error) {
      // Tratar erro na criação do agendamento
      // console.error("Erro ao criar agendamento:", error);
      // Mostrar uma mensagem de erro ou realizar a ação adequada para tratar o erro
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Novo agendamento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group mb-3">
          <label htmlFor="phone">Telefone:</label>
          <input
            type="text"
            className="form-control"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="content">Conteúdo:</label>
          <textarea
            id="content"
            name="content"
            className="form-control"
            value={formData.content}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="form-group mb-3">
          <label htmlFor="dataschedule">Data do Agendamento:</label>
          <input
            type="datetime-local"
            id="dataschedule"
            name="dataschedule"
            className="form-control"
            value={formData.dataschedule}
            onChange={handleChange}
            required
          />
        </div>
        <div><p className="mb-0">{msgAlert}</p></div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Enviar
        </Button>
      </Modal.Footer>
    </>
  );
};

export default NewAgendaModalFicha;
