import { Children, Component } from "react"
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import CurrencyFormat from 'react-currency-format';

export class Form extends Component {
    render() {
        const { children, title, onSubmit, style, row = true } = this.props
        return (
            <>
                <form className="card" style={style} onSubmit={(e) => { onSubmit(e) }}>
                    {title &&
                        (
                            <div className="card-header">
                                <h4 className="card-title d-flex align-items-center mb-0">{title}</h4>
                            </div>
                        )
                    }
                    {children && (
                        <div className="card-body">
                            <div className={row ? 'row' : ''}>
                                {children}
                            </div>
                        </div>)}
                </form>
            </>
        )
    }
}
Form.propTypes = {
    style: PropTypes.string,
};


export class Input extends Component {
    render() {
        const { classInput, name, style, label, valueInput, placeholder, type, onChange, title, mask, max, Required, min, disabled, readOnly } = this.props;
        return (
            <>
                <div className={classInput} style={style}>
                    <div className="mb-3">
                        {label && (<label className="form-label">{label}</label>)}
                        <InputMask
                            name={name}
                            className={`form-control ${Required ? 'required' : ''}`}
                            defaultValue={valueInput} // use a propriedade 'value' em vez de 'defaultValue'
                            mask={mask}
                            max={max} // defina a máscara desejada
                            min={min} // defina a máscara desejada
                            maskChar={null} // defina como null para não exibir caracteres de espaço reservado
                            type={type}
                            placeholder={placeholder}
                            onChange={onChange && ((e) => { onChange(e) })}
                            title={title}
                            disabled={disabled}
                            readOnly={readOnly}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export class InputCurrency extends Component {
    render() {
        const { classInput, name, style, label, valueInput, placeholder, type, onChange, title, mask, max, Required, dataType, id } = this.props;
        return (
            <div className={classInput} style={style}>
                <div className="mb-3">
                    {label && <label className="form-label">{label}</label>}
                    <CurrencyFormat
                        name={name}
                        id={id}
                        data-type={dataType}
                        className={`form-control ${Required ? 'required' : ''}`}
                        value={valueInput} // use a propriedade 'value' em vez de 'defaultValue'
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix="R$ "
                        displayType={'input'}
                        decimalScale={2}
                        fixedDecimalScale
                        placeholder={placeholder}
                        onChange={onChange && ((e) => { onChange(e) })}
                        type={type}
                        title={title}
                    />
                </div>
            </div>
        );
    }
}
export default InputCurrency;

Input.propTypes = {
    classInput: PropTypes.string,
    style: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export class SelectForm extends Component {
    render() {
        const { labelName, name, data, dataType, label, classInput, style, onChange, level, valueSelect, optionLebel, Required, id } = this.props

        return (
            <>
                <div className={classInput} style={style}>
                    <div className="mb-3">
                        <label className="form-label">{labelName}</label>
                        <select
                            onChange={onChange && ((e) => { onChange(e) })}
                            id={id} className={`form-select digits ${Required ? 'required' : ''}`}
                            data-type={dataType}
                            name={name}>
                            <option value="">{optionLebel ? optionLebel : 'Selecione um Opção'}</option>
                            {!!data && data.length > 0 ? data.map((e) => {
                                return (
                                    <>
                                        {
                                            e.role ? (
                                                <>
                                                    {e.role.includes(level) ? (
                                                        <>
                                                            <option
                                                                selected={valueSelect === e.value ? true : ''}
                                                                value={e.value}
                                                            >
                                                                {e.label}</option>
                                                        </>
                                                    ) : ''}
                                                </>
                                            ) : <option selected={valueSelect === e.value ? true : ''} value={e.value}>{e.label}</option>
                                        }
                                    </>
                                )
                            }) : <option>Não existe dados para serem exibidos</option>}
                        </select>
                    </div>
                </div >
            </>
        )
    }
}

export class InputFile extends Component {
    render() {
        const { classInput, style, name, label, valueInput, placeholder, type, onChange, title, Required } = this.props
        return (
            <>
                <div className={classInput} style={style}>
                    <div className="mb-3">
                        <label className="form-label">{title}</label>
                        <input name={name} className={`form-control ${Required ? 'required' : ''}`} value={valueInput} type="file" onChange={onChange} data-bs-original-title="" title="" />
                    </div>
                </div>
            </>
        )
    }
}

export class InputData extends Component {
    render() {
        const { classInput, style, name, valueInput, placeholder, type, onChange, title, defaultValue, Required, label } = this.props
        return (
            <>
                <div className={classInput} style={style}>
                    <div className="mb-3">
                        {label && (<label className="form-label">{label}</label>)}
                        <Input name={name} title={title} value={valueInput} className={`form-control digits ${Required ? 'required' : ''}`} type="date" defaultValue={defaultValue ? defaultValue : '2018-01-01'} />
                        {/* <input name={name} className="form-control digits" title={title} value={valueInput} placeholder={placeholder} type="date" defaultValue={defaultValue ? defaultValue : '2018-01-01'} /> */}
                    </div>
                </div>
            </>
        )

    }
}

export class BtnForm extends Component {
    render() {
        const { name, title, disabled } = this.props
        return (
            <>
                <div>
                    <div className=" text-end mt-4">
                        <button className="btn btn-primary" type="submit" title={title} disabled={disabled}>{name}</button>
                    </div>
                </div>
            </>
        )
    }
}
export class BtnNext extends Component {
    render() {
        const { name, onClick, isHidden, className } = this.props
        const btnClass = isHidden ? 'none' : '';
        return (
            <>
                <div style={{ display: btnClass }} className={`text-end mt-4`}>
                    <button onClick={onClick} className={`btn btn-primary ${className}`} type="button" data-bs-original-title="" title="">{name}</button>
                </div>
            </>
        )
    }
}
