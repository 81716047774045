// MyContext.js
import React from 'react';
import { startLoad, stopLoad } from '../messages/load';
import MessageDisplay from '../messages/messageDisplay';
import logOut from '../auth/logout';
export const ApiContext = React.createContext();

export class ApiRequest {
  tokenLocalStorage = ''
  tokenHash = ''
  login = ''
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    // construtor da classe
    this.tokenLocalStorage = window.localStorage.getItem("token");
    this.tokenHash = window.localStorage.getItem("tokenHash");
    this.login = window.localStorage.getItem("login");
  }

  async post(url, e, form = true, postData = false) {

    const msg = new MessageDisplay();
    if (!url || !e) {
      console.error("Não foram fornecidos os parâmetros necessários.");
      return false;
    }

    let body = e;
    try {
      if (form) {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const serializedForm = Object.fromEntries(formData.entries());
        body = serializedForm
      }

      if (postData) {
        for (let prop in postData) {
          body[prop] = postData[prop];
        }
      }

      startLoad();
      const response = await fetch(`${process.env.REACT_APP_HTTP}/${url}/${this.login}/${this.tokenHash}`, {
        method: 'POST',
        body: JSON.stringify(body),
        crossDomain: true,
        headers: { "Content-Type": "application/json", "Accept": "application/json", authorization: `Bearer ${this.tokenLocalStorage}` },
      });
      const result = await response.json();
      stopLoad();

      if (result.data === 'Acesso Negado' || result.data === 'Login Expirado!') {
        logOut();
        return
      }

      return result;
    } catch (error) {
      stopLoad();
      console.error(error);
      msg.error("Server", "Not connect");
      logOut();
      return error;
    }
  }


  async get(url, load = true) {
    if (load)
      startLoad();

    try {
      const response = await fetch(`${process.env.REACT_APP_HTTP}/${url}/${this.login}/${this.tokenHash}`, {
        method: 'GET',
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          authorization: `Bearer ${this.tokenLocalStorage}`
        },
      });
      const result = await response.json();

      if (load)
        stopLoad();

      if (result.data == 'Acesso negado') {
        logOut();
        return
      }
      return result;
    } catch (error) {
      if (load)
        stopLoad();
      console.error(error);
      return false;
    }
  }

  async getById(url, id, type) {
    const urlRequest = `${url}/${id}/${type}`;
    return this.get(urlRequest);
  }

  async put(url, body) {
    try {
      const response = await fetch(`${process.env.REACT_APP_HTTP}/${url}/${this.login}/${this.tokenHash}`, {
        method: 'PUT',
        body: JSON.stringify(body),
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          authorization: `Bearer ${this.tokenLocalStorage}`
        },
      });
      const result = await response.json();
      return result;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async delete(url) {
    try {
      const response = await fetch(`${process.env.REACT_APP_HTTP}/${url}/${this.login}/${this.tokenHash}`, {
        method: 'DELETE',
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          authorization: `Bearer ${this.tokenLocalStorage}`
        },
      });
      const result = await response.json();
      return result;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

}