import { Component } from "react";
import PageTitle from "../../../helpers/PageTitle";
import { Table, Tr, Td } from "../../../helpers/Table";
import ReturnPage from "../../../helpers/ReturnPage";
import Teams from "../teams/teams";
import Modal from "react-bootstrap/Modal";
import { Form, Input, BtnForm, SelectForm } from "../../../helpers/formData";
import MessageDisplay from "../../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../../messages/load";
import { listUfs } from "../../../helpers/FuncHelpers";
import { ApiRequest } from "../../../helpers/Api";

export default class CreateRegioes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newComponent: "",
      show: false,
      setShow: false,
      ufList: "",
      regionList: false,
      idName: "",
      ufs: "",
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleShowRegion = this.handleShowRegion.bind(this);
  }

  componentDidMount() {
    const { data } = this.props;
    this.setState({
      ufList: data,
    });
  }


  async deleteRegion(e) {
    const api = new ApiRequest();
    const msg = new MessageDisplay();

    const value = e.target.closest(".btn");
    const teamId = value.getAttribute("data-team");
    const id = value.id;

    const postData = {
      id, teamId
    }

    const result = await api.post(`teams/delete-region`, postData, false)
    if (result.error) {
      stopLoad();
      return msg.error("Equipe", result.data);
    }
    this.setState({
      ufList: result.data,
    });
  }

  async submitForm(e) {
    const api = new ApiRequest();
    const msg = new MessageDisplay();

    const result = await api.post(`teams/create-region`, e)
    if (result.error) {
      stopLoad();
      return msg.error("Equipe", result.data);
    }

    this.setState({
      ufList: result.data,
    });
  }


  async createRegioUf(e) {
    const api = new ApiRequest();
    const msg = new MessageDisplay();

    const result = await api.post(`teams/create-region-ufs`, e)
    if (result.error) {
      stopLoad();
      msg.error("Regiões", result.data);
      return
    }
    this.setState({
      ufList: result.data,
    });

  }

  async deleteUf(e) {
    const value = e.target.closest(".btn");
    const teamId = value.getAttribute("data-team");
    const id = value.id;
    const postData = {
      id,
      teamId
    }
    const api = new ApiRequest();
    const msg = new MessageDisplay();
    const result = await api.post(`teams/delete-region-ufs`, postData, false)
    if (result.error) {
      stopLoad();
      return msg.error("Regiões", result.data);
    }

    this.setState({
      ufList: result.data,
    });
  }

  handleClose() {
    this.setState({
      show: false,
    });
  }

  handleShow(e) {
    const value = e.target;
    this.setState({
      show: true,
      regionList: false,
      idName: "",
      ufs: "",
    });

    if (value.id) {
      const ufs = value.getAttribute("data-ufs");
      console.log(ufs);
      this.setState({
        show: true,
        regionList: true,
        idName: value.id,
        ufs: ufs,
      });
    }
  }

  handleShowRegion(e) {
    const value = e.target.closest('.btn')

    const ufs = value.getAttribute("data-ufs");
    this.setState({
      show: true,
      regionList: true,
      idName: value.id,
      ufs: ufs,
    });
  }

  returPage = () => {
    this.setState({
      newComponent: <Teams />,
    });
  };

  render() {
    const { newComponent, ufList, regionList, idName, ufs } = this.state;

    console.log(idName)
    const ArrayUfs = listUfs();
    return (
      <>
        {newComponent ? (
          newComponent
        ) : (
          <div className="page-body">
            <PageTitle title={"Regiões"} nameCompany={"Proconsig"} />

            <div className="container-fluid">
              <ReturnPage OnClick={this.returPage} />
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <h5>Cadastro de Regiões</h5>
                        </div>
                        <div>
                          <button
                            onClick={this.handleShow}
                            className="btn btn-info"
                          >
                            Cadastrar Nova
                          </button>
                        </div>
                      </div>
                    </div>

                    {ufList && ufList?.uf_list?.length > 0 ? (
                      <Table>
                        {ufList.uf_list.map((rg) => {
                          return (
                            <>
                              <Tr>
                                <Td classTd={"text-center"}>
                                  <p>{rg._id}</p>
                                </Td>
                                <Td classTd={"text-center"}>
                                  <p>{rg.name}</p>
                                </Td>
                                <Td classTd={"text-center"}>
                                  {rg.uf.map((uf, index) => {
                                    return (
                                      <>
                                        {uf.uf}
                                        {index !== rg.uf.length - 1 && ","}
                                      </>
                                    );
                                  })}
                                </Td>
                                <Td classTd={"text-center"}>
                                  <div className="btn-group">
                                    <button
                                      id={rg._id}
                                      data-ufs={JSON.stringify(rg.uf)}
                                      onClick={(e) => {
                                        this.handleShowRegion(e);
                                      }}
                                      className="adduf btn btn-primary"
                                      data-bs-original-title=""
                                      title="Adicionar UF"
                                    >
                                      <i className="fa fa-map"></i>
                                    </button>

                                    <button
                                      data-team={ufList?._id}
                                      id={rg._id}
                                      onClick={(e) => {
                                        this.deleteRegion(e);
                                      }}
                                      className="btn btn-secondary"
                                      data-bs-original-title=""
                                      title={`Delete Conjunto ${rg.name}`}
                                    >
                                      <i className="fa fa fa-close"></i>
                                    </button>
                                  </div>
                                </Td>
                              </Tr>
                            </>
                          );
                        })}
                      </Table>
                    ) : (
                      <p className="text-center mt-3">
                        Você ainda não possui regiões cadastradas
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Modal
              show={this.state.show}
              onHide={this.handleClose}
              size="lg"
              style={{ zIndex: "9998" }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {regionList ? "Cadastar Ufs" : "Cadastrar Nova Região"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {regionList ? (
                  <>
                    <Form title={""} onSubmit={(e) => this.createRegioUf(e)}>
                      <input
                        type="hidden"
                        name={"idTeams"}
                        defaultValue={ufList?._id}
                      />
                      <input type="hidden" name={"id"} defaultValue={idName} />
                      <SelectForm
                        classInput={"col-12"}
                        name={"name"}
                        label={"UF"}
                        data={ArrayUfs}
                      ></SelectForm>
                      <BtnForm name={"Adicionar Uf"} />
                    </Form>
                    <Table>
                      <Tr>
                        <Td classTd={"text-center"}>
                          <p>UF</p>
                        </Td>
                        <Td classTd={"text-center"}>
                          <p>DELETAR</p>
                        </Td>
                      </Tr>
                      {ufList?.uf_list?.map((rg) => {
                        if (rg._id == idName) {
                          return (
                            <>
                              {rg.uf.map((uf, index) => {
                                return (
                                  <>
                                    <Tr>
                                      <Td classTd={"text-center"}>{uf.uf}</Td>
                                      <Td classTd={"text-center"}>
                                        <button
                                          data-team={ufList._id}
                                          id={uf._id}
                                          onClick={(e) => {
                                            this.deleteUf(e);
                                          }}
                                          className="btn btn-sm btn-danger"
                                        >
                                          <i className="fa fa fa-close"></i>
                                        </button>
                                      </Td>
                                    </Tr>
                                  </>
                                );
                              })}
                            </>
                          );
                        }
                      })}
                      <Tr></Tr>
                    </Table>
                  </>
                ) : (
                  <Form title={""} onSubmit={(e) => this.submitForm(e)}>
                    <input
                      type="hidden"
                      name={"id"}
                      defaultValue={ufList?._id}
                    />

                    <Input
                      classInput={"col-12"}
                      type={"text"}
                      name={"name"}
                      label={"Nome"}
                      onChange={(e) => this.setState({ name: e })}
                      placeholder={"Nome da Região"}
                    />
                    <BtnForm name={"Cadastrar Região"} />
                  </Form>
                )}
              </Modal.Body>
            </Modal>
          </div>
        )}
      </>
    );
  }
}
