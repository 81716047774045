import { Component } from "react";
import React from 'react';
import PropTypes from 'prop-types';


export default class ListMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentWidth: window.innerWidth,
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }
    handleResize() {
        this.setState({ contentWidth: window.innerWidth });
    }

    sidebar(el, block) {
        const { contentWidth } = this.state;
        el?.target?.classList?.contains("show") &&
            el?.target?.classList?.remove("show");

        const currentList = el.target.closest(".sidebar-list");

        if (
            !currentList.querySelector(".sidebar-link").classList.contains("active")
        ) {
            document
                .querySelectorAll(".bg-overlay1")
                .forEach((el) => el.classList.remove("active"));
            if (!block) {
                currentList
                    .querySelectorAll(".sidebar-submenu, .menu-content")
                    .forEach((el) => {
                        el.style.display = "block";
                    });
            }

            document
                .querySelectorAll(".sidebar-link")
                .forEach((el) => el.classList.remove("active"));
            currentList.querySelector(".sidebar-link").classList.add("active");

            document.querySelectorAll(".bg-overlay1").forEach((el) => {
                el.classList.add("active");
                el.addEventListener("click", function (e) {
                    e.target.classList.remove("active");
                });
            });
        } else {
            if (!block) {
                currentList
                    .querySelectorAll(".sidebar-submenu, .menu-content")
                    .forEach((el) => {
                        el.style.display = "none";
                    });
            }

            currentList.querySelector(".sidebar-link").classList.remove("active");

            document.querySelectorAll(".bg-overlay1").forEach((el) => {
                el.classList.remove("active");
            });
        }

        if (contentWidth < "992") {
            document
                .querySelectorAll(".bg-overlay")
                .forEach((el) => el.classList.add("active"));
        }
    }

    render() {
        const { children, badge, onClick, href = "#", title, text, svg, menukey } = this.props;
        return (
            <li className="sidebar-list">
                {badge && (<label className="badge badge-light-primary">{badge}</label>)}
                <a
                    className="sidebar-link sidebar-title"
                    onClick={(e) => {
                        if (typeof onClick === 'function') {
                            onClick(menukey);
                            this.sidebar(e, true)
                        } else {
                            this.sidebar(e);
                        }
                    }}
                    title={title}
                    href={href}
                >
                    {svg}
                    <span className="lan-3">{text}</span>
                    {children && (
                        <div className="according-menu">
                            <i className="fa fa-angle-right"></i>
                        </div>
                    )}
                </a>
                <ul className="sidebar-submenu" style={{ display: "none" }}>
                    {children}
                </ul>
            </li>
        );
    }
}

ListMenu.propTypes = {
    // children: PropTypes.node.isRequired,
    href: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
    title: PropTypes.string,
    badge: PropTypes.string,
    menukey: PropTypes.string
};

export class Submenu extends Component {
    render() {
        const { children, href = "#", className = "lan-5 click_sub_menu", title, style, onClick, svg, menukey } = this.props;
        return (
            <li>
                <a
                    style={style}
                    href={href}
                    className={className}
                    title={title}
                    svg={svg}
                    onClick={() => onClick && onClick(menukey)}
                >
                    {children}
                </a>
            </li>
        );
    };
}

Submenu.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    href: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    style: PropTypes.string
};

export class Sidebar extends Component {
    render() {
        const { style, title, subtitle } = this.props;
        return (
            <li className="sidebar-main-title" style={style}>
                <div>
                    <h6 className="lan-1">{title}</h6>
                    {
                        subtitle && (
                            <p className="lan-2">
                                {subtitle}
                            </p>)
                    }

                </div>
            </li>
        )
    }
}

Sidebar.propTypes = {
    style: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
};



