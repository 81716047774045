import React, { useState, useEffect } from "react";
import PageTitle from "../../helpers/PageTitle";
import { Table, Tr, Td } from "../../helpers/Table";
import MessageDisplay from "../../messages/messageDisplay";
import { ApiRequest } from "../../helpers/Api";
import { startLoad, stopLoad } from "../../messages/load";
import { formatarCPF, formatarDataHora } from "../../helpers/FuncHelpers";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import NewAgendaModal from "./NewAgenda";

const AgendaList = ({ auth }) => {
  const [data, setData] = useState(null);
  const [newComponent, setNewComponent] = useState(null);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [agendaToDelete, setAgendaToDelete] = useState(null);

  useEffect(() => {
    getAgendas();
  }, []);

  const getAgendas = async () => {
    const msg = new MessageDisplay();
    startLoad();
    const api = new ApiRequest();
    const result = await api.get(`schedule-get-all`);

    if (result.error === true && result) {
      msg.error("Agenda", result.data);
      return;
    }
    stopLoad();
    setData(result.data);
  };

  const createAgenda = async (agendaData) => {
    try {
      const msg = new MessageDisplay();
      startLoad();
      const api = new ApiRequest();

      // Realizar a requisição POST para criar uma nova agenda
      const result = await api.post("schedule-create", agendaData, false);

      stopLoad();

      if (result.error === true && result.data) {
        // Lidar com o erro na criação da agenda
        msg.error("Erro ao criar agenda", result.data);
      } else {
        // Agenda criada com sucesso
        // Atualizar a lista de agendas
        getAgendas();
      }
    } catch (error) {
    }
  };

  const updateAgenda = async (id) => {
    // Lógica para atualizar uma agenda com o ID fornecido
  };

  const deleteAgenda = (id) => {
    setAgendaToDelete(id);
    setShowConfirm(true);
  };

  const confirmDeleteAgenda = async () => {
    try {
      const msg = new MessageDisplay();
      startLoad();
      const api = new ApiRequest();

      // Realizar a requisição DELETE para excluir a agenda
      const result = await api.delete(`schedule-delete/${agendaToDelete}`, false);

      stopLoad();

      if (result.error === true && result.data) {
        // Lidar com o erro na exclusão da agenda
        msg.error("Erro ao excluir agenda", result.data);
      } else {
        // Agenda excluída com sucesso
        // Atualizar a lista de agendas
        getAgendas();
      }

      setShowConfirm(false);
      setAgendaToDelete(null);
    } catch (error) {
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseConfirm = () => setShowConfirm(false);
  const handleShowConfirm = () => setShowConfirm(true);

  return (
    <>
      {newComponent ? (
        newComponent
      ) : (
        <>
          <div className="page-body">
            <div className="container-fluid">
              <PageTitle title={"Agenda"} auth={auth} />
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header d-flex align-items-center">
                      <h5>Últimos Agendamentos</h5>
                      <span className="ms-auto">
                        <button
                          onClick={handleShow}
                          className="btn btn-sm btn-info"
                        >
                          Novo agendamento
                        </button>
                      </span>
                    </div>
                    <Table>
                      {data && data.length > 0 ? (
                        data.map((agenda, i) => (
                          <Tr key={i}>
                            <Td classTd={"text-center"}>
                              <p>ID-{agenda._id}</p>
                            </Td>
                            <Td classTd={"text-center"}>
                              <p>{formatarDataHora(agenda.created_at)}</p>
                            </Td>
                            <Td classTd={"text-center"}>
                              <p>{agenda.name}</p>
                            </Td>
                            <Td classTd={"text-center"}>
                              <p>{formatarCPF(agenda.document)}</p>
                            </Td>
                            <Td classTd={"text-center"}>
                              <p>{agenda.content}</p>
                            </Td>
                            {/* <Td classTd={"text-center"}>
                              <button
                                onClick={() => updateAgenda(agenda._id)}
                                className="btn btn-sm btn-primary"
                              >
                                Editar
                              </button>
                            </Td> */}
                            <Td classTd={"text-center"}>
                              <button
                                onClick={() => deleteAgenda(agenda._id)}
                                className="btn btn-sm btn-danger"
                              >
                                Excluir
                              </button>
                            </Td>
                          </Tr>
                        ))
                      ) : (
                        <p className="text-center" style={{ padding: "15px" }}>
                          Você ainda não possui agendas
                        </p>
                      )}
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal show={show} onHide={handleClose}>
            <NewAgendaModal
              handleClose={handleClose}
              createAgenda={createAgenda}
            />
          </Modal>
          <Modal show={showConfirm} onHide={handleCloseConfirm}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmar exclusão</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Tem certeza de que deseja excluir a agenda com o ID: {agendaToDelete}?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseConfirm}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={confirmDeleteAgenda}>
                Excluir
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default AgendaList;
