
import PanelRouters from "./routers/panel_routers";
import Loading from "./components/messages/load";
import sessionClient from "./components/helpers/session";

function App() {
  const isLoggeIn = window.localStorage.getItem("loggeIn");
  return (
    <>
      <PanelRouters isLoggeIn={isLoggeIn} />
      <Loading />
    </>
  );
}

export default App;
