import { Component } from "react";
import PageTitle from "../../helpers/PageTitle";
import { CreativeCard } from "../../helpers/Cards";
import CreateMailingInss from "./convenios/inss";
import CreateMailingGov from "./convenios/gov";
import CreateMailingSiape from "./convenios/siape";
import CreateMailingFgts from "./convenios/fgts";
import { TeamTalkingLottie } from '../../animations/team-talking';
import CreateMailingSeguros from "./convenios/seguros_vida";

export default class CreateMailing extends Component {
    constructor(props) {
        super(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            newComponent: ''
        }
    }

    selectMailing(e) {

        if (e == 'inss') {
            this.setState({
                newComponent: <CreateMailingInss />
            })
        }

        if (e == 'fgts') {
            this.setState({
                newComponent: <CreateMailingFgts />
            })
        }

        if (e == 'gov') {
            this.setState({
                newComponent: <CreateMailingGov />
            })
        }

        if (e == 'seguros') {
            this.setState({
                newComponent: <CreateMailingSeguros />
            })
        }

        if (e == 'siape') {
            this.setState({
                newComponent: <CreateMailingSiape />
            })
        }

    }

    render() {
        const { newComponent, auth } = this.state
        console.log('....', auth)
        return (<>
            {newComponent ? newComponent : (
                <div className="page-body">
                    <PageTitle title={"Criar Modelo"} auth={auth} />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h5>Selecione o Convênio</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            {auth.company_id?.convenios.length > 0 ? auth.company_id.convenios.map((m) => {
                                                let colorBlock = (m.value == 'inss' ? 'primary' :
                                                    m.value == 'siape' ? 'danger' :
                                                        m.value == 'fgts' ? 'success' :
                                                            m.value == 'gov' ? 'warning' :
                                                                m.value == 'seguros' ? 'info'
                                                                    : '')
                                                return (<>
                                                    <CreativeCard
                                                        width={"col-sm-6 col-md-6 col-lg-6 col-xl-3"}
                                                        color={colorBlock}
                                                        title={m.name}
                                                        type={m.value}
                                                        onClick={(e) => { this.selectMailing(e) }}
                                                        desc={`Crie Campanha para trabalhar ${m.name}`}
                                                    />
                                                </>)
                                            })
                                                : <div className="text-center">
                                                    <TeamTalkingLottie className="mb-2" />
                                                    <h6 className="m-0 p-0">Sua empresa não possui convênios habilitados</h6>
                                                    <div className="p-0">Entre em contato com o administrador para utilizar a criação de modelos para seus mailings</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
        )
    }
}