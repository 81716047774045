import React, { Component } from "react";
import PageTitle from "../../helpers/PageTitle";
import { AnimationLottie } from "../../animations/animationLottie";
import DashboardWhiteLabel from "./level/whitelabel";
import DashboardEmpresa from "./level/empresa";
import DashboardOperador from "./level/operador";
export default class DashBoard extends Component {
    constructor(props) {
        super(props)
        const auth = window.localStorage.getItem("user")
        this.state = {
            auth: JSON.parse(auth)
        }
    }

    returnComponentIsComing() {
        return (
            <>
                <div className="text-center">
                    <AnimationLottie fileJsonName="rocket_fly" height={310} />
                    <h5 className="fw-bolder text-uppercase mb-2 mt-1">Este painel estará disponível em breve! </h5>
                    Aguarde alguns dias para aproveitar todos os benefícios que ele proporcionará. <br />
                    Estamos trabalhando duro para tornar essa experiência excepcional para você e sua equipe. <br />

                    <h6 className="mt-2">Fique atento às atualizações e novidades!</h6>
                </div>
            </>
        )
    }

    returnComponentDashByLevel() {
        const { auth } = this.state
        const level = auth.level;

        switch (level) {
            case 'whitelabel':
                return <DashboardWhiteLabel />
            case 'revenda':
                return <DashboardWhiteLabel />
            case 'empresa':
                return <DashboardEmpresa />
            case 'operador':
                return <DashboardOperador auth={auth} />
            default:
                return this.returnComponentIsComing(); //temporário até finalizar o dash para os outros levels
        }
    }

    render() {
        const { auth } = this.state
        return (
            <>
                <div className="page-body">

                    <div className="container-fluid">
                        <PageTitle title={"Dashboard"} auth={auth} />
                    </div>

                    {/* <SkeletonLoadDash /> */}
                    {this.returnComponentDashByLevel()}

                </div>
            </>
        )
    }
}