import { Component } from "react";
import { Table, Td, Tr } from "../../../helpers/Table";
import { formatBank, formatDate, formatarValorEmDinheiro, listBanks } from "../../../helpers/FuncHelpers";
import InputCurrency, { Input, InputData, SelectForm } from "../../../helpers/formData";
import { ApiRequest } from "../../../helpers/Api";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default class SimuladorInss extends Component {
    constructor(props) {
        super(props)
        this.state = {
            beneficio: null,
            nb: null,
            bancos: null,
            bancosMargemList: null,
            bancosCartaoList: null,
            bancosPortabilidadeList: null,
            coeficienteMargem: null,
            coeficienteCartao: null,
            coeficienteCartaoRcc: null,
        }
    }

    componentDidMount() {
        this.getBanksCoeficiente()
    }


    async getBanksCoeficiente() {
        const api = new ApiRequest();
        const result = await api.get(`atendiment-get-coeficientes`)
        const bancoList = listBanks();


        if (result && result.error == false) {
            let bancosMargem = result.data.filter((e) => e.tipo === 'margem')
            let bancosMargemList = {};
            if (bancosMargem.length > 0) {
                // Filtrar elementos únicos com base na propriedade "value"
                bancosMargemList = bancosMargem.map((b) => {
                    const bankName = bancoList.find((ba) => ba.value === b.banco);
                    return {
                        label: bankName.label,
                        value: b.banco,
                    };
                }).filter((item, index, self) => {
                    // Retorna true apenas para o primeiro índice do elemento encontrado
                    return index === self.findIndex((t) => t.value === item.value);
                });
            }


            let bancosCartao = result.data.filter((e) => e.tipo === 'cartao')
            let bancosCartaoList = {};
            if (bancosCartao.length > 0) {
                // Filtrar elementos únicos com base na propriedade "value"
                bancosCartaoList = bancosCartao.map((b) => {
                    const bankName = bancoList.find((ba) => ba.value === b.banco);
                    return {
                        label: bankName.label,
                        value: b.banco,
                    };
                }).filter((item, index, self) => {
                    // Retorna true apenas para o primeiro índice do elemento encontrado
                    return index === self.findIndex((t) => t.value === item.value);
                });
            }


            let bancosPortabilidade = result.data.filter((e) => e.tipo === 'portabilidade')
            let bancosPortabilidadeList = {};
            if (bancosPortabilidade.length > 0) {
                // Filtrar elementos únicos com base na propriedade "value"
                bancosPortabilidadeList = bancosPortabilidade.map((b) => {
                    const bankName = bancoList.find((ba) => ba.value === b.banco);
                    return {
                        label: bankName.label,
                        value: b.banco,
                    };
                }).filter((item, index, self) => {
                    // Retorna true apenas para o primeiro índice do elemento encontrado
                    return index === self.findIndex((t) => t.value === item.value);
                });
            }



            this.setState({
                bancos: result.data,
                bancosMargemList: bancosMargemList,
                bancosCartaoList: bancosCartaoList,
                bancosPortabilidadeList: bancosPortabilidadeList
            })
        }
    }

    getCoeficente(e) {
        const { bancos } = this.state
        const target = e.target
        const value = target.value

        const beneficio = e.target.id

        if (value) {

            const type = target.getAttribute('data-type');
            const coeficienteList = bancos.filter((e) => e.banco === value)
            const coe = coeficienteList.map((e) => {
                return ({
                    label: `${e.nome} - ${e.fator}`,
                    value: e._id
                })
            })

            if (type === 'margem') {

                this.setState({
                    coeficienteMargem: coe,
                    nb: beneficio
                })
            }

            if (type === 'cartao') {
                this.setState({
                    coeficienteCartao: coe,
                    nb: beneficio
                })
            }

            if (type === 'cartaorcc') {
                this.setState({
                    coeficienteCartaoRcc: coe,
                    nb: beneficio
                })
            }

        }

    }

    valorLiquido(e) {
        const { bancos, nb } = this.state

        const target = e.target
        const value = target.value
        if (value) {
            const type = target.getAttribute('data-type');
            this.valueFmt(type, value, bancos, nb)
        }
    }

    async calcMargem(e) {
        const target = e.target
        const nb = target.id
        const type = target.getAttribute('data-type')

        const value = document.querySelector(`[name=${type}]`).value

        if (value) {
            const { bancos } = this.state
            this.valueFmt(type, value, bancos, nb)
        }
    }

    async valueFmt(type, value, bancos, nb) {
        const valorLiquido = bancos.find((e) => e._id === value)
        const margem = document.querySelector(`[name=value_${type}_${nb}]`).value
        const margemFmt = margem.replace(/[R$.\s]/g, '').replace(',', '.');
        const vlLiquido = (margemFmt / valorLiquido.fator)

        const prazo = document.querySelector(`[name=prazo_${type}_${nb}]`)
        prazo.value = valorLiquido.prazo

        const valor = document.querySelector(`[name=valor_liquido_${type}_${nb}]`)
        valor.value = formatarValorEmDinheiro(vlLiquido)
    }

    render() {
        const { bancosMargemList, bancosCartaoList, bancosPortabilidadeList, coeficienteMargem, coeficienteCartao, coeficienteCartaoRcc } = this.state
        const { closeContain, beneficios, emprestimos } = this.props
        const bankList = listBanks()
        return (<>

            <div className="tab-content" id="c-pills-tabContent">
                <div className="customizer-header">
                    <i onClick={(e) => { closeContain(e) }} className="icofont-close icon-close"></i>
                    <h5>Simulador de Contratos</h5>
                    <h6 className="m-0 p-0"><i class="fa fa-rocket" aria-hidden="true"></i> Simule seu contratos:</h6>
                    <p className="m-0 p-0"><i className="fa fa-info-circle"></i> Essa é uma calculadora baseada em seus coeficientes </p>
                </div>
                <div className="simulator_list" style={{ padding: '20px' }}>
                    {(beneficios && beneficios.length) > 0 && (beneficios.map((b) => {
                        const rubricas = emprestimos !== null ? emprestimos.filter((e) => e.nb === b.beneficio) : null
                        return (<>
                            <h1 className="title">Benefício: {b.beneficio}</h1>
                            <div className="tab-pane fade active show" id="c-pills-home" role="tabpanel" aria-labelledby="c-pills-home-tab">
                                <div className="simulator">
                                    <h1>MARGEM LIVRE</h1>
                                    {b.margem_margemDisponivelEmprestimo > 0 ? (
                                        <>

                                            <div className="card-body" style={{ padding: '0px 20px' }}>
                                                <div className="row">
                                                    <div className="ficha_item_title col-3">
                                                        <span>
                                                            <InputCurrency
                                                                id={b.beneficio}
                                                                dataType={`margem`}
                                                                onChange={(e) => this.calcMargem(e)}
                                                                label={'Margem Disponível'}
                                                                name={`value_margem_${b.beneficio}`}
                                                                valueInput={formatarValorEmDinheiro(b.margem_margemDisponivelEmprestimo)}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="ficha_item_title col-3">
                                                        <span>
                                                            <SelectForm
                                                                onChange={(e) => this.getCoeficente(e)}
                                                                id={b.beneficio}
                                                                dataType={`margem`}
                                                                labelName={'Banco'}
                                                                data={bancosMargemList}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="ficha_item_title col-3">
                                                        <span>
                                                            <SelectForm
                                                                onChange={(e) => this.valorLiquido(e)}
                                                                dataType={`margem`}
                                                                name={`margem`}
                                                                labelName={'Tabela Coeficiente'}
                                                                data={coeficienteMargem}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="ficha_item_title col-1">
                                                        <Input
                                                            label={'Prazo'}
                                                            name={`prazo_margem_${b.beneficio}`}
                                                            valueInput={'--'}
                                                        />
                                                    </div>

                                                    <div className="ficha_item_desc col-2">
                                                        <Input
                                                            label={'Valor Liberado'}
                                                            name={`valor_liquido_margem_${b.beneficio}`}
                                                            valueInput={'--'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>) : (<>Esse benefício não possui margem Livre</>)}
                                    <h1>CARTÃO RMC</h1>
                                    {b.margem_margemDisponivelRmc > 0 ? (<>
                                        <div className="card-body" style={{ padding: '0px 20px' }}>
                                            <div className="row">
                                                <div className="ficha_item_title col-3">
                                                    <span>
                                                        <InputCurrency
                                                            dataType={`cartao`}
                                                            onChange={(e) => this.calcMargem(e)}
                                                            label={'Cartão RMC'}
                                                            id={b.beneficio}
                                                            name={`value_cartao_${b.beneficio}`}
                                                            valueInput={formatarValorEmDinheiro(b.margem_margemDisponivelRmc)}
                                                        /></span>
                                                </div>
                                                <div className="ficha_item_title col-3">
                                                    <span>
                                                        <SelectForm
                                                            onChange={(e) => this.getCoeficente(e)}
                                                            dataType={`cartao`}
                                                            labelName={'Banco'}
                                                            id={b.beneficio}
                                                            data={bancosCartaoList}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="ficha_item_title col-3">
                                                    <span>
                                                        <SelectForm
                                                            onChange={(e) => this.valorLiquido(e)}
                                                            dataType={`cartao`}
                                                            name={`cartao`}
                                                            data={coeficienteCartao}
                                                            labelName={'Tabela Coeficiente'}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="ficha_item_title col-1">
                                                    <Input
                                                        label={'Prazo'}
                                                        name={`prazo_cartao_${b.beneficio}`}
                                                        valueInput={'--'}
                                                    />
                                                </div>

                                                <div className="ficha_item_desc col-2">
                                                    <Input
                                                        label={'Valor Liberado'}
                                                        name={`valor_liquido_cartao_${b.beneficio}`}
                                                        valueInput={'--'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>) : (<>Esse benefício já possui CARTÃO RMC</>)}
                                    <h1>CARTÃO RCC</h1>
                                    {b.margem_margemDisponivelRcc > 0 ? (<>

                                        <div className="card-body" style={{ padding: '0px 20px' }}>
                                            <div className="row">
                                                <div className="ficha_item_title col-3">
                                                    <span>
                                                        <InputCurrency
                                                            onChange={(e) => this.calcMargem(e)}
                                                            dataType={`cartaorcc`}
                                                            label={'Cartão RCC'}
                                                            id={b.beneficio}
                                                            name={`value_cartaorcc_${b.beneficio}`}
                                                            valueInput={formatarValorEmDinheiro(b.margem_margemDisponivelRcc)}
                                                        /></span>
                                                </div>
                                                <div className="ficha_item_title col-3">
                                                    <span>
                                                        <SelectForm
                                                            onChange={(e) => this.getCoeficente(e)}
                                                            dataType={`cartaorcc`}
                                                            labelName={'Banco'}
                                                            id={b.beneficio}
                                                            data={bancosCartaoList}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="ficha_item_title col-3">
                                                    <span>
                                                        <SelectForm
                                                            onChange={(e) => this.valorLiquido(e)}
                                                            dataType={`cartaorcc`}
                                                            name={`cartaorcc`}
                                                            data={coeficienteCartaoRcc}
                                                            labelName={'Tabela Coeficiente'}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="ficha_item_title col-1">
                                                    <Input
                                                        label={'Prazo'}
                                                        name={`prazo_cartaorcc_${b.beneficio}`}
                                                        valueInput={'--'}
                                                    />
                                                </div>

                                                <div className="ficha_item_desc col-2">
                                                    <Input
                                                        label={'Valor Liberado'}
                                                        name={`valor_liquido_cartaorcc_${b.beneficio}`}
                                                        valueInput={'--'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>) : (<>Esse benefício  já possui CARTÃO RCC</>)}
                                    <h1>EMPRÉSTIMOS</h1>
                                    {/* 
                                    {rubricas ? rubricas.map((r) => {
                                        const bankName = r?.banco?.codigo && r?.banco?.codigo !== null ? bankList.filter((e) => e.value == formatBank(r?.banco?.codigo))[0] : null
                                        return (<>
                                            <div className="emprestimos">
                                                <div className="row">
                                                    <table>
                                                        <tr style={{ fontSize: "0.775em" }}>
                                                            <th >CONTRATO</th>
                                                            <th >BANCO</th>
                                                            <th >INÍCIO</th>
                                                            <th >FIM</th>
                                                            <th >TAXA</th>
                                                            <th >VL CONTRATADO</th>
                                                            <th >PRAZO</th>
                                                            <th >PARCELA</th>
                                                            <th >SALDO DEVEDOR</th>
                                                            <th >VL BRUTO</th>
                                                        </tr>
                                                        <tr style={{ fontSize: "0.775em" }}>
                                                            <td>{r?.contrato}</td>
                                                            <td><b>{r?.banco?.codigo && formatBank(r?.banco?.codigo)}</b> -
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            {bankName?.label}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <button className="btn btn-xs btn-primary"><i className="fa fa-info"></i>
                                                                    </button>
                                                                </OverlayTrigger>

                                                            </td>
                                                            <td>{r?.competenciaInicioDesconto ? formatDate(r?.competenciaInicioDesconto) : null}</td>
                                                            <td>{r?.competenciaFimDesconto ? formatDate(r?.competenciaFimDesconto) : null}</td>
                                                            <td>
                                                                {r?.taxa? ? `${r?.taxa?}%` : null} {r.statusTaxa && (<>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip id="tooltip">
                                                                                Taxa atualizada com sucesso
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <button className="btn btn-xs btn-success"><i className="fa fa-check"></i>
                                                                        </button>
                                                                    </OverlayTrigger>

                                                                </>)}
                                                            </td>
                                                            <td>{r?.valorEmprestado? ? formatarValorEmDinheiro(r?.valorEmprestado?) : null}</td>
                                                            <td>{r?.quantidadeParcelas - r?.quantidadeParcelasEmAberto}/{r?.quantidadeParcelas}</td>
                                                            <td>{r?.valorParcela? ? formatarValorEmDinheiro(r?.valorParcela?) : null}</td>
                                                            <td>{r?.saldoQuitacao? ? formatarValorEmDinheiro(r.saldoQuitacao) : null}</td>
                                                            <td>{r?.valorParcela? ? formatarValorEmDinheiro(r?.valorParcela? * r.quantidadeParcelas) : null}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <div className="row">
                                                    <div className="ficha_item_title col-3">
                                                        <span> <InputCurrency
                                                            label={'Valor Parcela'}
                                                            name={'value_margem'}

                                                            valueInput={formatarValorEmDinheiro(r.valorParcela)}
                                                        /></span>
                                                    </div>
                                                    <div className="ficha_item_title col-3">
                                                        <span>
                                                            <SelectForm
                                                                data={bancosPortabilidadeList}
                                                                labelName={'Banco'}
                                                            /></span>
                                                    </div>
                                                    <div className="ficha_item_title col-2">
                                                        <span><SelectForm
                                                            labelName={'Tabela Coeficiente'}
                                                        /></span>
                                                    </div>
                                                    <div className="ficha_item_title col-1">
                                                        <p>Prazo</p>
                                                        <span>--</span>
                                                    </div>

                                                    <div className="ficha_item_desc col-3">
                                                        <p>Valor Líquido</p>
                                                        <span>--</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>)
                                    }) : (<>Esse benefício não possui empréstimos</>)} */}
                                </div>
                            </div>
                        </>)
                    })
                    )}
                </div >
            </div>
        </>
        )
    }
}