import { Component } from "react";
import LogoDefaultExtend from "../../assets/images/logo/logo.png";
import LeftHeader from "./left-header";
import ListMenu, { Submenu, Sidebar } from "../helpers/Menu";
import { Helmet } from "react-helmet";
import {
  IconMonitor,
  IconUser,
  IconAward,
  IconFilePlus,
  IconSearch,
  IconConfig,
  IconSidebarToggle,
  Calendar,
  IconHeadPhone,
  IconChart,
  IconChevronUp,
  IconPhone,
  IconAtendimentManual,
} from "../helpers/icons";
import DashBoard from "../painel/dash/dash";
import logOut from "../../components/auth/logout";
// SEARCH
import Search from "./search/search";
import ListSearch from "./search/list_search";
// END SEARCH
// ATENDIENT
import AtendimentList from "./atendiment/list";
// END ATENDIENT
// COMPANY
import Company from "./companies/companies";
import CreateCompany from "./companies/create";
// END COMPANY
// MAILING
import Mailing from "./mailing/mailing";
import CreateMailing from "./mailing/create";
// END MAILING
// CALL-CENTER
import ClientCallCenter from "./callcenter/client";
// END CALL CENTER
// USERS
import ListUser from "./users/list";
import CreateUser from "./users/create";
// END USERS
// REPORTS
import CompanyRenovation from "./reports/empresa/renovations";
import CompanyTrial from "./reports/empresa/trial";
import ReportsAtendiment from "./reports/atendiment/atendiment";

// END REPORTS
// CONFIG
import CreateTeams from "./config/teams/create";
import Teams from "./config/teams/teams";
// END CONFIG
// PERFIL
import Perfil from "./perfil/perfil";
import RamalOper from "./perfil/ramal";
import Api from "./api/api";
// END PERFIL
import sessionClient from "../helpers/session";

// WHITELABEL
import WhiteLabel from "./whitelabel/config";

// ERRORS
import TeamsNotFound from "../auth/teams-not-found";
import AtendimentManual from "./atendiment/atendiment_manual";

// agenda
import Agenda from "./agenda/agenda";
import sweetAlert from "../helpers/sweet";
import moment from "moment";

import CoefficientList from "./config/coeficientes";

export default class Painel extends Component {
  constructor(props) {
    super(props);
    const auth = window.localStorage.getItem("user");
    const colorMenu = localStorage.getItem("bodyColor");
    const companyWhite = JSON.parse(localStorage.getItem('whiteLabelSettings'))
    this.state = {
      whitelabelSettings: companyWhite,
      auth: JSON.parse(auth),
      colorMenu: colorMenu ? colorMenu : "ligth",
      typeMenu: "compact-wrapper",
      // typeMenu: 'compact-sidebar compact-small',
      activeComponent: <DashBoard />,
      contentWidth: window.innerWidth,
      desblockPainel: false,
      key: null,
    };

    // this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.clickColor = this.clickColor.bind(this);
    this.validateTeams = this.validateTeams.bind(this);
    // this.socketFunciont();
  }


  componentDidUpdate(prevProps, prevState) {
    const { key } = this.state
    if (!prevProps.statusWebsocketConnect) {

      if (key === 'call_center') {
        this.setState({
          activeComponent: <ClientCallCenter statusWebsocketConnect={prevProps.statusWebsocketConnect} />,
        });
      }
    }
  }

  async componentDidMount() {
    const token = window.localStorage.getItem("tokenHash");
    const session = await sessionClient.getSession(token);
    if (session) {
      session.onSocket.onLogout(false, (data) => {
        const [{ logout }] = data;
        if (logout) {
          const sweet = new sweetAlert();
          sweet.warning('Desculpe, mas parece que houve um login em sua conta de usuário a partir de outro dispositivo. Por razões de segurança, estamos encerrando sua sessão', 4000, true)
          setTimeout(() => {
            logOut();
          }, 4000)
        }
      });
    }
    window.addEventListener("resize", this.handleResize);
    this.validateTeams();
  }

  menuSidebar(e) {
    document.querySelector(".sidebar-wrapper")?.classList?.toggle("close_icon");
    document.querySelector(".page-header")?.classList?.toggle("close_icon");
    document.querySelector(".back-btn")?.classList?.toggle("close_icon");
    window.dispatchEvent(new Event("overlay"));

  }

  sidebar(el) {
    const { contentWidth } = this.state;
    el?.target?.classList?.contains("show") &&
      el?.target?.classList?.remove("show");

    const currentList = el.target.closest(".sidebar-list");

    if (
      !currentList.querySelector(".sidebar-link").classList.contains("active")
    ) {
      document
        .querySelectorAll(".bg-overlay1")
        .forEach((el) => el.classList.remove("active"));

      currentList
        .querySelectorAll(".sidebar-submenu, .menu-content")
        .forEach((el) => {
          el.style.display = "block";
        });

      document
        .querySelectorAll(".sidebar-link")
        .forEach((el) => el.classList.remove("active"));
      currentList.querySelector(".sidebar-link").classList.add("active");

      document.querySelectorAll(".bg-overlay1").forEach((el) => {
        el.classList.add("active");
        el.addEventListener("click", function (e) {
          e.target.classList.remove("active");
        });
      });
    } else {
      currentList
        .querySelectorAll(".sidebar-submenu, .menu-content")
        .forEach((el) => {
          el.style.display = "none";
        });

      currentList.querySelector(".sidebar-link").classList.remove("active");

      document.querySelectorAll(".bg-overlay1").forEach((el) => {
        el.classList.remove("active");
      });
    }

    if (contentWidth < "992") {
      document
        .querySelectorAll(".bg-overlay")
        .forEach((el) => el.classList.add("active"));
    }
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  async validateTeams() {
    const { auth } = this.state;
    if (auth.level === "supervisor" || auth.level === "operador")
      if (auth.teams.length <= 0)
        this.setState({
          desblockPainel: true,
        });
  }

  handleResize() {
    this.setState({ contentWidth: window.innerWidth });
    const { contentWidth } = this.state;

    const nav = document.querySelector(".nav");
    const header = document.querySelector(".header");
    const sidebar = document.querySelector(".sidebar-wrapper");
    const pageHeader = document.querySelector(".page-header");
    // if (contentWidth <= 991) {
    //     nav?.classList?.add("close_icon");
    //     header?.classList?.add("close_icon");
    //     sidebar?.classList?.add("close_icon");
    //     pageHeader?.classList?.add("close_icon");
    // } else {
    //     nav?.classList?.remove("close_icon");
    //     header?.classList?.remove("close_icon");
    //     sidebar?.classList?.remove("close_icon");
    //     pageHeader?.classList?.remove("close_icon");
    // }
  }

  clickColor(e) {
    const body = document.getElementById("bodyPainelColor");
    const iconBody = document.getElementById("iconColorPainel");

    if (iconBody.classList.value === "fa fa-moon-o") {
      iconBody.className = "fa fa-lightbulb-o";
      localStorage.setItem("iconColorMenu", "fa fa-lightbulb-o");
    } else {
      iconBody.className = "fa fa-moon-o";
      localStorage.setItem("iconColorMenu", "fa fa-moon-o");
    }

    if (body.classList.value === "ligth") {
      body.className = "dark-only";
      localStorage.setItem("bodyColor", "dark-only");
    } else {
      body.className = "ligth";
      localStorage.setItem("bodyColor", "ligth");
    }
  }


  async handleMenuClick(key) {
    const { statusWebsocketConnect } = this.state
    const tokenHashLocalStorage = window.localStorage.getItem("tokenHash");
    const tokenLocalStorage = window.localStorage.getItem("token");
    const session = await sessionClient.getSession(tokenHashLocalStorage);
    // session.sendSocket.sendRestartToken([
    //   { restartToken: true, token: tokenLocalStorage },
    // ]); 


    this.setState(
      {
        activeComponent: null,
      },
      () => {
        this.setState({ key: key })
        if (key === "dashboard") {
          this.setState({
            activeComponent: <DashBoard />,
          });
        }

        if (key === "company_ativas") {
          this.setState({
            activeComponent: <Company status={'active'} />,
          });
        }

        if (key === "company_trial") {
          this.setState({
            activeComponent: <Company status={'trial'} />,
          });
        }

        if (key === "company_inativas") {
          this.setState({
            activeComponent: <Company status={'inactive'} />,
          });
        }

        if (key === "company_revendas") {
          this.setState({
            activeComponent: <Company status={'active'} typeView={true} />,
          });
        }

        if (key === "company_revendas_inativas") {
          this.setState({
            activeComponent: <Company status={'inactive'} typeView={true} />,
          });
        }

        if (key === "create_company") {
          this.setState({
            activeComponent: <CreateCompany />,
          });
        }

        if (key === "search") {
          this.setState({
            activeComponent: <Search />,
          });
        }

        if (key === "atendiment_manual") {
          this.setState({
            activeComponent: <AtendimentManual />,
          });
        }

        if (key === "call_center") {
          this.setState({
            activeComponent: <ClientCallCenter statusWebsocketConnect={statusWebsocketConnect} />,
          });
        }

        if (key === "atendiment") {
          this.setState({
            activeComponent: <AtendimentList />,
          });
        }

        if (key === "reports_atendiment") {
          this.setState({
            activeComponent: <ReportsAtendiment />,
          });
        }

        if (key === "mailing") {
          this.setState({
            activeComponent: <Mailing />,
          });
        }

        if (key === "create_mailing") {
          this.setState({
            activeComponent: <CreateMailing />,
          });
        }

        if (key === "list_users") {
          this.setState({
            activeComponent: <ListUser />,
          });
        }

        if (key === "create_user") {
          this.setState({
            activeComponent: <CreateUser />,
          });
        }

        if (key === "company_reports_renovation") {
          this.setState({
            activeComponent: <CompanyRenovation />,
          });
        }

        if (key === "company_reports_trial") {
          this.setState({
            activeComponent: <CompanyTrial />,
          });
        }

        if (key === "create_teams") {
          this.setState({
            activeComponent: <Teams />,
          });
        }

        if (key === "config_teams") {
          this.setState({
            activeComponent: <CreateTeams />,
          });
        }

        if (key === "perfil") {
          this.setState({
            activeComponent: <Perfil />,
          });
        }

        if (key === "api") {
          this.setState({
            activeComponent: <Api />,
          });
        }

        if (key === "ramal") {
          this.setState({
            activeComponent: <RamalOper />,
          });
        }

        if (key === "schedule") {
          this.setState({
            activeComponent: <Agenda />,
          });
        }

        if (key === "config_white_label") {
          this.setState({
            activeComponent: <WhiteLabel />,
          });
        }

        if (key === 'config_coeficientes') {
          this.setState({
            activeComponent: <CoefficientList />,
          });
        }
      }
    );
  }

  render() {
    const { auth, desblockPainel, whitelabelSettings, statusWebsocketConnect } = this.state;
    const permissions = [
      {
        titleText: "Dashboad",
        menukey: "dashboard",
        icon: <IconMonitor />,
        role: [
          "master",
          "whitelabel",
          "revenda",
          "empresa",
          "supervisor",
          "operador",
        ],
      },
      {
        titleText: "Agenda",
        menukey: "schedule",
        icon: <Calendar />,
        role: ["operador"],
      },
      {
        titleText: "Call-Center",
        menukey: "call_center",
        icon: <IconHeadPhone />,
        role: ["operador"],
      },
      {
        titleText: "Atendimento",
        menukey: "atendiment",
        icon: <IconPhone />,
        role: ["operador"],
      },
      {
        titleText: "Atendimento Manual",
        icon: <IconAtendimentManual />,
        role: ["operador"],
        menukey: "atendiment_manual",
      },
      {
        titleText: "Pesquisar",
        icon: <IconSearch />,
        role: ["empresa", "supervisor", "operador"],
        menukey: "search",
      },
      {
        titleText: "Modelos",
        icon: <IconFilePlus />,
        role: ["empresa", "supervisor"],
        children: [
          {
            titleText: "Criar",
            menukey: "create_mailing",
            role: ["empresa", "supervisor"],
          },
          {
            titleText: "Listar",
            menukey: "mailing",
            role: ["empresa", "supervisor"],
          },
        ],
      },
      {
        titleText: "Empresas",
        icon: <IconAward />,
        role: ["master", "whitelabel", "revenda"],
        children: [
          {
            titleText: "Criar",
            menukey: "create_company",
            role: ["master", "whitelabel", "revenda"],
          },
          {
            titleText: "Trial",
            menukey: "company_trial",
            role: ["whitelabel", "revenda"],
          },
          {
            titleText: "Ativas",
            menukey: "company_ativas",
            role: ["master", "whitelabel", "revenda"],
          },
          {
            titleText: "Inativas",
            menukey: "company_inativas",
            role: ["master", "whitelabel", "revenda"],
          },
        ],
      },
      {
        titleText: "Revendas",
        icon: <IconAward />,
        role: ["whitelabel"],
        children: [
          {
            titleText: "Criar",
            menukey: "create_company",
            role: ["master", "whitelabel", "revenda"],
          },
          {
            titleText: "Ativas",
            menukey: "company_revendas",
            role: ["whitelabel"],
          },
          {
            titleText: "Inativas",
            menukey: "company_revendas_inativas",
            role: ["whitelabel"],
          },
        ],
      },
      {
        titleText: "Usuários",
        icon: <IconUser />,
        role: ["master", "empresa", "whitelabel", "revenda", "supervisor"],
        children: [
          {
            titleText: "Criar",
            menukey: "create_user",
            role: ["master", "empresa", "whitelabel", "revenda", "supervisor"],
          },
          {
            titleText: "Listar",
            menukey: "list_users",
            role: ["master", "empresa", "whitelabel", "revenda", "supervisor"],
          },
        ],
      },
      {
        titleText: "Relatórios",
        icon: <IconChart />,
        role: ["whitelabel", "revenda", "empresa", 'supervisor'],
        children: [
          {
            titleText: "Renovações",
            menukey: "company_reports_renovation",
            role: ["master", "whitelabel", "revenda"],
          },
          {
            titleText: "Atendimento",
            menukey: "reports_atendiment",
            role: ["empresa", "supervisor"],
          },
          {
            titleText: "Logs",
            menukey: "",
            role: ["empresa"],
          },
        ],
      },
      {
        titleText: "Configurações",
        icon: <IconConfig />,
        role: ["empresa", "supervisor", "whitelabel"],
        children: [
          {
            titleText: "White Label",
            menukey: "config_white_label",
            role: ["whitelabel"],
          },
          {
            titleText: "Equipes",
            menukey: "create_teams",
            role: ["empresa", "supervisor"],
          },
          {
            titleText: "Criar Equipes",
            menukey: "config_teams",
            role: ["empresa"],
          },
          {
            titleText: "Coeficientes",
            menukey: "config_coeficientes",
            role: ["supervisor"],
          },
        ],
      },
    ];

    return (
      <>
        <Helmet>
          <title>{whitelabelSettings?.name ? whitelabelSettings?.name : 'RVXTECH'} - O maior e mais completo sistema do Brasil</title>
        </Helmet>
        {desblockPainel ? (
          <TeamsNotFound />
        ) : (
          <body id="bodyPainelColor" className={this.state.colorMenu}>
            <div className="tap-top">
              <IconChevronUp />
            </div>
            <div
              className={`page-wrapper null ${this.state.typeMenu}`}
              id="pageWrapper"
            >
              <LeftHeader
                handleMenuClick={(e) => {
                  this.handleMenuClick(e);
                }}
                menuSidebar={(e) => { this.menuSidebar(e) }}
                clickColor={(e) => {
                  this.clickColor(e);
                }}
              />

              <div className="page-body-wrapper">
                <div className="sidebar-wrapper close_icon">
                  <div>
                    <div className="logo-wrapper">
                      <a href="./" data-bs-original-title="" title="">
                        <img
                          className="img-fluid for-light"
                          style={{ width: "40%" }}
                          src={whitelabelSettings?.logo ? whitelabelSettings?.logo : LogoDefaultExtend}
                          alt=""
                        />
                        <img
                          className="img-fluid for-dark"
                          style={{ width: "40%" }}
                          src={whitelabelSettings?.logo ? whitelabelSettings?.logo : LogoDefaultExtend}
                          alt=""
                        />
                      </a>
                      <div style={{ cursor: 'pointer' }} className="back-btn" onClick={(e) => this.menuSidebar(e)}>
                        <i className="fa fa-angle-left"></i>
                      </div>
                      <div
                        className="toggle-sidebar"
                        onClick={(e) => {
                          this.menuSidebar(e);
                        }}
                      >
                        <IconSidebarToggle />
                      </div>
                    </div>
                    <div className="logo-icon-wrapper">
                      <a href="index.html" data-bs-original-title="" title="" >
                        <img className="img-fluid" style={{ width: '30px' }} src={whitelabelSettings?.favicon ? whitelabelSettings?.favicon : LogoDefaultExtend} alt="" />
                      </a>
                    </div>
                    <nav className="sidebar-main">
                      <div id="sidebar-menu">
                        <ul
                          className="sidebar-links"
                          id="simple-bar"
                          data-simplebar="init"
                          style={{ display: "block" }}
                        >
                          <div
                            className="simplebar-wrapper"
                            style={{ margin: "0px" }}
                          >
                            <div className="simplebar-height-auto-observer-wrapper">
                              <div className="simplebar-height-auto-observer"></div>
                            </div>
                            <div className="simplebar-mask">
                              <div
                                className="simplebar-offset"
                                style={{ right: "0px", bottom: "0px" }}
                              >
                                <div
                                  className="simplebar-content-wrapper"
                                  style={{
                                    height: "100%",
                                    overflow: "hidden scroll",
                                  }}
                                >
                                  <div
                                    className="simplebar-content"
                                    style={{ padding: "0px" }}
                                  >
                                    <Sidebar
                                      title="Sidebar"
                                      subtitle="Dashboards &amp; layout."
                                    />

                                    {permissions
                                      ? permissions.map((result) => {
                                        return (
                                          <>
                                            {result.role.includes(
                                              auth.level
                                            ) ? (
                                              <>
                                                <ListMenu
                                                  menukey={result?.menukey}
                                                  onClick={
                                                    result?.menukey
                                                      ? this.handleMenuClick.bind(
                                                        this,
                                                        `${result?.menukey}`
                                                      )
                                                      : ""
                                                  }
                                                  text={result.titleText}
                                                  title={result.titleText}
                                                  svg={result.icon}
                                                >
                                                  {result?.children
                                                    ? result.children.map(
                                                      (chil) => (
                                                        <>
                                                          {chil.role.includes(
                                                            auth.level
                                                          ) ? (
                                                            <Submenu
                                                              menukey={
                                                                chil?.menukey
                                                              }
                                                              onClick={
                                                                chil?.menukey
                                                                  ? this.handleMenuClick.bind(
                                                                    this,
                                                                    chil?.menukey
                                                                  )
                                                                  : ""
                                                              }
                                                            >
                                                              {
                                                                chil.titleText
                                                              }
                                                            </Submenu>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </>
                                                      )
                                                    )
                                                    : ""}
                                                </ListMenu>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        );
                                      })
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="simplebar-placeholder"
                              style={{ width: "auto", height: "3184px" }}
                            ></div>
                          </div>
                          <div
                            className="simplebar-track simplebar-horizontal"
                            style={{ visibility: "hidden " }}
                          >
                            <div
                              className="simplebar - scrollbar"
                              style={{ width: "0px", display: "none" }}
                            ></div>
                          </div>
                          <div
                            className="simplebar-track simplebar-vertical"
                            style={{ visibility: "visible" }}
                          >
                            <div
                              className="simplebar-scrollbar"
                              style={{
                                height: "242px",
                                transform: "translate3d(0px, 518px, 0px)",
                                display: "block",
                              }}
                            ></div>
                          </div>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
                {/* PUXAR COMPOENTES */}
                {this.state.activeComponent}
                {/* PUXAR COMPOENTES */}
                <footer className="footer">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12 footer-copyright text-center">
                        <p className="mb-0">Copyright {moment(new Date()).format('YYYY')} © RVX Tech</p>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </body>
        )}
      </>
    );
  }
}
