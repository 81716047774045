import { Component } from "react";
import ReturnPage from "../../../helpers/ReturnPage";
import Teams from "./teams";
import PageTitle from "../../../helpers/PageTitle";
import { ApiRequest } from '../../../helpers/Api'
import { Form, Input, BtnForm } from "../../../helpers/formData";
import MessageDisplay from "../../../messages/messageDisplay";

export default class EditTeams extends Component {
    constructor(props) {
        super(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            newComponent: ''
        }

        this.submitForm = this.submitForm.bind(this);
    }

    async submitForm(e) {
        e.preventDefault();
        const api = new ApiRequest();
       
        const msg = new MessageDisplay();

        const result = await api.post(`team-update`, e)
        if (result.error === true && result) {
            msg.error('Equipes', result.data)
            return;
        }
        msg.success('Equipes', 'Equipe atualizada com sucesso')
        return;
    }

    returPage = () => {
        this.setState({
            newComponent: <Teams />
        })
    };

    render() {
        const { newComponent } = this.state
        const { data } = this.props
        console.log(data)
        return (
            <>
                {newComponent ? newComponent : (
                    <div className="page-body">
                        <div className="container-fluid">
                            <PageTitle title={`Atualizar Equipe ${data.name}`} nameCompany={"Proconsig"} />
                        </div>
                        <div className="container-fluid">
                            <ReturnPage OnClick={this.returPage} />
                            <div className="edit-profile">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <Form title={'Editar Equipe'} onSubmit={(e) => this.submitForm(e)}>
                                            <Input
                                                classInput={"col-12"}
                                                type={'hidden'}
                                                name={'id'}
                                                valueInput={data._id}
                                                placeholder={"Nome da Equipe"}
                                            />
                                            <Input
                                                classInput={"col-12"}
                                                name={'name'}
                                                valueInput={data.name}
                                                label={'Nome'}
                                                placeholder={"Nome da Equipe"}
                                            />
                                            <BtnForm name={'Atualizar Equipe'} />
                                        </Form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}