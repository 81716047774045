
import { Component } from 'react';

export class BadgeConvenioMailing extends Component {

    getColorBadge = () => {
        const { convenioMailing } = this.props;
        const types = {
            'inss': 'bg-success',
            'seguro_vida': 'bg-info',
            'fgts': 'bg-danger',
            'siape': 'bg-primary',
            'gov': 'bg-dark',
        };
        return types[convenioMailing];
    }

    render() {
        const { convenioMailing } = this.props;
        const classColorBadge = this.getColorBadge();
        return ( 
            <>
                <span className={`badge text-uppercase fw-lighter ${classColorBadge}`}> {convenioMailing} </span>
            </>
        );
    }
}