import React, { Component } from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/not-found-robot.json';

export class NotFoundRobot extends Component{

    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };

        const { height, width } = this.props;
        const heightUsed = height ? height : 280;
        const widthUsed = width ? width : 360;

        return (
            <Lottie 
                options={defaultOptions}
                height={heightUsed}
                width={widthUsed}
                style={{marginTop: '-60px'}}
            />
        )
    }
}