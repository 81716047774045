import React, { Component } from "react";
import { TailSpin } from "react-loader-spinner";

export default class Loading extends Component {
  render() {
    return (
      <div className="ajax_load" style={{ display: "none" }}>
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TailSpin
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
          />
        </div>
      </div>
    );
  }
}
export function startLoad() {
  const load = document.querySelector(".ajax_load");
  load.style.display = "block";
  const formFields = document.querySelectorAll("input, textarea, select");
  formFields.forEach((field) => {
    field.disabled = true;
  });
}

export function stopLoad() {
  const load = document.querySelector(".ajax_load");
  load.style.display = "none";
  const formFields = document.querySelectorAll("input, textarea, select");
  formFields.forEach((field) => {
    if (!field.classList.contains("disabled")) {
      field.removeAttribute("disabled");
    }
  });
}
