import { Component } from "react";
import PageTitle from "../../helpers/PageTitle";
import { BtnForm, Form, Input } from "../../helpers/formData";
import { ApiRequest } from "../../helpers/Api";
import MessageDisplay from "../../messages/messageDisplay";

export default class ApiExtrato extends Component {

    constructor(props) {
        super(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem('user'))
        }
    }

    async submitFormInss(e) {
        const api = new ApiRequest();
        const msg = new MessageDisplay();

        const result = await api.post(`api-create-inss`, e)
        if (result.error === true && result) {
            msg.error('Api', result.data)
            return;
        }
        msg.success('Api', 'Api Atualizada com Sucesso')
        return;
    }

    async submitForFgts(e) {
        const api = new ApiRequest();
        const msg = new MessageDisplay();

        const result = await api.post(`api-create-fgts`, e)
        if (result.error === true && result) {
            msg.error('Api', result.data)
            return;
        }
        msg.success('Api', 'Api Atualizada com Sucesso')
        return;
    }

    async submitForSiape(e) {
        const api = new ApiRequest();
        const msg = new MessageDisplay();

        const result = await api.post(`api-create-siape`, e)
        if (result.error === true && result) {
            msg.error('Api', result.data)
            return;
        }
        msg.success('Api', 'Api Atualizada com Sucesso')
        return;
    }

    render() {
        const { auth } = this.state
        const apis_inss = auth.company_id.apis_inss[0]
        const fgts = auth.company_id.api_fgts
        const siape = auth.company_id.api_siape

        return (<>
            <div className="page-body">
                <div className="container-fluid">
                    <PageTitle title={'Apis Integração'} auth={auth} />
                </div>
                <div className="container-fluid">
                    <div
                        className="container-fluid search-page"
                        style={{ background: "#F4F5FA", padding: "50px" }}
                    >
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="">
                                    <div className="">
                                        <div className="text-center">
                                            <ul
                                                className="nav nav-tabs search-list"
                                                id="top-tab"
                                                role="tablist"
                                                style={{ border: "nome !important" }}
                                            >
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link active"
                                                        id="datasoftcall"
                                                        data-bs-toggle="tab"
                                                        href="#data-softcall"
                                                        role="tab"
                                                        aria-selected="true"
                                                        data-bs-original-title=""
                                                        title=""
                                                    >
                                                        INSS
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        id="dataipbox"
                                                        data-bs-toggle="tab"
                                                        href="#data-ipbox"
                                                        role="tab"
                                                        aria-selected="false"
                                                        data-bs-original-title=""
                                                        title=""
                                                    >
                                                        FGTS
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        id="datamaisvoip"
                                                        data-bs-toggle="tab"
                                                        href="#data-maisvoip"
                                                        role="tab"
                                                        aria-selected="false"
                                                        data-bs-original-title=""
                                                        title=""
                                                    >
                                                        SIAPE
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <br />
                                        <div className="tab-content" id="top-tabContent">
                                            <div
                                                className="search-links tab-pane active fade show"
                                                id="data-softcall"
                                                role="tabpanel"
                                                aria-labelledby="datasoftcall"
                                            >

                                                <div className="edit-profile">
                                                    <Form title={'Api Inss'} onSubmit={(e) => this.submitFormInss(e)}>
                                                        <Input
                                                            label={'Token Offline'}
                                                            classInput={"col-12"}
                                                            valueInput={apis_inss?.offline}
                                                            type={'text'}
                                                            name={'offline'}
                                                            placeholder={"Token de integração"}
                                                        />
                                                        <Input
                                                            label={'Token In100'}
                                                            classInput={"col-12"}
                                                            valueInput={apis_inss?.in100}
                                                            type={'text'}
                                                            name={'in100'}
                                                            placeholder={"Token de integração"}
                                                        />
                                                        <Input
                                                            label={'Token Online'}
                                                            classInput={"col-12"}
                                                            valueInput={apis_inss?.online}
                                                            type={'text'}
                                                            name={'online'}
                                                            placeholder={"Token de integração"}
                                                        />
                                                        <BtnForm name={'Salvar INSS'} />
                                                    </Form>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="tab-content" id="top-tabContent">
                                            <div
                                                className="search-links tab-pane fade show"
                                                id="data-ipbox"
                                                role="tabpanel"
                                                aria-labelledby="dataipbox"
                                            >
                                                <div className="edit-profile">
                                                    <Form title={'Api Inss'} onSubmit={(e) => this.submitForFgts(e)}>
                                                        <Input
                                                            label={'Api FGTS'}
                                                            classInput={"col-12"}
                                                            valueInput={fgts?.offline}
                                                            type={'text'}
                                                            name={'fgts'}
                                                            placeholder={"Url de integração"}
                                                        />
                                                        <BtnForm name={'Salvar Fgts'} />
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-content" id="top-tabContent">
                                            <div
                                                className="search-links tab-pane fade show"
                                                id="data-maisvoip"
                                                role="tabpanel"
                                                aria-labelledby="datamaisvoip"
                                            >
                                                <div className="edit-profile">
                                                    <Form title={'Api Inss'} onSubmit={(e) => this.submitForSiape(e)}>
                                                        <Input
                                                            label={'Api Siape'}
                                                            classInput={"col-12"}
                                                            valueInput={siape?.offline}
                                                            type={'text'}
                                                            name={'siape'}
                                                            placeholder={"Url de integração"}
                                                        />
                                                        <BtnForm name={'Salvar Siape'} />
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>)
    }
}