const removeExtraSpace = (textinput) => {
    if (typeof textinput !== 'string') {
        return '';
    }
    const remove = textinput.replace(/\s+/g, ' ').trim();
    return remove;
}

const countWords = (textinput, maxWordLength) => {
    const removeSpace = removeExtraSpace(textinput);
    if (typeof removeSpace !== 'string') {
        return false
    }
    const words = removeSpace.split(/\s+/).filter(word => word !== '');

    for (const word of words) {
        if (word.length > maxWordLength) {
            return false;
        }
    }

    return words.length;
}


const checkEmail = (textinput) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(textinput)
}

const nameAndSurname = (textinput) => {
    const nameReEx = /^[A-ZÀ-Ú][a-zà-ú]*((\s|-)[A-ZÀ-Ú][a-zà-ú]*)*$/;
    return nameReEx.test(textinput)
}

const checkSpace = (textinput) => {
    const whiteSpaceReEx = /\s/;
    return !whiteSpaceReEx.test(textinput);
}


export { countWords, removeExtraSpace, checkEmail, nameAndSurname, checkSpace };