import { Component } from "react";
import { Form, Input, BtnForm, InputFile } from "../../helpers/formData";
import NoCompany from '../../../assets/images/proconsig/no_login.png'
import MessageDisplay from "../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../messages/load";
import { ApiRequest } from "../../helpers/Api";


export default class DataCompany extends Component {
    constructor(props) {
        super(props)

        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            viewEdit: false,
            logoCompany: ''
        }
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                viewEdit: true
            })
        }, 500)
    }

    handleImageChange(event) {


        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                const base64 = reader.result;
                this.setState({ logoCompany: base64 });
            };

            reader.readAsDataURL(file);
        } else {
            this.setState({ logoCompany: null });
        }
    };


    // async submitForm(e){
    //     const api = new ApiRequest();
    //     const msg = new MessageDisplay();
    //     const result = await api.post(``)
    // }

    submitForm(e) {
        e.preventDefault();
        const { data } = this.props
        const _id = data._id
        const { logoCompany } = this.state
        const msg = new MessageDisplay();

        const form = e.target;

        const formData = new FormData(form);
        const serializedForm = Object.fromEntries(formData.entries());
        serializedForm._id = _id
        serializedForm.logo = data.logo ? data.logo : logoCompany ? logoCompany : ''

        const tokenLocalStorage = localStorage.getItem("token");
        const tokenHash = localStorage.getItem("tokenHash");
        const login = localStorage.getItem("login");

        startLoad();
        fetch(`${process.env.REACT_APP_HTTP}/companie-update/${login}/${tokenHash}`, {
            method: 'PUT',
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                authorization: `Bearer ${tokenLocalStorage}`
            },
            body: JSON.stringify({
                ...serializedForm
            })
        })
            .then((res) => res.json())
            .then(async (res) => {

                if (res.error) {
                    stopLoad();
                    return msg.error("Empresa", res.data);
                }

                stopLoad();
                return msg.success("Empresa", 'Empresa atualizada com sucesso');


            })
            .catch(() => {
                stopLoad();
                msg.error("Server", "Not connect");
            });

        e.preventDefault();
    }

    render() {
        const type_company = [
            {
                key: 'whitelabel',
                name: 'WHITELABEL',
                role: ['master']
            },
            {
                key: 'revenda',
                name: 'REVENDA',
                role: ['whitelabel']
            }
            ,
            {
                key: 'empresa',
                name: 'EMPRESA',
                role: ['revenda', 'whitelabel']
            }

        ]
        const { viewEdit } = this.state
        const { data } = this.props
        const { logo, level, name, cnpj, responsable, phone, email } = data
        const { logoCompany } = this.state
        return (
            <>
                {viewEdit ? (
                    <>
                        {level == 'whitelabel' ? (
                            <div className="col-12 mt-4">
                                <div className="text-center mb-5">
                                    <img src={logo ? logo : logoCompany ? logoCompany : NoCompany} style={logo ? { width: '20%' } : logoCompany ? { width: '20%' } : { width: '5%' }} alt="logo empresa" />
                                </div>
                            </div>
                        ) : ''}


                        <Form title={name} onSubmit={(e) => this.submitForm(e)}>
                            <Input
                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                name={"name"}
                                valueInput={name}
                                label={'Nome Empresa'}
                                type={'text'}
                                placeholder={"Nome da Empresa"}
                            />
                            <Input
                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                valueInput={cnpj}
                                name={"cnpj"}
                                mask={"99.999.999/9999-99"}
                                type={'text'}
                                label={'CNPJ'}
                                placeholder={"Cnpj da empresa"}
                            />
                            <Input
                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                valueInput={responsable}
                                name={"responsable"}
                                type={'text'}
                                label={'Responsável'}
                                placeholder={"Resposável"}
                            />
                            <Input
                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                name={"phone"}
                                valueInput={phone}
                                mask={"(99) 9-9999-9999"}
                                type={'text'}
                                label={'Telephone'}
                                placeholder={"telefone / whatsapp"}
                            />
                            <Input
                                classInput={"col-sm-6 col-md-6 col-xl-4"}
                                valueInput={email}
                                type={'email'}
                                name={"email"}
                                label={'E-mail Válido'}
                                placeholder={"E-mail valído da empresa"}
                            />
                            {level == 'whitelabel' ? (
                                <InputFile
                                    classInput={"col-12"}
                                    name={"logo"}
                                    title={'Logo Empresa'}
                                    onChange={(e) => { this.handleImageChange(e) }}
                                />
                            ) : ''}
                            <BtnForm name={'Atualizar Empresa'} />
                        </Form>

                    </>
                ) : ''}
            </>
        )
    }
}