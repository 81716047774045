import React, { Component } from "react";
import { formatarCEP } from "../../../helpers/FuncHelpers";
export default class DadosCadastrais extends Component {
  render() {
    const { address } = this.props
    return (
      <>

        <div className="col-sm-6 col-xl-6">
          <div className="card">
            <div className="card-header b-l-primary">
              <h5><i className="icon-map-alt"></i> Endereços</h5>
            </div>
            <div className="card-body" style={{ padding: '25px' }}>
              {address && address.length > 0 ? address.map((a, index) => {
                if (index == 0)
                  return (<>
                    <div className="d-flex justify-content-between align-items-center ficha_item">
                      <div className="ficha_item_title">
                        <span>CIDADE</span>
                      </div>
                      <div className="ficha_item_desc">
                        <span>{a.cidade}</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center ficha_item">
                      <div className="ficha_item_title">
                        <span>UF</span>
                      </div>
                      <div className="ficha_item_desc">
                        <span>{a.uf}</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center ficha_item">
                      <div className="ficha_item_title">
                        <span>CEP</span>
                      </div>
                      <div className="ficha_item_desc">
                        <span>{formatarCEP(a.cep)}</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center ficha_item">
                      <div className="ficha_item_title">
                        <span>BAIRRO</span>
                      </div>
                      <div className="ficha_item_desc">
                        <span>{a.bairro}</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center ficha_item">
                      <div className="ficha_item_title">
                        <span>RUA / AV</span>
                      </div>
                      <div className="ficha_item_desc">
                        <span>{a.endereco}</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center ficha_item">
                      <div className="ficha_item_title">
                        <span>NÚMERO / COMPLEMENTO</span>
                      </div>
                      <div className="ficha_item_desc">
                        <span>{a.numero}</span>
                      </div>
                    </div>
                  </>)
              }) : <p style={{ padding: "15px" }}>Desculpe mais esse cliente não possui endereços em nossa base de dados</p>}
            </div>
          </div>
        </div>
      </>
    );
  }
}
