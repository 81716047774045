import React, { Component } from 'react';
import Lottie from 'react-lottie';

export class AnimationLottie extends Component {

    render() {
        const { height, width, fileJsonName } = this.props;
        const heightUsed = height ? height : 300;
        const widthUsed = width ? width : 600;
        
        const fileLottie = require(`../../assets/lotties/${fileJsonName}.json`);
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: fileLottie,
            renderer: 'svg',
        };

        return ( <>
            <Lottie 
                options={defaultOptions}
                height={heightUsed}
                width={widthUsed}
            />
        </> );
    }
}