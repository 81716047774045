import { Component } from "react";
import PageTitle from "../../helpers/PageTitle";
import { Form, Input, BtnForm } from "../../helpers/formData";
import ResultSearch from "./result/result";
import { ApiRequest } from "../../helpers/Api";
import MessageDisplay from "../../messages/messageDisplay";
import Atendiment from "../callcenter/atendiment";
import { startLoad, stopLoad } from "../../messages/load";
import logOut from "../../auth/logout";
import sweetAlert from "../../helpers/sweet";

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: JSON.parse(localStorage.getItem("user")),
      newComponent: "",
      cpf: "",
      beneficio: "",
      clientAtendiment: false,
    };
  }

  async submitForm(e) {
    e.preventDefault();
    const api = new ApiRequest();
    const msg = new MessageDisplay();
    const sweet = new sweetAlert();

    var inputs = document.querySelectorAll(".inputs input");
    var inputsPreenchidos = Array.from(inputs).filter(function (input) {
      return input.value.trim() !== "";
    });

    // Verifica o comprimento do array de inputs preenchidos
    if (inputsPreenchidos.length > 1) {
      msg.error(
        "Pesquisa",
        "Desculpe mais de dois campos estão preenchidos para pesquisa"
      );
    }

    const result = await api.post(`search-client`, e);
    console.log(result)
    if (result.error === true && result) {
      if (result.data == 'block') {
        sweet.warning('Identificamos comportamento suspeito na utilização do sistema portanto sua Empresa foi bloqueada entre em contato com o Administrador', 6000, true)
        setTimeout(() => {
          logOut();
        }, 6000)
      }


      msg.error("Pesquisa", result.data);
      return;
    }

    this.setState({
      newComponent: true,
      clients: result.data,
    });
    msg.success("Pesquisa", "Consulta realizada com sucesso");
    return;
  }

  async getClientAtendiment(e) {
    e.preventDefault();
    const msg = new MessageDisplay();
    startLoad();
    const value = e.target.closest(".btn");
    const data = JSON.parse(value.id);

    setTimeout(() => {
      this.setState({
        clientAtendiment: (
          <Atendiment
            data={data}
          />
        ),
      });
      stopLoad();
    }, 1500);

    msg.success("Atendimento", "Atendimento Aberto com sucesso");
    return;
  }

  render() {
    const { newComponent, cpf, clients, clientAtendiment, beneficio, auth, titleFomr = 'Pesquisar Cliente' } =
      this.state;
    const { disabledTitle = false, fnGetClientAtendiment = false, colSearch = 'col-4', colResult = 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8' } = this.props;
    return (
      <>
        {clientAtendiment ? (
          clientAtendiment
        ) : (
          <>
            <div className="page-body">
              <div className="container-fluid">
                {!disabledTitle && (
                  <>
                    <PageTitle title={"Pesquisar"} auth={auth} />
                  </>
                )}
                <div className="row">
                  <div className={colSearch}>
                    <Form
                      title={titleFomr}
                      onSubmit={(e) => this.submitForm(e)}
                    >
                      <Input
                        classInput={"col-12 inputs"}
                        name={"cpf"}
                        valueInput={cpf}
                        label={"CPF"}
                        mask={"999.999.999-99"}
                        type={"text"}
                      />
                      <Input
                        classInput={"col-12 inputs"}
                        valueInput={beneficio}
                        name={"beneficio"}
                        label={"Benefício"}
                        mask={"999.999.999-9"}
                        type={"text"}
                      />
                      {/* <Input
                        classInput={"col-12 inputs"}
                        name={"matricula"}
                        label={"Matricula"}
                        mask={"999.999.999-9"}
                        type={"text"}
                      />
                      */}
                      <Input
                        classInput={"col-12 inputs"}
                        name={"_id"}
                        label={"Identificador"}
                        type={"text"}
                      />
                      <BtnForm name={"Pesquisar Cliente"} />
                    </Form>
                  </div>
                  {newComponent && !clientAtendiment ? (
                    <ResultSearch
                      data={clients}
                      colResult={colResult}
                      onGetClient={(e) => {
                        if (fnGetClientAtendiment) {
                          fnGetClientAtendiment(e);
                          return;
                        }
                        this.getClientAtendiment(e);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
