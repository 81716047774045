import { Component } from "react";
import { Form, Input, BtnForm } from '../../../../helpers/formData';
import MessageDisplay from "../../../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../../../messages/load";

export default class UpdateMaisVoip extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
        }
    }
    submitForm(e) {
        e.preventDefault();
        const msg = new MessageDisplay();

        const form = e.target;

        const formData = new FormData(form);
        const serializedForm = Object.fromEntries(formData.entries());
        console.log(serializedForm)
        startLoad();

        const tokenLocalStorage = localStorage.getItem("token");
        const tokenHash = localStorage.getItem("tokenHash");
        const login = localStorage.getItem("login");

        fetch(`${process.env.REACT_APP_HTTP}/teams-insert-discadora/${login}/${tokenHash}`, {
            method: 'POST',
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                authorization: `Bearer ${tokenLocalStorage}`
            },
            body: JSON.stringify({
                ...serializedForm
            })
        })
            .then((res) => res.json())
            .then(async (res) => {
                console.log(res)
                stopLoad()

                if (res.error) {
                    stopLoad();
                    return msg.error("Discadora", res.data);
                }
                return msg.success("Discadora", 'Discadora Atualizada com Sucesso');


            })
            .catch((e) => {
                console.log(e)
                stopLoad();
                msg.error("Server", "Not connect");
            });
    }

    render() {
        const { data } = this.props
        const discadora = data.discadoraMaisVoip[0]
        return (
            <>
                <div className="col-xl-12">
                    <Form title={'Discadora Mais Voip'} onSubmit={(e) => this.submitForm(e)}>

                        <Input
                            classInput={"col-sm-6 col-md-6 col-xl-6"}
                            valueInput={'mais_voip'}
                            name={'type_discadora'}
                            type={'hidden'}
                            placeholder={"Url de integração"}
                        />
                        <Input
                            classInput={"col-sm-6 col-md-6 col-xl-6"}
                            valueInput={data?._id}
                            name={'team_id'}
                            type={'hidden'}
                            placeholder={"Url de integração"}
                        />
                        <Input
                            classInput={"col-sm-6 col-md-6 col-xl-6"}
                            label={'URL'}
                            valueInput={discadora?.url}
                            name={'url'}
                            placeholder={"Url de integração"}
                        />
                        <Input
                            classInput={"col-sm-6 col-md-6 col-xl-6"}
                            label={'Lote'}
                            valueInput={discadora?.lote}
                            name={'lote'}
                            placeholder={"lote"}
                        />
                        <Input
                            classInput={"col-sm-6 col-md-4 col-xl-4"}
                            valueInput={discadora?.layout}
                            label={'Layout'}
                            name={'layout'}
                            placeholder={"layout"}
                        />
                        <Input
                            classInput={"col-sm-6 col-md-4 col-xl-4"}
                            label={'User'}
                            valueInput={discadora?.user}
                            name={'user'}
                            placeholder={"user"}
                        />
                        <Input
                            classInput={"col-sm-6 col-md-4 col-xl-4"}
                            label={'Password'}
                            valueInput={discadora?.password}
                            name={'password'}
                            placeholder={"password"}
                        />
                        <BtnForm name={'Update Mais Voip'} />
                    </Form>
                </div>
            </>
        )
    }
}