import { Component } from "react";
import { formatBank, formatDate, formatarValorEmDinheiro } from "../../../helpers/FuncHelpers";

export default class CartaoRMC extends Component {
    render() {
        const { cartaoRmc } = this.props
        return (
            <>

                <div style={{ flexBasis: '100%' }}>
                    <div className=" ficha card">
                        <div className="card-header" style={{ padding: "10px" }}>
                            <h6>CARTÃO RMC</h6>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-border-horizontal">
                                <thead>
                                    <tr style={{ fontSize: '0.775em' }}>
                                        <th scope="col">CONTRATO</th>
                                        <th scope="col">BANCO</th>
                                        <th scope="col">INCLUSÃO</th>
                                        <th scope="col">LIMITE</th>
                                        <th scope="col">VALOR RESERVADO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cartaoRmc && cartaoRmc.length > 0 ? cartaoRmc.map((c) => {
                                        return (<>

                                            <tr style={{ fontSize: '0.775em' }}>
                                                <td>{c?.contrato}</td>
                                                <td>{c?.banco_codigo ? formatBank(c?.banco_codigo) : null} - {c?.banco?.nome}</td>
                                                <td>{c?.dataInicioContrato ? formatDate(c?.dataInicioContrato) : null}</td>
                                                <td>{c?.limiteCartao ? formatarValorEmDinheiro(c?.limiteCartao) : null}</td>
                                                <td>{c?.valorReservado ? formatarValorEmDinheiro(c?.valorReservado) : null}</td>
                                            </tr>

                                        </>)
                                    }) :
                                        <>
                                            <tr scope="col">
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <p>Esse cliente não possui cartão RMC</p>
                                                </td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}