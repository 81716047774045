import React, { Component, useContext } from "react";
import PageTitle from "../../../helpers/PageTitle";
import { Table, Tr, Td } from "../../../helpers/Table";
import { startLoad, stopLoad } from "../../../messages/load";
import MessageDisplay from "../../../messages/messageDisplay";
import ButtonSearch from "../../../helpers/ButtonSearch";
import Modal from 'react-bootstrap/Modal';
import { BtnForm, Form, Input, InputData } from "../../../helpers/formData";
import { formatDate } from "../../../helpers/FuncHelpers";

import { ApiContext, ApiRequest } from '../../../helpers/Api'

export default class CompanyRenovation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            companyList: '',
            newComponent: '',
            show: false,
            id_companyMoldal: '',
            id_cliente: ''
        }
        this.getCompanies = this.getCompanies.bind(this)
        this.handleClick = this.handleClick.bind(this);
        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.submitForm = this.submitForm.bind(this)
    }

    componentDidMount() {
        this.getCompanies();
    }


    handleClose() {
        this.setState({
            show: false
        })
    }

    handleShow(e) {
        console.log('abriu modal')
        const value = e.target.closest('.btn')
        const id = value.id
        console.log(id)
        this.setState({
            show: true,
            id_companyMoldal: id
        })
    }



    async getCompanies(e) {
        const msg = new MessageDisplay();
        const api = new ApiRequest();
        startLoad();
        const result = await api.get(`reports-renvation`)
        if (result.error) {
            msg.error("Empresa", "Acesso Negado");
        }

        stopLoad();
        this.setState({
            companyList: result.data
        })

    }

    async submitForm(e) {
        const msg = new MessageDisplay();
        const api = new ApiRequest();
        const result = await api.post(`companie-update-renovation`, e)
        if (result.error === true) {
            return msg.error('Empresa', result.data)
        }
        this.setState({
            show: false
        })
        msg.success('Empresa', 'Empresa renovada com sucesso')
        await this.getCompanies()
    }


    handleClick(e) {
        const value = e.target.closest(".btn");
        const attr = value.getAttribute('data-info')
    }

    async typeConsult(e) {
        console.log(e)
        if (e === 'active') {
            this.setState({
                typeConsult: 'inactive'
            }, () => this.getCompanies())
        }
        if (e === 'inactive') {
            this.setState({
                typeConsult: 'active'
            }, () => this.getCompanies())
        }
    }

    dataResult(e) {
        if (e !== '') {
            this.setState({
                companyList: e
            })
        }
    }

    render() {
        const { companyList, auth, newComponent, id_companyMoldal } = this.state;

        return (
            <>
                {newComponent ? newComponent : (
                    <div className="page-body">
                        <div className="container-fluid">
                            <PageTitle title={'Relatório de Empresas Renovação'} auth={auth} />
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5>Lista de Empresas</h5>
                                                </div>
                                                <div>
                                                    <ButtonSearch onFunction={this.getCompanies} type={"companies"} onResult={(e) => { this.dataResult(e) }} />
                                                </div>
                                            </div>
                                        </div>
                                        {companyList && companyList.length > 0 ? (


                                            <Table>
                                                <Tr>
                                                    <Td classTd={"text-center"}>
                                                        <p>Nome</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>Resposável</p>
                                                    </Td>
                                                    {auth.level === 'empresa' ? (
                                                        <>
                                                            <Td classTd={"text-center"}>
                                                                <p>Próxima Renovação</p>
                                                            </Td>
                                                            <Td classTd={"text-center"}>
                                                                <p>Usuários</p>
                                                            </Td>
                                                        </>
                                                    ) : ''}
                                                    <Td classTd={"text-center"}>
                                                        <p>Vencimento</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>Próxima Renovação</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>Total Licenças</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>Convênios</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>Ações</p>

                                                    </Td>
                                                </Tr>
                                                {companyList.map((c) => {
                                                    return (<>
                                                        <Tr>
                                                            <Td classTd={"text-center"}>
                                                                <p>{c?.name}</p>
                                                            </Td>
                                                            <Td classTd={"text-center"}>
                                                                <p>{c?.responsable}</p>
                                                            </Td>
                                                            <Td classTd={"text-center"}>
                                                                <p>{c?.level}</p>
                                                            </Td>

                                                            <Td classTd={"text-center"}>
                                                                <p>{formatDate(c.date_renovation)}</p>
                                                            </Td>
                                                            <Td classTd={"text-center"} >
                                                                <p className={`total_license_teste${c._id}`}>{c.total_users_trial ? c.total_users_trial : 0}</p>
                                                            </Td>
                                                            <Td classTd={"text-center"} >
                                                                <p className={`total_license_teste${c._id}`}>{c.total_users}</p>
                                                            </Td>
                                                            <Td classTd={"text-center"}>
                                                                {c.convenios.map ? c.convenios.map((con, index) => {
                                                                    return (
                                                                        <>
                                                                            {con.name}
                                                                            {index !== con.name.length - 1 && ","}
                                                                        </>
                                                                    )
                                                                }) : <p>Nenhum convênio habilitado</p>}
                                                            </Td>
                                                            <Td classTd={"text-center"}>
                                                                <div className="btn-group">
                                                                    <button id={c._id} onClick={(e) => { this.handleShow(e) }}
                                                                        className="btn btn-sm btn-info"
                                                                        title="Extender prazo"
                                                                    >
                                                                        <i className="fa fa-calendar"></i>
                                                                        {/* <i className="fa fa-a"></i> */}
                                                                    </button>
                                                                </div>
                                                            </Td>
                                                        </Tr >
                                                    </>)
                                                })}
                                            </Table>
                                        ) : <p className="text-center mt-3">Você ainda não possui empresas cadastrados</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                )
                }




                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    size="lg"
                    style={{ zIndex: '9998' }}
                >

                    <Modal.Header closeButton>
                        <Modal.Title>Renovar Empresa</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(e) => this.submitForm(e)}>
                            <Input
                                type={'text'}
                                name={'id'}
                                valueInput={id_companyMoldal}
                            />
                            <InputData
                                classInput={"col-12"}
                                name={"date_renovation"}
                                label={'Data da Próxima Renovação'}
                            />
                            <BtnForm name={'Atualizar Renovação'} />
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}