import React, { Component, useContext } from "react";
import PageTitle from "../../../helpers/PageTitle";
import { Table, Tr, Td } from "../../../helpers/Table";
import { startLoad, stopLoad } from "../../../messages/load";
import MessageDisplay from "../../../messages/messageDisplay";
import ButtonSearch from "../../../helpers/ButtonSearch";
import Modal from 'react-bootstrap/Modal';
import { BtnForm, Form, Input } from "../../../helpers/formData";
import { formatDate } from "../../../helpers/FuncHelpers";

import { ApiContext, ApiRequest } from '../../../helpers/Api'

export default class CompanyTrial extends Component {
    constructor(props) {
        super(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            companyList: '',
            newComponent: '',
            show: false,
            question: false,
            id_companyMoldal: '',
            id_cliente: ''
        }
        this.getCompanies = this.getCompanies.bind(this)
        this.handleClick = this.handleClick.bind(this);
        this.changeCompany = this.changeCompany.bind(this);
        this.setUpdateStatusCompany = this.setUpdateStatusCompany.bind(this);
        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleCloseQuestion = this.handleCloseQuestion.bind(this)
        this.extendTrialModal = this.extendTrialModal.bind(this)
        this.extendTrial = this.extendTrial.bind(this)
    }

    componentDidMount() {
        this.getCompanies();
    }


    handleClose() {
        this.setState({
            show: false
        })
    }

    handleShow(e) {
        const value = e.target.closest('.license_test')
        const id = value.id
        this.setState({
            show: true,
            id_companyMoldal: id
        })
    }


    async submitLicensesTest(e) {
        const msg = new MessageDisplay();
        const api = new ApiRequest();

        const result = await api.post(`add-decrase-license-test`, e)


        if (result.error === true && result) {
            msg.error('Equipes', result.data)
            return;
        }
        this.updateTotalUser(result.data)

        msg.success('Empresas', 'Usuários testes atualizados com sucesso')
        return;
    }




    // submitLicensesTest(e) {
    //     e.preventDefault();

    //     // Restante do código de envio do formulário...
    //     const msg = new MessageDisplay();

    //     const form = e.target;
    //     const formData = new FormData(form);
    //     const serializedForm = Object.fromEntries(formData.entries());
    //     serializedForm._id = this.state.id_companyMoldal

    //     const tokenLocalStorage = window.localStorage.getItem("token");
    //     const tokenHash = window.localStorage.getItem("tokenHash");
    //     const login = window.localStorage.getItem("login");
    //     console.log(serializedForm)
    //     startLoad();

    //     fetch(`${process.env.REACT_APP_HTTP}/add-decrase-license-test/${login}/${tokenHash}`, {
    //         method: "POST",
    //         crossDomain: true,
    //         headers: { "Content-Type": "application/json", "Accept": "application/json", authorization: `Bearer ${tokenLocalStorage}` },

    //         body: JSON.stringify({
    //             ...serializedForm
    //         }),
    //     })
    //         .then((res) => res.json())
    //         .then(async (res) => {

    //             if (res.error) {
    //                 stopLoad();
    //                 return msg.error("Empresa", res.data);
    //             }

    //             stopLoad();
    //             this.updateTotalUser(res.data)


    //         })
    //         .catch(() => {
    //             stopLoad();
    //             msg.error("Server", "Not connect");
    //         });
    // }


    getCompanies() {
        const msg = new MessageDisplay();
        // Restante do código de envio do formulário...
        startLoad();
        const tokenLocalStorage = window.localStorage.getItem("token");
        const tokenHash = window.localStorage.getItem("tokenHash");
        const login = window.localStorage.getItem("login");

        fetch(`${process.env.REACT_APP_HTTP}/reports-trial/${login}/${tokenHash}`, {
            method: "GET",
            crossDomain: true,
            headers: { "Content-Type": "application/json", "Accept": "application/json", authorization: `Bearer ${tokenLocalStorage}` },
        })
            .then((res) => res.json())
            .then(async (res) => {

                if (res.error) {
                    msg.error("Empresa", "Acesso Negado");
                }

                stopLoad();
                this.setState({
                    companyList: res.data
                })

            })
            .catch(() => {
                stopLoad();
                msg.error("Server", "Not connect");
            });
    }

    updateTotalUser(data) {
        console.log(data)
        const find = document.getElementsByClassName(`total_license_teste${data._id}`)
        console.log(find[0])
        find[0].innerHTML = data.total_users_trial
    }


    changeCompany(e) {
        startLoad();
        // Restante do código de envio do formulário...
        const msg = new MessageDisplay();

        const tokenLocalStorage = window.localStorage.getItem("token");
        const tokenHash = window.localStorage.getItem("tokenHash");
        const login = window.localStorage.getItem("login");

        fetch(`${process.env.REACT_APP_HTTP}/companie-change-status/${e._id}/${login}/${tokenHash}`, {
            method: "GET",
            crossDomain: true,
            headers: { "Content-Type": "application/json", "Accept": "application/json", authorization: `Bearer ${tokenLocalStorage}` }
        })
            .then((res) => res.json())
            .then(async (res) => {
                stopLoad();

                if (res.error) {
                    return msg.error("Empresa", res.data);
                }

                if (res.error === false) {
                    this.setUpdateStatusCompany(res.data)
                    return msg.success("Empresa", 'Empresa atualizada com sucesso');
                }


            })
            .catch(() => {
                stopLoad();
                msg.error("Server", "Not connect");
            });
    }

    setUpdateStatusCompany(company) {
        const IdCompany = document.getElementsByClassName(`findStatus${company._id}`)
        const icon = IdCompany[0].children[0]
        if (company.status === 'active') {
            IdCompany[0].className = `findStatus${company._id} btn btn-success`
            IdCompany[0].title = 'Desativar Empresa'
            icon.className = 'fa fa fa-check'
        } else {
            IdCompany[0].className = `findStatus${company._id} btn btn-danger`
            IdCompany[0].title = 'Ativar Empresa'
            icon.className = 'fa fa fa-close'
        }

    }

    extendTrialModal(e) {
        console.log(e)
        this.setState({
            question: true,
            id_cliente: e
        })
    }

    handleCloseQuestion() {
        this.setState({
            question: false
        })
    }

    async extendTrial() {
        const api = new ApiRequest();
        const { id_cliente } = this.state

        const msg = new MessageDisplay();
        // Restante do código de envio do formulário...

        try {
            startLoad();
            const result = await api.put(`extend-trial/${id_cliente}`, { id_cliente }, id_cliente)
            console.log(result)
            if (result) {
                stopLoad();
                this.handleCloseQuestion()
                if (result.error === true) {
                    return msg.error("Empresa", result.data);
                }
                await this.getCompanies();
                return msg.success("Empresa", result.data);
            }
        } catch (err) {
            console.log('erro')
            stopLoad();
            msg.error("Server", "Not connect");
        }

    }


    handleClick(e) {
        const value = e.target.closest(".btn");
        const attr = value.getAttribute('data-info')
    }

    async typeConsult(e) {
        console.log(e)
        if (e === 'active') {
            this.setState({
                typeConsult: 'inactive'
            }, () => this.getCompanies())
        }
        if (e === 'inactive') {
            this.setState({
                typeConsult: 'active'
            }, () => this.getCompanies())
        }
    }

    dataResult(e) {
        console.log('asdfasdfasfasd', e)
        if (e !== '') {
            this.setState({
                companyList: e
            })
        }
    }

    render() {
        const { companyList, auth, newComponent, id_companyMoldal } = this.state;


        return (
            <>
                {newComponent ? newComponent : (
                    <div className="page-body">
                        <div className="container-fluid">
                            <PageTitle title={'Relatório Empresas Trial'} auth={auth} />
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5>Lista de Empresas</h5>
                                                </div>
                                                <div>
                                                    <ButtonSearch onFunction={this.getCompanies} type={"companies"} onResult={(e) => { this.dataResult(e) }} />
                                                </div>
                                            </div>
                                        </div>
                                        {companyList && companyList.length > 0 ? (


                                            <Table>
                                                <Tr>
                                                    <Td classTd={"text-center"}>
                                                        <p>Nome</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>Resposável</p>
                                                    </Td>
                                                    {auth.level === 'empresa' ? (
                                                        <>
                                                            <Td classTd={"text-center"}>
                                                                <p>Próxima Renovação</p>
                                                            </Td>
                                                            <Td classTd={"text-center"}>
                                                                <p>Usuários</p>
                                                            </Td>
                                                        </>
                                                    ) : ''}
                                                    <Td classTd={"text-center"}>
                                                        <p>Vencimento do Teste</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>Total Licenças Teste</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>Convênios</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>Ações</p>

                                                    </Td>
                                                </Tr>
                                                {companyList.map((c) => {
                                                    return (<>
                                                        <Tr>
                                                            <Td classTd={"text-center"}>
                                                                <p>{c?.name}</p>
                                                            </Td>
                                                            <Td classTd={"text-center"}>
                                                                <p>{c?.responsable}</p>
                                                            </Td>
                                                            {auth.level === 'empresa' ? (
                                                                <>
                                                                    <Td classTd={"text-center"}>
                                                                        <p>{c?.date_renovation}</p>
                                                                    </Td>
                                                                    <Td classTd={"text-center"}>
                                                                        <p>{c?.total_users}</p>
                                                                    </Td>
                                                                </>
                                                            ) : ''}
                                                            <Td classTd={"text-center"}>
                                                                <p>{formatDate(c.date_trial)}</p>
                                                            </Td>
                                                            <Td classTd={"text-center"} >
                                                                <p className={`total_license_teste${c._id}`}>{c.total_users_trial ? c.total_users_trial : 0}</p>
                                                            </Td>
                                                            <Td classTd={"text-center"}>
                                                                {c.convenios.map ? c.convenios.map((con, index) => {
                                                                    return (
                                                                        <>
                                                                            {con.name}
                                                                            {index !== con.name.length - 1 && ","}
                                                                        </>
                                                                    )
                                                                }) : <p>Nenhum convênio habilitado</p>}
                                                            </Td>
                                                            <Td classTd={"text-center"}>
                                                                <div className="btn-group">
                                                                    <button onClick={this.handleShow} id={c._id} data-info={'edit'} className="btn btn-primary license_test" data-bs-original-title="" title="Adicionar Licenças Teste"><i className="fa fa-plus-square-o"></i></button>
                                                                    <button
                                                                        className={`findStatus${c._id} btn btn-${c.status === 'active' ? 'success' : 'danger'}`}
                                                                        data-bs-original-title=""
                                                                        title={`${c.status === 'active' ? 'Desativar Empresa' : 'Ativar Empresa'}`}
                                                                        onClick={() => this.changeCompany(c)}
                                                                    >
                                                                        <i id='filho' className={`fa fa fa-${c.status === 'active' ? 'check' : 'close'} `}></i>
                                                                    </button>

                                                                    <button
                                                                        className="btn btn-warning"
                                                                        title="Extender Trial"
                                                                        onClick={() => this.extendTrialModal(c._id)}
                                                                    >
                                                                        <i className="fa fa-calendar"></i>
                                                                        {/* <i className="fa fa-a"></i> */}
                                                                    </button>
                                                                </div>
                                                            </Td>
                                                        </Tr >
                                                    </>)
                                                })}
                                            </Table>
                                        ) : <p className="text-center mt-3">Você ainda não possui empresas cadastrados</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                )
                }


                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    size="lg"
                    style={{ zIndex: '9998' }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Adicionar Licenças Teste</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Você possui <b className="">{auth.company_id.total_users_trial}</b> licenças teste</p>
                        <Form onSubmit={(e) => this.submitLicensesTest(e)}>
                            <Input
                                classInput={"col-12"}
                                name={'_id'}
                                type={'hidden'}
                                valueInput={id_companyMoldal}
                            />
                            <Input
                                classInput={"col-12"}
                                // valueInput={cnpj}
                                name={"total"}
                                type={'number'}
                                label={'Total'}
                                placeholder={"Adicionar Licenças Teste"}
                            />
                            <BtnForm name={'Adicionar Licenças'} />
                        </Form>

                    </Modal.Body>
                </Modal>



                <Modal
                    show={this.state.question}
                    onHide={this.handleCloseQuestion}
                    size="xs"
                    style={{ zIndex: '9998' }}
                >

                    <Modal.Header closeButton>
                        <Modal.Title>Prolongar Licenças trial</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Essa ação fará com que seu cliente tenha mais <b>3 dias</b> de teste</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-sm btn-success" onClick={() => this.extendTrial()}>Sim</button>
                        <button className="btn btn-sm btn-danger">Não</button>
                    </Modal.Footer>

                </Modal>
            </>
        )
    }
}