import { Component } from "react";
import PageTitle from "../../../helpers/PageTitle";
import {
  Form,
  Input,
  BtnNext,
  BtnForm,
} from "../../../helpers/formData";
import ReturnPage from "../../../helpers/ReturnPage";
import CreateMailing from "../create";
import MessageDisplay from "../../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../../messages/load";
import Mailing from "../mailing";

import DataMailing from "./data_mailing/data_mailing";
import CaracteristicasInss from "./inss_components/caracteristicas";
import MargemInss from "./inss_components/margem";
import CartaoRmcInss from "./inss_components/cartao_rmc";
import CartaoBeneficioInss from "./inss_components/cartao_beneficio";
import PortabilidadeInss from "./inss_components/portabilidade";
import BancosInss from "./inss_components/bancos";
import BeneficiosInss from "./inss_components/beneficios";
import Teams from "../../config/teams/teams";
window.portList = [];

export default class CreateMailingInss extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      auth: localStorage.getItem("user"),
      newComponent: "",
      step_limit: 8,
      type_mailing: "",
      step: 0,
      joao: false,
      showCadastra: 0,
      componentes: [],
      componentesList: [
        {
          id: "todos",
          type: "Dados do Mailing",
          icon: "fa fa-home",
          componemt: <DataMailing />,
          view: false,
          data: [
            "todos",
            "caracteristicas",
            "margem",
            "cartao",
            "cartao_beneficio",
            "portabilidade",
            "bancos",
            "beneficios",
          ],
        },
        {
          id: "caracteristicas",
          type: "Caracteristicas Inss",
          icon: "fa fa-user",
          componemt: <CaracteristicasInss />,
          view: false,
        },
        {
          id: "margem",
          type: "Margem",
          icon: "fa fa-money",
          componemt: <MargemInss />,
          view: false,
          data: [
            "todos",
            "caracteristicas",
            "margem",
            "bancos",
            "beneficios",
          ],
        },
        {
          id: "cartao",
          type: "Cartão RMC",
          icon: "fa fa-shopping-cart",
          componemt: <CartaoRmcInss />,
          view: false,
          data: [
            "todos",
            "caracteristicas",
            "cartao",
            "bancos",
            "beneficios",
          ],
        },
        {
          id: "cartao_beneficio",
          type: "Cartão BENEFÍCIO",
          icon: "fa fa-send-o",
          componemt: <CartaoBeneficioInss />,
          view: false,
          data: [
            "todos",
            "caracteristicas",
            "cartao_beneficio",
            "bancos",
            "beneficios",
          ],
        },
        {
          id: "portabilidade",
          type: "Portabilidade / Refin",
          icon: "fa fa-bolt",
          componemt: <PortabilidadeInss />,
          view: false,
          data: [
            "todos",
            "caracteristicas",
            "portabilidade",
            "bancos",
            "beneficios",
          ],
        },
        {
          id: "bancos",
          type: "Bancos (Onde o cliente recebe)",
          icon: "fa fa-bank",
          componemt: <BancosInss />,
          view: false,
        },
        {
          id: "beneficios",
          type: "Benefícios",
          icon: "fa fa-gavel",
          componemt: <BeneficiosInss />,
          view: false,
        },
      ],
      showModal: false,
      teams: {}
    };
    this.submitForm = this.submitForm.bind(this);
    this.redirectRegioes = this.redirectRegioes.bind(this);
  }

  async componentDidMount() {
    const { componentesList } = this.state;
    const stepCount = document.querySelectorAll(".count-step");
    this.setState({
      componentes: componentesList,
      listWidthStep: 100 / stepCount.length,
    });
  }

  next(id) {
    const requiredFields = document.querySelectorAll(".required");
    console.log(requiredFields);
    let next = true;

    for (const field of requiredFields) {
      if (!field.value) {
        field.classList.add("alert_required");
        next = false;
      } else {
        next = true;
        field.classList.remove("alert_required");
      }
    }
    if (next) {
      const { step_limit } = this.state;
      if (step_limit === id + 1) {
        this.setState({ showCadastra: 1, step: id });
      } else {
        this.setState({ step: id });
      }

      if (id + 1 < step_limit) {
        this.setState({ showCadastra: 0 });
      }
    }
  }

  async selectTypeMailing(e) {
    const value = e.target.value;
    const { componentesList } = this.state;

    this.setState(
      {
        componentes: componentesList,
      },
      () => {
        const { componentes } = this.state;

        const result = [];
        const filteredItems = componentes.filter((item) => item.id === value);

        filteredItems.forEach((item) => {
          const data = item.data;
          componentes.forEach((e) => {
            if (data.includes(e.id)) {
              e.view = true;
              result.push(e);
            }
          });
        });

        this.setState({
          componentes: result,
          type_mailing: value,
        });
      }
    );

    setTimeout(() => {
      const stepCount = document.querySelectorAll(".count-step");
      this.setState({
        listWidthStep: 100 / stepCount.length,
        btnProx: true,
      });
    }, 50);
  }

  submitForm(e) {
    e.preventDefault();
    const msg = new MessageDisplay();

    const form = e.target;
    const formData = new FormData(form);
    const serializedForm = Object.fromEntries(formData.entries());
    serializedForm.port = window.portList;
    serializedForm.considerarBeneficios = window.considerarBeneficios;
    serializedForm.desconsiderarBeneficios = window.desconsiderarBeneficios;
    serializedForm.considerarBancos = window.considerarBancos;
    serializedForm.desconsiderarBancos = window.desconsiderarBancos;
    startLoad();
    const tokenLocalStorage = localStorage.getItem("token");
    const tokenHash = localStorage.getItem("tokenHash");
    const login = localStorage.getItem("login");

    fetch(
      `${process.env.REACT_APP_HTTP}/create-mailing-inss/${login}/${tokenHash}`,
      {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${tokenLocalStorage}`,
        },
        body: JSON.stringify({
          ...serializedForm,
        }),
      }
    )
      .then((res) => res.json())
      .then(async (res) => {
        if (res.error) {
          stopLoad();
          return msg.error("Mailing", res.data);
        }

        stopLoad();
        this.setState({
          newComponent: <Mailing />,
        });
        return msg.success("Mailing", "Mailing Criado com sucesso");
      })
      .catch(() => {
        stopLoad();
        msg.error("Server", "Not connect");
      });

    e.preventDefault();
  }

  redirectRegioes() {
    this.setState({
      newComponent: <Teams />,
    })
  }

  returPage = () => {
    this.setState({
      newComponent: <CreateMailing />,
    });
  };

  render() {
    const {
      step,
      newComponent,
      listWidthStep,
      showCadastra,
      componentes,
      btnProx,
      type_mailing,
    } = this.state;

    const isHidden = step == 0;

    return (
      <>
        <>
          {newComponent ? (
            newComponent
          ) : (
            <>
              <div className="page-body">
                <div className="container-fluid">
                  <PageTitle title={"Mailing Inss"} />
                </div>

                <div className="container-fluid">
                  <div className="d-flex justify-content-between align-items-center">
                    <div style={{ width: "50%" }}>
                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.selectTypeMailing(e);
                        }}
                      >
                        <option>SELECIONE O TIPO DE MAILING</option>
                        <option value={"margem"}>----- MARGEM -----</option>
                        <option value={"cartao"}>----- CARTÃO -----</option>
                        <option value={"portabilidade"}>
                          ----- PORTABILIADDE -----
                        </option>
                        <option value={"cartao_beneficio"}>
                          ----- CARTÃO BENEFÍCIO -----
                        </option>
                        <option value={"todos"}>
                          ----- TODOS (ACIMA) -----
                        </option>
                      </select>
                    </div>

                    <div>
                      <ReturnPage OnClick={this.returPage} />
                    </div>
                  </div>
                  <br />
                  <div className="edit-profile">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="f1">
                          <div className="f1-steps">
                            <div className="f1-progress">
                              <div
                                className="f1-progress-line"
                                data-now-value="16.66"
                                data-number-of-steps="3"
                              ></div>
                            </div>
                            <>
                              {componentes
                                ? componentes.map((e, index) => {
                                    const itemNumber = index - 1;

                                    return (
                                      <>
                                        {e.view ? (
                                          <div
                                            className={`f1-step count-step ${
                                              step > itemNumber ? "active" : ""
                                            }`}
                                            style={{
                                              width: listWidthStep + "%",
                                            }}
                                          >
                                            <div className="f1-step-icon">
                                              <i className={e.icon}></i>
                                            </div>
                                            <p>{e.type}</p>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    );
                                  })
                                : ""}
                            </>
                          </div>
                        </div>
                        <Form
                          title={
                            <>
                              {"Mailing para (INSS)"}
                              <button
                                className="btn btn-primary ms-auto"
                                type="button"
                                onClick={this.redirectRegioes}
                              >
                                Cadastro De Regiões
                              </button>
                            </>
                          }
                          onSubmit={(e) => this.submitForm(e)}
                          row={false}
                        >
                          <Input
                            type={"hidden"}
                            valueInput={type_mailing}
                            classInput={"col-sm-6 col-md-6 col-xl-6"}
                            name={"type"}
                            placeholder={"Tipo Mailing"}
                          />

                          {componentes
                            ? componentes.map((e, index) => {
                                return (
                                  <>
                                    {e.view ? (
                                      <div
                                        className="row"
                                        style={{
                                          display: step === index ? "" : "none",
                                        }}
                                      >
                                        {e.componemt}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })
                            : ""}

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <BtnNext
                              className="btn btn-primary btn-previous"
                              onClick={() => this.next(step - 1)}
                              isHidden={isHidden}
                              name="Voltar"
                            />
                            &nbsp;
                            {showCadastra === 1 && <></>}
                            {step === componentes.length - 1 ? (
                              <BtnForm name={"Cadastrar Modelo"} />
                            ) : (
                              ""
                            )}
                            {step < componentes.length - 1 && btnProx ? (
                              <BtnNext
                                isHidden={showCadastra}
                                onClick={() => this.next(step + 1)}
                                name="Proximo"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      </>
    );
  }
}
