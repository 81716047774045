import { Component } from "react";
import PageTitle from "../../../helpers/PageTitle";
import { Table, Tr, Td } from "../../../helpers/Table";
import Discador from "../discador/discador";
import CreateRegioes from "../regioes/regioes";
import MessageDisplay from "../../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../../messages/load";
import EditTeams from "./edit";
import { ApiRequest } from "../../../helpers/Api";
import sweetAlert from "../../../helpers/sweet";


export default class Teams extends Component {
    constructor(props) {
        super(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            newComponent: '',
            teamsList: false
        }
        this.getTeams = this.getTeams.bind(this)
    }
    componentDidMount() {
        this.getTeams()
    }

    async getTeams() {
        const { auth } = this.state;
        const msg = new MessageDisplay();
        // Restante do código de envio do formulário...
        startLoad();
        const tokenLocalStorage = window.localStorage.getItem("token");
        const tokenHash = window.localStorage.getItem("tokenHash");
        const login = window.localStorage.getItem("login");

        const queryParams = new URLSearchParams(auth).toString();


        fetch(`${process.env.REACT_APP_HTTP}/teams-get-all/${login}/${tokenHash}`, {
            method: "GET",
            crossDomain: true,
            headers: { "Content-Type": "application/json", "Accept": "application/json", authorization: `Bearer ${tokenLocalStorage}` },
        })
            .then((res) => res.json())
            .then(async (res) => {

                if (res.error) {
                    msg.error("Equipes", "Acesso Negado");
                }
                console.log(res)

                stopLoad();
                this.setState({
                    teamsList: res.data
                })

            })
            .catch(() => {
                stopLoad();
                msg.error("Server", "Not connect");
            });
    }

    async selectComponent(e) {
        const value = e.target.closest('.btn')
        const key = value.getAttribute('data-type')
        const teams = JSON.parse(value.getAttribute('data-teams'))
        if (key == 'discador') {
            this.setState({
                newComponent: <Discador data={teams} />
            })
        }

        if (key == 'regioes') {
            this.setState({
                newComponent: <CreateRegioes data={teams} />
            })
        }

        if (key == 'edit') {
            console.log(teams)
            this.setState({
                newComponent: <EditTeams data={teams} />
            })
        }

        if (key == 'deletar') {
            const api = new ApiRequest();
            const msg = new MessageDisplay();
            const postData = {
                id: value.id
            }
            const result = await api.post(`team-delete`, postData, false)
            console.log(result)
            if (result.info === true) {
                const sweet = new sweetAlert();
                let usersList = new Array();
                for (const u of result.data) {
                    usersList.push(u.login)
                }
                sweet.info(`Desculpe mais o seguintes usuários pertencem a essa equipe <b>${usersList}</b> para deletar é necessário remove-los antes da equipe`)
                return;
            }

            msg.success('Equipes', 'Equipe deletada com sucesso')
            await this.getTeams();
            return;
        }

    }

    selectMailing(e) {

    }

    render() {

        const { newComponent, teamsList,auth } = this.state


        return (<>

            {newComponent ? newComponent : (
                <div className="page-body">
                    <PageTitle title={"Equipes"} auth={auth} />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h5>Selecione sua Equipe</h5>
                                    </div>
                                    <Table>
                                        {teamsList ? teamsList.map(t => {
                                            console.log(t)
                                            return (<>
                                                <Tr>
                                                    <Td classTd={"text-center"}>
                                                        <p>{t._id}</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <p>{t.name}</p>
                                                    </Td>
                                                    <Td classTd={"text-center"}>
                                                        <div className="btn-group">
                                                            <button data-teams={JSON.stringify(t)} data-type='edit' onClick={(e) => this.selectComponent(e)} className="btn btn-secondary" data-bs-original-title="" title="Editar Equipe">
                                                                <i className="fa fa fa-edit"></i>
                                                            </button>
                                                            <button data-teams={JSON.stringify(t)} data-type='discador' onClick={(e) => this.selectComponent(e)} className="btn btn-primary" data-bs-original-title="" title="Parametrização Discadora">
                                                                <i className="fa fa-headphones"></i>
                                                            </button>
                                                            <button data-teams={JSON.stringify(t)} data-type='regioes' onClick={(e) => this.selectComponent(e)} className="btn btn-success" data-bs-original-title="" title="Cadastro de Rigões">
                                                                <i className="fa fa-map-marker"></i>
                                                            </button>
                                                            <button id={t._id} data-type='deletar' onClick={(e) => this.selectComponent(e)} className="btn btn-danger" data-bs-original-title="" title="Deletar Equipe">
                                                                <i className="fa fa fa-close"></i>
                                                            </button>
                                                            {/* <button className="btn btn-success" data-bs-original-title="" title="Ativar Usuário">
                                                        <i className="fa fa fa-check"></i>
                                                    </button> */}
                                                        </div>
                                                    </Td>


                                                </Tr >
                                            </>)
                                        }) : ''}
                                    </Table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
        )
    }
}