import { useEffect, useState } from "react";
import PageTitle from "../../../helpers/PageTitle"
import ReturnPage from "../../../helpers/ReturnPage"
import { BtnForm, BtnNext, Form, Input } from "../../../helpers/formData";
import DataMailing from "./data_mailing/data_mailing";
import DadosClientSeguroVida from "./seguro_vida_components/dados_clients";
import DadosBancosSeguroVida from "./seguro_vida_components/dados_banco";
import { ApiRequest } from "../../../helpers/Api";
import MessageDisplay from "../../../messages/messageDisplay";
import Mailing from "../mailing";

const CreateMailingSeguros = () => {

    const [step, setStep] = useState(0)
    const [listWidthStep, setListWidthStep] = useState(0)
    const [showCadastra, setShowCadastra] = useState(0)
    const [newCom, setNewCom] = useState(false)


    const compoNentesList = [
        {
            type: "Dados do Mailing",
            icon: "fa fa-home",
            componemt: <DataMailing />,
        },
        {
            type: "Dados Cliente",
            icon: "fa fa-home",
            componemt: <DadosClientSeguroVida />,
        },
        {
            type: "Bancos Recebimento",
            icon: "fa fa-home",
            componemt: <DadosBancosSeguroVida />,
        },
    ];


    const countStepsWidth = () => {
        setListWidthStep((100 / compoNentesList.length))
    }

    const next = (id) => {
        const requiredFields = document.querySelectorAll(".required");
        let next = true;

        for (const field of requiredFields) {
            if (!field.value) {
                field.classList.add("alert_required");
                next = false;
            } else {
                next = true;
                field.classList.remove("alert_required");
            }
        }
        if (next) {
            const step_limit = 0;
            if (step_limit === id + 1) {
                setShowCadastra(1);
                setStep(id);
            } else {
                setStep(id);
            }

            if (id + 1 < step_limit) {
                setShowCadastra(0);
            }
        }
    }

    const submitForm = (e) => {
        const api = new ApiRequest();
        const msg = new MessageDisplay();
        const postData = {
            considerar_bancos: window.considerarBancos,
            desconsiderar_bancos: window.desconsiderar_bancos
        }
        const result = api.post(`create-modelo-seguro-vida`, e, true, postData)
        if (result.error === true) {
            return msg.error('Modelo', result.data)
        }
        setNewCom(true)


    }

    const isHidden = step == 0;

    useEffect(() => {
        countStepsWidth();
    }, [])

    if (newCom) {
        return <Mailing />
    }

    return (
        <>

            <div className="page-body">
                <div className="container-fluid">
                    <PageTitle title={"Mailing Seguros"} />
                </div>
                <div className="container-fluid">
                    <div className="d-flex justify-content-between align-items-center">
                        <ReturnPage OnClick={() => { setNewCom(true) }} />
                    </div>

                    <div className="edit-profile">
                        <div className="row">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="f1">
                                        <div className="f1-steps">
                                            <div className="f1-progress">
                                                <div
                                                    className="f1-progress-line"
                                                    data-now-value="16.66"
                                                    data-number-of-steps="3"
                                                ></div>
                                            </div>
                                            <div className="col-xl-12">
                                                {compoNentesList && compoNentesList.map((e, index) => {
                                                    const itemNumber = index - 1;
                                                    return (
                                                        <>
                                                            <div
                                                                className={`f1-step count-step ${step > itemNumber ? "active" : ""
                                                                    }`}
                                                                style={{
                                                                    width: listWidthStep + "%",
                                                                }}
                                                            >
                                                                <div className="f1-step-icon">
                                                                    <i className={e.icon}></i>
                                                                </div>
                                                                <p>{e.type}</p>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    <Form
                                        title={
                                            <>
                                                {"Mailing para (SEGUROS VIDA)"}
                                                <button
                                                    className="btn btn-primary ms-auto"
                                                    type="button"
                                                >
                                                    Cadastro De Regiões
                                                </button>
                                            </>
                                        }
                                        onSubmit={(e) => submitForm(e)}
                                        row={false}
                                    >
                                        {compoNentesList
                                            ? compoNentesList.map((e, index) => {
                                                return (
                                                    <>
                                                        <div
                                                            className="row"
                                                            style={{
                                                                display: step === index ? "" : "none",
                                                            }}
                                                        >
                                                            {e.componemt}
                                                        </div>
                                                    </>
                                                );
                                            })
                                            : ""}

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <BtnNext
                                                className="btn btn-primary btn-previous"
                                                onClick={() => next(step - 1)}
                                                isHidden={isHidden}
                                                name="Voltar"
                                            />
                                            &nbsp;
                                            {showCadastra === 1 && <></>}
                                            {step === compoNentesList.length - 1 ? (
                                                <BtnForm name={"Cadastrar Modelo"} />
                                            ) : (
                                                ""
                                            )}
                                            {step < compoNentesList.length - 1 ? (
                                                <BtnNext
                                                    isHidden={showCadastra}
                                                    onClick={() => next(step + 1)}
                                                    name="Proximo"
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default CreateMailingSeguros