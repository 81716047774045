import { Component } from "react";

export default class PageTitle extends Component {

    constructor(props) {
        super(props)
        const auth = window.localStorage.getItem("user")
        this.state = {
            auth: JSON.parse(auth)
        }

    }


    render() {
        const { auth } = this.state
        const { title } = this.props
        return (

            <>
                <div className="page-title">
                    <div className="row">
                        <div className="col-6">
                            <h3>{title}</h3>
                        </div>
                        <div className="col-6">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    {auth?.company_id?.name} </li>
                                <li className="breadcrumb-item active"> {title}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}