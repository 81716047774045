import { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import { Form, Input, BtnForm } from '../helpers/formData'
import MessageDisplay from "../messages/messageDisplay";
import { startLoad, stopLoad } from "../messages/load";
import UsersList from "./UsersList";
import PropTypes from 'prop-types';

export default class ButtonSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            newComponent: '',
            resultSearch: '',
            typeSearch: '',
            search: false
        }
        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.submitForm = this.submitForm.bind(this)
    }

    handleClose() {
        this.setState({
            show: false
        })
    }

    handleShow() {
        this.setState({
            show: true,
        })
    }

    submitForm(e) {
        const { typeSearch } = this.state
        const msg = new MessageDisplay();

        const form = e.target;

        const formData = new FormData(form);
        const serializedForm = Object.fromEntries(formData.entries());

        startLoad();

        const tokenLocalStorage = localStorage.getItem("token");
        const tokenHash = localStorage.getItem("tokenHash");
        const login = localStorage.getItem("login");

        fetch(`${process.env.REACT_APP_HTTP}/${typeSearch}-search/${login}/${tokenHash}`, {
            method: 'POST',
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                authorization: `Bearer ${tokenLocalStorage}`
            },
            body: JSON.stringify({
                ...serializedForm
            })
        })
            .then((res) => res.json())
            .then(async (res) => {
                stopLoad();
                if (res.error) {
                    stopLoad();
                    return msg.error("Usuário", res.data);
                }

                this.setState({
                    search: true,
                    show: false,
                    resultSearch: res.data
                })

            })
            .catch(() => {
                stopLoad();
                msg.error("Server", "Not connect");
            });

        e.preventDefault();
    }
    componentDidMount() {
        const { type } = this.props;
        if (type) {
            this.setState({
                typeSearch: type
            })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const { resultSearch } = this.state;
        const { onResult } = this.props;
        if (prevState.resultSearch !== resultSearch) {
            onResult(resultSearch);
        }
    }
    render() {
        const { search } = this.state
        const { onFunction, type } = this.props

        return (
            <>
                {search ? (
                    <button onClick={() => onFunction(this.setState({
                        search: false
                    }))} className="btn btn-sm btn-info" style={{ marginRight: '10px' }}>Limpar Filtro</button>
                ) : ''}
                <button onClick={this.handleShow} className="btn btn-sm btn-success"><i className="fa fa fa-search"></i></button>

                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    size="lg"
                    style={{ zIndex: '9998' }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Pesquisar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(e) => this.submitForm(e)}>
                            {type == 'users' ? (
                                <Input
                                    classInput={"col 12"}
                                    name={'find'}
                                    label={'Pesquise por E-mail / Login / Nome'}
                                    onChange={(e) => this.setState({ name: e })}
                                    placeholder={"Pesquisar"}
                                />
                            ) : ''}

                            {type == 'companies' ? (
                                <Input
                                    classInput={"col 12"}
                                    name={'find'}
                                    label={'Pesquise por E-mail / Responsável / CNPJ'}
                                    onChange={(e) => this.setState({ name: e })}
                                    placeholder={"Pesquisar"}
                                />
                            ) : ''}
                            <BtnForm name={'Pesquisar'} />
                        </Form>

                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

ButtonSearch.propTypes = {
    type: PropTypes.string.isRequired,
    onResult: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};