import { Component } from "react";
import PageTitle from "../../../helpers/PageTitle";
import UsersList from "../../../helpers/UsersList";
import MessageDisplay from "../../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../../messages/load";
import Company from "../companies";
import ReturnPage from "../../../helpers/ReturnPage";
import { ApiRequest } from "../../../helpers/Api";
import EditUser from "../../users/edit";

export default class UsersCompany extends Component {
    constructor(props) {
        super(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            data: [],
            newComponent: ''
        }


        this.getUsers = this.getUsers.bind(this);
        this.editUser = this.editUser.bind(this);
    }

    editUser(data) {
        this.setState({
            newComponent: <EditUser id={data} />
        })

    }
    componentDidMount() {
        this.getUsers()
    }

    returPage = () => {
        this.setState({
            newComponent: <Company />
        })
    };

    async getUsers() {
        const api = new ApiRequest();
        const msg = new MessageDisplay();
        const id = this.props.id

        const result = await api.get(`get-users-company/${id}`)
        console.log(result.data)
        if (result.eror) {
            return msg.error("Empresa", result.data);
        }
   
        this.setState({
            data: result.data
        })
    }

    render() {
        const { data, newComponent, auth } = this.state
        return (
            <>
                {newComponent ? newComponent : (
                    <div className="page-body">
                        <div className="container-fluid">
                            <PageTitle title={'Usuários'} auth={auth} />
                        </div>
                        <div className="container-fluid">
                            <ReturnPage OnClick={this.returPage} />
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h5>Usuários Empresa</h5>
                                                </div>
                                            </div>
                                        </div>
                                        {data ? (
                                            <UsersList data={data} editUser={(e) => { this.editUser(e) }} />
                                        ) : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                )}
            </>
        )
    }
}