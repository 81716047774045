import { Component } from "react";
import PropTypes from 'prop-types';

const defaultProps = {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round",
};

const iconPropTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    viewBox: PropTypes.string,
    fill: PropTypes.string,
    stroke: PropTypes.string,
    strokeWidth: PropTypes.string,
    strokeLinecap: PropTypes.string,
    strokeLinejoin: PropTypes.string,
};



export class IconChevronUp extends Component {
    render() {
        const props = { ...defaultProps, ...this.props };
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="feather feather-chevrons-up"
                {...props}
            >
                <polyline points="17 11 12 6 7 11"></polyline>
                <polyline points="17 18 12 13 7 18"></polyline>
            </svg>
        );
    }
}

export class IconHome extends Component {
    render() {
        const props = { ...defaultProps, ...this.props };
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="feather feather-home"
                {...props}
            >
                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                <polyline points="9 22 9 12 15 12 15 22" />
            </svg>
        );
    }
}
IconHome.propTypes = iconPropTypes;

export class IconPhone extends Component {
    render() {
        const props = { ...defaultProps, ...this.props };
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="feather-phone-call"
                {...props}
            >
                <path xmlns="http://www.w3.org/2000/svg" d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
        );
    }
}
IconPhone.propTypes = iconPropTypes;

export class Calendar extends Component {
    render() {
        const props = { ...defaultProps, ...this.props };
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="feather feather-calendar"
                {...props}
            >
                <rect xmlns="http://www.w3.org/2000/svg" x="3" y="4" width="18" height="18" rx="2" ry="2" />
                <line xmlns="http://www.w3.org/2000/svg" x1="16" y1="2" x2="16" y2="6" />
                <line xmlns="http://www.w3.org/2000/svg" x1="8" y1="2" x2="8" y2="6" />
                <line xmlns="http://www.w3.org/2000/svg" x1="3" y1="10" x2="21" y2="10" />
            </svg>
        );
    }
}
Calendar.propTypes = iconPropTypes;


export class IconUser extends Component {
    render() {
        const props = { ...defaultProps, ...this.props };
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="feather feather-users"
                {...props}
            >
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                <circle cx="9" cy="7" r="4" />
                <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
        );
    }
}

IconUser.propTypes = iconPropTypes;

export class IconAward extends Component {
    render() {
        const props = { ...defaultProps, ...this.props };
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="feather feather-award"
                {...props}
            >
                <circle cx="12" cy="8" r="7" />
                <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88" />
            </svg>
        );
    }
}

IconAward.propTypes = iconPropTypes;

export class IconSearch extends Component {
    render() {
        const props = { ...defaultProps, ...this.props };
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="feather feather-search"
                {...props}
            >
                <circle cx="11" cy="11" r="8" />
                <line x1="21" y1="21" x2="16.65" y2="16.65" />
            </svg>
        );
    }
}

IconSearch.propTypes = iconPropTypes;


export class IconFilePlus extends Component {
    render() {
        const props = { ...defaultProps, ...this.props };
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="feather feather-file-plus"
                {...props}
            >
                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z">
                </path>
                <polyline points="14 2 14 8 20 8">
                </polyline>
                <line x1="12" y1="18" x2="12" y2="12">
                </line>
                <line x1="9" y1="15" x2="15" y2="15">
                </line>
            </svg>
        );
    }
}

IconFilePlus.propTypes = iconPropTypes;

export class IconConfig extends Component {
    render() {
        let feather = 'feather feather-settings'
        const props = { ...defaultProps, ...this.props };
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className={feather}
                {...props}
            >
                <circle xmlns="http://www.w3.org/2000/svg" cx="12" cy="12" r="3" />
                <path xmlns="http://www.w3.org/2000/svg" d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
            </svg>
        );
    }
}

IconConfig.propTypes = iconPropTypes;

export class IconHeadPhone extends Component {
    render() {
        let feather = 'feather feather-headphones'
        const props = { ...defaultProps, ...this.props };
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className={feather}
                {...props}
            >
                <path xmlns="http://www.w3.org/2000/svg" d="M3 18v-6a9 9 0 0 1 18 0v6" />
                <path xmlns="http://www.w3.org/2000/svg" d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z" />
            </svg>
        );
    }
}

IconHeadPhone.propTypes = iconPropTypes;


export class IconAtendimentManual extends Component {
    render() {
        let feather = 'feather feather-activity'
        const props = { ...defaultProps, ...this.props };
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className={feather}
                {...props}
            >
                <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                <polygon points="12 15 17 21 7 21 12 15"></polygon>
            </svg>
        );
    }
}

IconAtendimentManual.propTypes = iconPropTypes;

export class IconChart extends Component {
    render() {
        let feather = 'feather feather-pie-chart'
        const props = { ...defaultProps, ...this.props };
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className={feather}
                {...props}
            >
                <path xmlns="http://www.w3.org/2000/svg" d="M21.21 15.89A10 10 0 1 1 8 2.83" />
                <path xmlns="http://www.w3.org/2000/svg" d="M22 12A10 10 0 0 0 12 2v10z" />
            </svg>
        );
    }
}

IconChart.propTypes = iconPropTypes;

export class IconMonitor extends Component {
    render() {
        const feather = 'feather feather-monitor'
        const props = { ...defaultProps, ...this.props };
        return (
            <svg 
                xmlns="http://www.w3.org/2000/svg"
                className={feather}
                {...props}
            >
                <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
                <line x1="8" y1="21" x2="16" y2="21"></line>
                <line x1="12" y1="17" x2="12" y2="21"></line>
            </svg>
        );
    }
}

IconMonitor.propTypes = iconPropTypes;

export class IconSidebarToggle extends Component {
    render() {
        const props = { ...defaultProps, ...this.props };
        return (
            <svg
                data-feather="grid"
                xmlns="http://www.w3.org/2000/svg"
                className="feather feather-grid status_toggle middle sidebar-toggle"
                {...props}
            >
                <rect x="3" y="3" width="7" height="7"></rect>
                <rect x="14" y="3" width="7" height="7"></rect>
                <rect x="14" y="14" width="7" height="7"></rect>
                <rect x="3" y="14" width="7" height="7"></rect>
            </svg>
        );
    }
}

IconSidebarToggle.propTypes = iconPropTypes;
