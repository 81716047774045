import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import InputMask from "react-input-mask";
import { listBanks } from "../../../helpers/FuncHelpers";

const CoefficientModal = ({ show, onClose, onCreate }) => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [formData, setFormData] = useState({
    tipo: "",
    banco: "",
    nome: "",
    convenio: "",
    fator: "0.0000",
    prazo: "",
    teams_id: "",
    minimoPortado: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "prazo" && parseInt(value) > 84) {
      setFormData((prevData) => ({
        ...prevData,
        prazo: "84",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onCreate(formData);
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Criar Novo Coeficiente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Tipo</Form.Label>
            <Form.Select
              name="tipo"
              value={formData.tipo}
              onChange={handleChange}
              required
            >
              <option value="">Selecione um Tipo</option>
              {[
                {
                  _id: "margem",
                  name: "Margem",
                },
                {
                  _id: "cartao",
                  name: "Cartão",
                },
                {
                  _id: "portabilidade",
                  name: "Portabilidade",
                },
              ].map((convenio, i) => (
                <option key={i} value={convenio._id}>
                  {convenio.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Equipe</Form.Label>
            <Form.Select
              name="teams_id"
              value={formData.teams_id}
              onChange={handleChange}
              required
            >
              <option value="">Selecione uma equipe</option>
              {auth.teams.map((team) => (
                <option key={team.team_id} value={team.team_id}>
                  {team.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Banco</Form.Label>
            <Form.Select
              name="banco"
              value={formData.banco}
              onChange={handleChange}
              required
            >
              <option value="">Selecione um banco</option>
              {listBanks().map((bank) => (
                <option key={bank.value} value={bank.value}>
                  {bank.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Convênio</Form.Label>
            <Form.Select
              name="convenio"
              value={formData.convenio}
              onChange={handleChange}
              required
            >
              <option value="">Selecione um convênio</option>
              {auth?.company_id?.convenios?.map((convenio) => (
                <option key={convenio._id} value={convenio._id}>
                  {convenio.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              name="nome"
              placeholder="Nome do Coeficiente"
              value={formData.nome}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Fator</Form.Label>
            <NumericFormat
              className="form-control"
              format="0.000"
              mask="_"
              placeholder="0.000"
              allowEmptyFormatting={false}
              name="fator"
              value={formData.fator}
              onValueChange={(values) => {
                const { value, floatValue } = values;
                // Set the state with the formatted float value
                setFormData((prevData) => ({
                  ...prevData,
                  fator:
                    floatValue !== undefined ? floatValue.toString() : value,
                }));
              }}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Prazo</Form.Label>
            <InputMask
              mask="99"
              placeholder="84"
              name="prazo"
              value={formData.prazo}
              onChange={handleChange}
              required
            >
              {(inputProps) => <Form.Control {...inputProps} />}
            </InputMask>
          </Form.Group>
          {formData.tipo === "portabilidade" && (
            <Form.Group className="mb-3">
              <Form.Label>Mínimo Portado</Form.Label>
              <Form.Control
                type="text"
                placeholder="0"
                name="minimoPortado"
                value={formData.minimoPortado}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^\d]/g, "");
                  handleChange(e);
                }}
                required
                onKeyUp={(e) => {
                  const charCode = e.charCode;
                  if (
                    charCode !== 0 &&
                    !/\d/.test(String.fromCharCode(charCode))
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Group>
          )}
          <Button type="submit">Criar</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CoefficientModal;
