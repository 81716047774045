import { Component } from "react";
import { InputCurrency } from "../../../../helpers/formData";

export default class CartaoBeneficioInss extends Component {
    render() {
        return (
            <>
                <InputCurrency
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    label={'Cartão BENEFÍCIO de:'}
                    name={"cartao_beneficio_de"}
                    placeholder={"Cartão BENEFÍCIO de"}
                />
                <InputCurrency
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    label={'Cartão BENEFÍCIO até'}
                    name={"cartao_beneficio_ate"}
                    placeholder={"Cartão BENEFÍCIO até"}
                />
            </>
        )
    }
}