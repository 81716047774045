import { Component } from "react";
import { FaHeadset } from "react-icons/fa";
import PageTitle from "../../../helpers/PageTitle";
import { Form, SelectForm, Input, BtnForm } from "../../../helpers/formData";
import Teams from "../teams/teams";
import ReturnPage from "../../../helpers/ReturnPage";
import UpdateDiscSoftcall from "./list/softcall"
import UpdateArgus from "./list/argus"
import UpdateCallix from "./list/callix"
import UpdateIpbox from "./list/ipbox";
import Update3cPlus from './list/3cplus';
import UpdateFastTelecom from "./list/fasttelecom";
import MessageDisplay from "../../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../../messages/load";
import UpdateMaisVoip from "./list/mais_voip";
import { DialerEnum } from './../../../../core/enum/DialerEnum';

export default class Discador extends Component {
    constructor(props) {
        super(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            newComponent: '',
            name: ''
        }

        this.submitDisc = this.submitDisc.bind(this)
    }


    submitDisc(e) {
        e.preventDefault();
        const msg = new MessageDisplay();

        const form = e.target;

        const formData = new FormData(form);
        const serializedForm = Object.fromEntries(formData.entries());
        startLoad();

        const tokenLocalStorage = localStorage.getItem("token");
        const tokenHash = localStorage.getItem("tokenHash");
        const login = localStorage.getItem("login");

        fetch(`${process.env.REACT_APP_HTTP}/teams-discadora/${login}/${tokenHash}`, {
            method: 'PUT',
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                authorization: `Bearer ${tokenLocalStorage}`
            },
            body: JSON.stringify({
                ...serializedForm
            })
        })
            .then((res) => res.json())
            .then(async (res) => {
                console.log(res)
                stopLoad()

                if (res.error) {
                    stopLoad();
                    return msg.error("Discadora", res.data);
                }
                return msg.success("Discadora", 'Discadora Atualizada com Sucesso');


            })
            .catch(() => {
                stopLoad();
                msg.error("Server", "Not connect");
            });
    }

    returPage = () => {
        this.setState({
            newComponent: <Teams />
        })
    };

    render() {
        const discador = [
            {
                value: DialerEnum.Softcall, label: 'SOFTCALL'
            },
            {
                value: 'ipbox', label: 'IPBOX'
            },
            {
                value: 'mais_voip', label: 'MAIS VOIP'
            }
            ,
            {
                value: DialerEnum.Argus, label: 'ARGUS'
            }
            ,
            {
                value: DialerEnum.Callix, label: 'CALLIX'
            }
            ,
            {
                value: '3c_plus', label: '3CPLUS'
            }
            ,
            {
                value: 'fast_telecom', label: 'FAST TELECOM'
            }
        ]
        const { newComponent, auth } = this.state
        const { data } = this.props
        return (
            <>
                {newComponent ? newComponent : (
                    <div className="page-body">
                        <div className="container-fluid">
                            <PageTitle title={'Integração Discadoras'} auth={auth} />
                        </div>
                        <div className="container-fluid">
                            <ReturnPage OnClick={this.returPage} />
                            <div className="row">
                                <Form title={"Selecione o Discador"} onSubmit={(e) => this.submitDisc(e)}>
                                    <Input
                                        name={'idTeams'}
                                        valueInput={data._id}
                                        type={'hidden'}
                                    />
                                    <SelectForm
                                        valueSelect={data?.discadora?.value}
                                        name={'disc'}
                                        titleLabel={"Selecione o Discador"}
                                        data={discador}
                                    />
                                    <BtnForm name={'Salvar Alteração'} />
                                </Form>
                            </div>
                            <div
                                className="container-fluid search-page"
                                style={{ background: "#F4F5FA", padding: "50px" }}
                            >
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="">
                                            <div className="">
                                                <div className="text-center">
                                                    <ul
                                                        className="nav nav-tabs search-list"
                                                        id="top-tab"
                                                        role="tablist"
                                                        style={{ border: "nome !important" }}
                                                    >
                                                        <li className="nav-item">
                                                            <a
                                                                className="nav-link active"
                                                                id="datasoftcall"
                                                                data-bs-toggle="tab"
                                                                href="#data-softcall"
                                                                role="tab"
                                                                aria-selected="true"
                                                                data-bs-original-title=""
                                                                title=""
                                                            >
                                                                <FaHeadset /> SOFTCALL
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                className="nav-link"
                                                                id="dataargus"
                                                                data-bs-toggle="tab"
                                                                href="#data-argus"
                                                                role="tab"
                                                                aria-selected="false"
                                                                data-bs-original-title=""
                                                                title=""
                                                            >
                                                                <FaHeadset /> ARGUS
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                className="nav-link"
                                                                id="datacallix"
                                                                data-bs-toggle="tab"
                                                                href="#data-callix"
                                                                role="tab"
                                                                aria-selected="false"
                                                                data-bs-original-title=""
                                                                title=""
                                                            >
                                                                <FaHeadset /> CALLIX
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                className="nav-link"
                                                                id="dataipbox"
                                                                data-bs-toggle="tab"
                                                                href="#data-ipbox"
                                                                role="tab"
                                                                aria-selected="false"
                                                                data-bs-original-title=""
                                                                title=""
                                                            >
                                                                <FaHeadset /> IPBOX
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                className="nav-link"
                                                                id="datamaisvoip"
                                                                data-bs-toggle="tab"
                                                                href="#data-maisvoip"
                                                                role="tab"
                                                                aria-selected="false"
                                                                data-bs-original-title=""
                                                                title=""
                                                            >
                                                                <FaHeadset /> MAIS VOIP
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                className="nav-link"
                                                                id="data3cplus"
                                                                data-bs-toggle="tab"
                                                                href="#data-3cplus"
                                                                role="tab"
                                                                aria-selected="false"
                                                                data-bs-original-title=""
                                                                title=""
                                                            >
                                                                <FaHeadset /> 3C PLUS
                                                            </a>

                                                        </li>
                                                        <li className="nav-item">
                                                            <a
                                                                className="nav-link"
                                                                id="datafastteleco"
                                                                data-bs-toggle="tab"
                                                                href="#data-fast-telecom"
                                                                role="tab"
                                                                aria-selected="false"
                                                                data-bs-original-title=""
                                                                title=""
                                                            >
                                                                <FaHeadset /> FAST TELECOM
                                                            </a>

                                                        </li>
                                                    </ul>
                                                </div>
                                                <br />
                                                <div className="tab-content" id="top-tabContent">
                                                    <div
                                                        className="search-links tab-pane active fade show"
                                                        id="data-softcall"
                                                        role="tabpanel"
                                                        aria-labelledby="datasoftcall"
                                                    >

                                                        <div className="edit-profile">
                                                            <UpdateDiscSoftcall data={data} />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="tab-content" id="top-tabContent">
                                                    <div
                                                        className="search-links tab-pane fade show"
                                                        id="data-ipbox"
                                                        role="tabpanel"
                                                        aria-labelledby="dataipbox"
                                                    >
                                                        <div className="edit-profile">
                                                            <UpdateIpbox data={data} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-content" id="top-tabContent">
                                                    <div
                                                        className="search-links tab-pane fade show"
                                                        id="data-maisvoip"
                                                        role="tabpanel"
                                                        aria-labelledby="datamaisvoip"
                                                    >
                                                        <div className="edit-profile">
                                                            <UpdateMaisVoip data={data} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-content" id="top-tabContent">
                                                    <div
                                                        className="search-links tab-pane fade show"
                                                        id="data-argus"
                                                        role="tabpanel"
                                                        aria-labelledby="dataargus"
                                                    >
                                                        <div className="edit-profile">
                                                            <UpdateArgus data={data} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-content" id="top-tabContent">
                                                    <div
                                                        className="search-links tab-pane fade show"
                                                        id="data-callix"
                                                        role="tabpanel"
                                                        aria-labelledby="datacallix"
                                                    >
                                                        <div className="edit-profile">
                                                            <UpdateCallix data={data} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tab-content" id="top-tabContent">
                                                    <div
                                                        className="search-links tab-pane fade show"
                                                        id="data-3cplus"
                                                        role="tabpanel"
                                                        aria-labelledby="data3cplus"
                                                    >
                                                        <div className="edit-profile">
                                                            <Update3cPlus data={data} />
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="tab-content" id="top-tabContent">
                                                    <div
                                                        className="search-links tab-pane fade show"
                                                        id="data-fast-telecom"
                                                        role="tabpanel"
                                                        aria-labelledby="datafastteleco"
                                                    >
                                                        <div className="edit-profile">
                                                            <UpdateFastTelecom data={data} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                )}
            </>
        )
    }
}