import { Component } from "react";
import { Form, Input, BtnForm } from '../../../../helpers/formData'
import MessageDisplay from "../../../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../../../messages/load";

export default class UpdateDiscCallix extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            urlIsValid: null
        }
        this.checkUrlIsValid = this.checkUrlIsValid.bind(this)
    }
    submitForm(e) {
        e.preventDefault();
        const msg = new MessageDisplay();

        const form = e.target;

        const formData = new FormData(form);
        const serializedForm = Object.fromEntries(formData.entries());
        startLoad();

        const tokenLocalStorage = localStorage.getItem("token");
        const tokenHash = localStorage.getItem("tokenHash");
        const login = localStorage.getItem("login");

        fetch(`${process.env.REACT_APP_HTTP}/teams-insert-discadora/${login}/${tokenHash}`, {
            method: 'POST',
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                authorization: `Bearer ${tokenLocalStorage}`
            },
            body: JSON.stringify({
                ...serializedForm
            })
        })
            .then((res) => res.json())
            .then(async (res) => {
                stopLoad()

                if (res.error) {
                    stopLoad();
                    return msg.error("Discadora", res.data);
                }

                const alertMessageNoParams = document.querySelector('#alert-callix-no-params');
                if (alertMessageNoParams != null)
                    alertMessageNoParams.classList.add('d-none');

                return msg.success("Discadora", 'Discadora Atualizada com Sucesso');
            })
            .catch((e) => {
                stopLoad();
                msg.error("Erro interno", `Não foi possível atualizar a discadora -> ${e}`);
            });
    }

    checkUrlIsValid(){
        const valueInputUrl = document.querySelector('.callix [name="url"]').value;
        console.log(valueInputUrl);
        
        const initValueInputUrl = valueInputUrl.slice(0, 4);

        if ( initValueInputUrl === 'http' &&
            (valueInputUrl.includes("http://") || valueInputUrl.includes("https://"))
        ){
            this.setState({ urlIsValid: true });
            return
        }
        
        this.setState({ urlIsValid: false });
    }

    render() {
        const { data } = this.props;
        console.log(data);
        const { urlIsValid } = this.state;
        const discadora = data?.discadoraCallix[0];
        return (
            <>
                <div className="col-xl-12 callix">
                    <Form title={'Discadora Callix'} onSubmit={(e) => this.submitForm(e)}>
                        {(!discadora?.url || discadora?.url === '') &&
                            <>
                            <div id="alert-callix-no-params" className="alert alert-info" role="alert">
                                Sua discadora Callix não está parametrizada. Adicione a <u>URL</u> e o <u>TOKEN</u> e salve as alterações
                            </div>
                            </>
                        }

                        { !urlIsValid && urlIsValid !== null &&
                            <>
                                <div className="alert alert-danger" role="alert">
                                    <h6><strong>Atenção!</strong> URL inválida. Siga o modelo de exemplo abaixo para parametrizar corretamente a sua discadora: </h6>
                                    Ex.: https://empresa.callix.com.br (Sem "/" no final)
                                </div>
                            </> 
                        }                        

                        <Input
                            autoFocus={true}
                            valueInput={discadora?.url}
                            classInput={"col-12"}
                            name={'url'}
                            label={'URL AMBIENTE'}
                            onChange={() =>
                                this.checkUrlIsValid()
                            }
                            placeholder={"Ex.: https://empresa.callix.com.br"}
                        />
                        <Input
                            valueInput={discadora?.token}
                            classInput={"col-12"}
                            name={'token'}
                            label={'Token'}
                            onChange={() =>
                                this.checkUrlIsValid()
                            }
                            placeholder={"Token de autenticação da discadora"}
                        />

                        <Input
                            valueInput={'callix'}
                            name={'type_discadora'}
                            type={'hidden'}
                        />
                        <Input
                            valueInput={data?._id}
                            name={'team_id'}
                            type={'hidden'}
                        />
                        <BtnForm name={'Atualizar Callix'} disabled={(!urlIsValid)} title={'Salvar configurações da discadora'} />
                    </Form>
                </div>
            </>
        )
    }
}