import { Component } from "react";
import { SelectForm, InputData, InputCurrency, Input } from "../../../../helpers/formData";

export default class CaracteristicasFGTS extends Component {
    render() {
        return (
            <>
                <Input
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={'idade_de'}
                    label={'Idade de'}
                    placeholder={"Idade de"}
                />
                <Input
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={'idade_ate'}
                    label={'Idade até'}
                    placeholder={"Idade até"}
                />

                <InputCurrency
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={'renda_de'}
                    label={'Renda de:'}
                    placeholder={"Renda de:"}
                />
                <InputCurrency
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={'renda_ate'}
                    label={'Renda até:'}
                    placeholder={"Renda até:"}
                />

                <InputCurrency
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={'valor_fgts_de'}
                    label={'Valor Fgts de:'}
                    placeholder={"Valor Fgts de:"}
                />
                <InputCurrency
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={'valor_fgts_ate'}
                    label={'Valor Fgts até:'}
                    placeholder={"Valor Fgts até:"}
                />

                <SelectForm
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={"sexo"}
                    optionLebel="Selecione o Sexo:"
                    labelName={'Sexo'}
                    data={[
                        {
                            value: 'Ambos',
                            label: 'Ambos'
                        },
                        {
                            value: 'Masculino',
                            label: 'Masculino'
                        },
                        {
                            value: 'Feminino',
                            label: 'Feminino'
                        }
                    ]}>
                </SelectForm>
            </>
        )
    }
}