import { Component } from "react";

export class CreativeCard extends Component {
    render() {
        const { width, title, color, desc, onClick, type } = this.props
        return (
            <>
                <div className={width} style={{ cursor: 'pointer' }} onClick={() => { onClick(type) }}>
                    <div className="card card-absolute">
                        <div className={`card-header bg-${color}`}>
                            <h5 className="text-white">{title}</h5>
                        </div>
                        <div className="card-body">
                            <p>{desc}</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}