import { listBanks } from "../../../helpers/FuncHelpers";
import { FaMoneyCheckAlt } from "react-icons/fa";

const SegurosComponente = ({ dadosBancarios }) => {
    const bankList = listBanks()

    const bankname = dadosBancarios && dadosBancarios.length && dadosBancarios[0]?.banco_codigo && dadosBancarios[0]?.banco_codigo !== null ? bankList.filter((e) => e.value == dadosBancarios[0].banco_codigo)[0] : null

    return (
        <>
            <div className="tab-content" style={{ padding: '0px' }} id="top-tabContent">
                <div
                    className="search-links tab-pane"
                    id="data-seguros"
                    role="tabpanel"
                    aria-labelledby="dataseguros"
                >
                    <div className="list_beneficio row">
                        <div className="col-sm-6 col-xl-6">
                            <div className="card">
                                <div className="card-header">
                                    <h5><FaMoneyCheckAlt /> Dados Bancários</h5>
                                </div>
                                <div className="card-body" style={{ padding: '5px 20px' }}>
                                    <div className="d-flex justify-content-between align-items-center ficha_item">
                                        <div className="ficha_item_title">
                                            <span>BANCO</span>
                                        </div>
                                        <div className="ficha_item_desc">
                                            <span>{bankname?.label}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center ficha_item">
                                        <div className="ficha_item_title">
                                            <span>AG. BANCO</span>
                                        </div>
                                        <div className="ficha_item_desc">
                                            <span>{dadosBancarios && dadosBancarios.length ? dadosBancarios[0]?.agencia_codigo : 'Não informado'}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center ficha_item">
                                        <div className="ficha_item_title">
                                            <span>CONTA</span>
                                        </div>
                                        <div className="ficha_item_desc">
                                            <span>{dadosBancarios && dadosBancarios.length ? dadosBancarios[0]?.banco_numero : 'Não informado'}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center ficha_item">
                                        <div className="ficha_item_title">
                                            <span>RECEBIMENTO</span>
                                        </div>
                                        <div className="ficha_item_desc">
                                            <span>{dadosBancarios && dadosBancarios.length ? dadosBancarios[0]?.banco_tipo : 'Não informado'}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center ficha_item">
                                        <div className="ficha_item_title">
                                            <span>CNPJ AGÊNCIA</span>
                                        </div>
                                        <div className="ficha_item_desc">
                                            <span>{dadosBancarios && dadosBancarios.length ? dadosBancarios[0]?.agencia_cnpj : 'Não informado'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-6">
                            <div className="card">
                                <div className="card-header">
                                    <h5><i className="icon-map-alt"></i> Localização Agência</h5>
                                </div>
                                <div className="card-body" style={{ padding: '5px 20px' }}>
                                    <div className="d-flex justify-content-between align-items-center ficha_item">
                                        <div className="ficha_item_title">
                                            <span>CEP</span>
                                        </div>
                                        <div className="ficha_item_desc">
                                            <span>{dadosBancarios && dadosBancarios.length? dadosBancarios[0]?.agencia_endereco_cep : 'Não informado'}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center ficha_item">
                                        <div className="ficha_item_title">
                                            <span>ENDEREÇO</span>
                                        </div>
                                        <div className="ficha_item_desc">
                                            <span>{dadosBancarios && dadosBancarios.length ? dadosBancarios[0]?.agencia_endereco_endereco : 'Não informado'}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center ficha_item">
                                        <div className="ficha_item_title">
                                            <span>BAIRRO</span>
                                        </div>
                                        <div className="ficha_item_desc">
                                            <span>{dadosBancarios && dadosBancarios.length ? dadosBancarios[0]?.agencia_endereco_bairro : 'Não informado'}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center ficha_item">
                                        <div className="ficha_item_title">
                                            <span>CIDADE</span>
                                        </div>
                                        <div className="ficha_item_desc">
                                            <span>{dadosBancarios && dadosBancarios.length ? dadosBancarios[0]?.agencia_endereco_cidade: 'Não informado'}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center ficha_item">
                                        <div className="ficha_item_title">
                                            <span>UF</span>
                                        </div>
                                        <div className="ficha_item_desc">
                                            <span>{dadosBancarios && dadosBancarios.length ? dadosBancarios[0]?.agencia_endereco_uf: 'Não informado'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SegurosComponente;