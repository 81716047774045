import { Component } from "react";
import { Form, Input, BtnForm } from '../../../../helpers/formData'
import MessageDisplay from "../../../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../../../messages/load";

export default class Update3cPlus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
        }
    }
    submitForm(e) {
        e.preventDefault();
        const msg = new MessageDisplay();

        const form = e.target;

        const formData = new FormData(form);
        const serializedForm = Object.fromEntries(formData.entries());
        startLoad();

        const tokenLocalStorage = localStorage.getItem("token");
        const tokenHash = localStorage.getItem("tokenHash");
        const login = localStorage.getItem("login");

        fetch(`${process.env.REACT_APP_HTTP}/teams-insert-discadora/${login}/${tokenHash}`, {
            method: 'POST',
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                authorization: `Bearer ${tokenLocalStorage}`
            },
            body: JSON.stringify({
                ...serializedForm
            })
        })
            .then((res) => res.json())
            .then(async (res) => {

                stopLoad()

                if (res.error) {
                    stopLoad();
                    return msg.error("Discadora", res.data);
                }
                return msg.success("Discadora", 'Discadora Atualizada com Sucesso');


            })
            .catch((e) => {
                stopLoad();
                msg.error("Server", "Not connect");
            });
    }
    render() {
        const { data } = this.props
        const discadora = data?.discadora3cplus[0]
        return (
            <>
                <div className="col-xl-12">
                    <Form title={'Discadora 3cPlus'} onSubmit={(e) => this.submitForm(e)}>
                        <Input
                            classInput={"col-sm-6 col-md-6 col-xl-6"}
                            valueInput={'3cplus'}
                            type={'hidden'}
                            name={'type_discadora'}
                            placeholder={"Url de integração"}
                        />
                        <Input
                            classInput={"col-sm-6 col-md-6 col-xl-6"}
                            valueInput={data?._id}
                            type={'hidden'}
                            name={'team_id'}
                            placeholder={"Url de integração"}
                        />
                        <Input
                            classInput={"col-sm-6 col-md-6 col-xl-6"}
                            name={'url'}
                            valueInput={discadora?.url}
                            label={'URL'}
                            placeholder={"Url de integração"}
                        />
                        <Input
                            classInput={"col-sm-6 col-md-6 col-xl-6"}
                            name={'token'}
                            valueInput={discadora?.token}
                            label={'Token'}
                            placeholder={"Token"}
                        />
                        <BtnForm name={'Update 3cPlus'} />
                    </Form>
                </div>
            </>
        )
    }
}