import { Component } from "react";
import PageTitle from "../../helpers/PageTitle";
import { Form, Input, Select, InputFile, BtnForm } from "../../helpers/formData";
import MessageDisplay from "../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../messages/load";
import NoCompany from '../../../assets/images/proconsig/no_login.png'
import Company from "./companies";
import ReturnPage from "../../helpers/ReturnPage";
import UsersList from "../../helpers/UsersList";
import DataCompany from "./data_company";
import TotalLicenses from "./licenses/licenses";
import TotalLicensesTrial from "./licenses/licenses_trial";
import DashCompany from "./dash_company/dash_company";
import Convenios from "./convenios/convenios";
import { ApiRequest } from "../../helpers/Api";


export default class EditCompany extends Component {
    constructor(props) {
        super(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            company: '',
            data: ''
        }
        this.getUsers = this.getUsers.bind(this)
        this.getCompanyEdit = this.getCompanyEdit.bind(this)

    }
    async componentDidMount() {

        const users = await this.getUsers()
        console.log(users)
        this.setState({
            data: users
        }, async () => {
            await this.getCompanyEdit()
        })


    }


    async getUsers() {
        const { id } = this.props
        const api = new ApiRequest();
        const msg = new MessageDisplay();
        const result = await api.get(`get-users-company/${id}`);
        if (result.eror) {
            return msg.error("Empresa", result.data);
        }
        return result.data
    }


    async getCompanyEdit() {
        const { id } = this.props
        const api = new ApiRequest();
        const msg = new MessageDisplay();
        const result = await api.get(`companie-id/${id}`)
        console.log(result.data)
        if (result.error) {
            return msg.error("Empresa", result.data);
        }

        if (result.error == false) {
            this.setState({
                company: result.data
            })
        }
    }



    returPage = () => {
        this.setState({
            newComponent: <Company />
        })
    };


    render() {

        const { auth, data, newComponent, company } = this.state
        return (
            <>
                {newComponent ? newComponent : (
                    <div className="page-body">
                        <div className="container-fluid">
                            <PageTitle title={"Editar Empresa"} auth={auth} />
                        </div>
                        <div className="container-fluid"></div>
                        <ReturnPage OnClick={this.returPage} />
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="">
                                    <div className="">
                                        <div className="text-center">
                                            <ul
                                                className="nav nav-tabs search-list"
                                                id="top-tab"
                                                role="tablist"
                                                style={{ border: "nome !important" }}
                                            > <li className="nav-item">
                                                    <a
                                                        className="nav-link active"
                                                        id="datadash"
                                                        data-bs-toggle="tab"
                                                        href="#data-dash"
                                                        role="tab"
                                                        aria-selected="true"
                                                        data-bs-original-title=""
                                                        title=""
                                                    >
                                                        DASH
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a
                                                        className="nav-link"
                                                        id="dataedit"
                                                        data-bs-toggle="tab"
                                                        href="#data-edit"
                                                        role="tab"
                                                        aria-selected="false"
                                                        data-bs-original-title=""
                                                        title=""
                                                    >
                                                        EDITAR
                                                    </a>
                                                </li>
                                                {company.level !== 'master' ? (
                                                    <>
                                                        <li className="nav-item">
                                                            <a
                                                                className="nav-link"
                                                                id="dataconvenios"
                                                                data-bs-toggle="tab"
                                                                href="#data-convenios"
                                                                role="tab"
                                                                aria-selected="true"
                                                                data-bs-original-title=""
                                                                title=""
                                                            >
                                                                CONVÊNIOS
                                                            </a>
                                                        </li>
                                                        {/* {company.level == 'empresa' && company.status == 'active' ? (
                                                            <>
                                                                <li className="nav-item">
                                                                    <a
                                                                        className="nav-link"
                                                                        id="datateams"
                                                                        data-bs-toggle="tab"
                                                                        href="#data-teams"
                                                                        role="tab"
                                                                        aria-selected="false"
                                                                        data-bs-original-title=""
                                                                        title=""
                                                                    >
                                                                        EQUIPES
                                                                    </a>
                                                                </li>
                                                            </>
                                                        ) : ''} */}
                                                        {company.status !== 'trial' && (<>
                                                            <li className="nav-item">
                                                                <a
                                                                    className="nav-link"
                                                                    id="datatotalusers"
                                                                    data-bs-toggle="tab"
                                                                    href="#data-totalusers"
                                                                    role="tab"
                                                                    aria-selected="false"
                                                                    data-bs-original-title=""
                                                                    title=""
                                                                >
                                                                    LICENÇAS
                                                                </a>

                                                            </li>
                                                        </>)}
                                                        {(company.status === 'trial' || company.level === 'whitelabel') && (<>
                                                            <li className="nav-item">
                                                                <a
                                                                    className="nav-link"
                                                                    id="datatotaluserstrial"
                                                                    data-bs-toggle="tab"
                                                                    href="#data-totaluserstrial"
                                                                    role="tab"
                                                                    aria-selected="false"
                                                                    data-bs-original-title=""
                                                                    title=""
                                                                >
                                                                    LICENÇAS TRIAL
                                                                </a>

                                                            </li>
                                                        </>)}
                                                        <li className="nav-item">
                                                            <a
                                                                className="nav-link"
                                                                id="datausers"
                                                                data-bs-toggle="tab"
                                                                href="#data-users"
                                                                role="tab"
                                                                aria-selected="false"
                                                                data-bs-original-title=""
                                                                title=""
                                                            >
                                                                USUÁRIOS
                                                            </a>

                                                        </li>
                                                    </>
                                                ) : ''}
                                            </ul>
                                        </div>
                                        <br />

                                        <div className="tab-content" id="top-tabContent">
                                            <div
                                                className="search-links tab-pane active fade show"
                                                id="data-dash"
                                                role="tabpanel"
                                                aria-labelledby="datadash"
                                            >
                                                <DashCompany company={company} />
                                            </div>
                                        </div>
                                        <br />
                                        <div className="tab-content" id="top-tabContent">
                                            <div
                                                className="search-links tab-pane fade show"
                                                id="data-convenios"
                                                role="tabpanel"
                                                aria-labelledby="dataconvenios"
                                            >
                                                <Convenios company={company} />
                                            </div>
                                        </div>

                                        <div className="tab-content" id="top-tabContent">
                                            <div
                                                className="search-links tab-pane fade show"
                                                id="data-edit"
                                                role="tabpanel"
                                                aria-labelledby="dataedit"
                                            >
                                                <div className="container-fluid">


                                                    {data ? (
                                                        <DataCompany data={company} />
                                                    ) : ''}

                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-content" id="top-tabContent">
                                            <div
                                                className="search-links tab-pane fade show"
                                                id="data-teams"
                                                role="tabpanel"
                                                aria-labelledby="datateams"
                                            >
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <div>
                                                                            <h5>EQUIPES</h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card-body">

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-content" id="top-tabContent">
                                            <div
                                                className="search-links tab-pane fade show"
                                                id="data-totalusers"
                                                role="tabpanel"
                                                aria-labelledby="datatotalusers"
                                            >
                                                <TotalLicenses company={company} />
                                            </div>
                                        </div>
                                        <div className="tab-content" id="top-tabContent">
                                            <div
                                                className="search-links tab-pane fade show"
                                                id="data-totaluserstrial"
                                                role="tabpanel"
                                                aria-labelledby="datatotaluserstrial"
                                            >

                                                <TotalLicensesTrial company={company} />
                                            </div>
                                        </div>
                                        <div className="tab-content" id="top-tabContent">
                                            <div
                                                className="search-links tab-pane fade show"
                                                id="data-users"
                                                role="tabpanel"
                                                aria-labelledby="datausers"
                                            >
                                                <div className="container-fluid">

                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <div>
                                                                            <h5>Usuários Empresa</h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {data ? (
                                                                    <UsersList data={data} />
                                                                ) : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}