import { Component } from "react";
import NoAvatar from "../../../../assets/images/proconsig/no_avatar.jpg";

export default class ResultSearch extends Component {
    render() {

        const { data, onGetClient, colResult } = this.props
        const client = data[0]?.pessoa ? data[0]?.pessoa[0] : false
        return (
            <>
                <div className={colResult}>
                    <div className="card">
                        <div className="card-header">
                            <h5>Resultado da Pesquisa</h5>
                        </div>
                        {client ? (
                            <div
                                className="blog-box blog-list row"
                                style={{ padding: "10px" }}
                            >
                                <div className="col-sm-2">
                                    <img
                                        className="img-fluid sm-100-w"
                                        src={NoAvatar}
                                        alt=""
                                        style={{ width: "80%", borderRadius: "50%" }}
                                    />
                                </div>
                                <div className="col-sm-10">
                                    <div className="blog-details">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="">
                                                <div className="blog-date">
                                                    <span style={{ fontSize: "1.2em" }}><b>{client.nome}</b></span> {client.sexo}
                                                </div>
                                            </div>
                                            <div className="">
                                                <button className="btn btn-sm btn-danger mt-2" id={JSON.stringify(data[0])} onClick={(e) => { onGetClient(e) }}>
                                                    <i className="fa fa fa-search"></i> Visualizar Cliente
                                                </button>
                                            </div>
                                        </div>
                                        <div className="blog-bottom-content">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (<p className="text-center" style={{ padding: "15px 0" }}>Desculpe não foram encontrados dados para a pesquisa</p>)}
                    </div>
                </div>
            </>
        )
    }
}