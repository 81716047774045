import { Component } from "react";
import { InputCurrency } from "../../../../helpers/formData";

export default class MargemInss extends Component {
    render() {
        return (
            <>
                <InputCurrency
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={"margem_de"}
                    label={'Margem de:'}
                    placeholder={"Margem de"}
                />
                <InputCurrency
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    name={"margem_ate"}
                    label={'Margem até'}
                    placeholder={"Margem até"}
                />
            </>
        )
    }
}