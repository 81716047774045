import React, { Component } from "react";
import { formatDate, formatarCPF, calcularIdade } from "../../../helpers/FuncHelpers";
export default class DadosCadastrais extends Component {
  render() {
    const { client } = this.props
    return (
      <>

        <div className="col-sm-6 col-xl-6">
          <div className="card">
            <div className="card-header b-l-primary">
              <h5><i className="icon-target"></i> Dados Cadastrais</h5>
            </div>
            <div className="card-body" style={{ padding: '25px' }}>
              <div className="d-flex justify-content-between align-items-center ficha_item">
                <div className="ficha_item_title">
                  <span>NOME</span>
                </div>
                <div className="ficha_item_desc">
                  <span>{client?.nome}</span>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center ficha_item">
                <div className="ficha_item_title">
                  <span>CPF</span>
                </div>
                <div className="ficha_item_desc">
                  <span>{formatarCPF(client?.doc)}</span>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center ficha_item">
                <div className="ficha_item_title">
                  <span>RG</span>
                </div>
                <div className="ficha_item_desc">
                  <span>{client?.rg_numero} / {client?.rg_ufEmissor}</span>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center ficha_item">
                <div className="ficha_item_title">
                  <span>IDADE</span>
                </div>
                <div className="ficha_item_desc">
                  <span>{calcularIdade(formatDate(client?.dataNascimento))} ANOS</span>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center ficha_item">
                <div className="ficha_item_title">
                  <span>NASCIMENTO</span>
                </div>
                <div className="ficha_item_desc">
                  <span>{formatDate(client?.dataNascimento)}</span>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center ficha_item">
                <div className="ficha_item_title">
                  <span>E-MAIL</span>
                </div>
                <div className="ficha_item_desc">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
