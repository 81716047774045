import { Component } from "react";
import { formatarValorEmDinheiro } from "../../../helpers/FuncHelpers";

export default class Margem extends Component {

    render() {

        const { beneficio } = this.props

        return (
            <>
                <div className="row">
                    <div className="col-sm-6 col-xl-2">
                        <div className="card">
                            <div className="card-body" style={{ padding: '25px' }}>
                                <h5 style={{ marginBottom: '5px' }}>VALOR BENEFÍCIO</h5>
                                <span className="value_beneficio">{beneficio?.valorBeneficio ? formatarValorEmDinheiro(beneficio?.valorBeneficio) : 0}</span>
                                <p className="value_aproximado">Aproximado.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-2">
                        <div className="card">
                            <div className="card-body" style={{ padding: '25px' }}>
                                <h5 style={{ marginBottom: '5px' }}>BASE DE CÁLCULO</h5>
                                <span className="value_beneficio">{beneficio?.margem_baseCalculoMargemConsignavel ? formatarValorEmDinheiro(beneficio?.margem_baseCalculoMargemConsignavel) : 0}</span>
                                <p className="value_aproximado">Aproximado.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 col-xl-2">
                        <div className="card">
                            <div className="card-body" style={{ padding: '25px' }}>
                                <h5 style={{ marginBottom: '5px' }}>MARGEM (35%)</h5>
                                <span className="text-success value_beneficio">{beneficio?.margem_margemDisponivelEmprestimo ? formatarValorEmDinheiro(beneficio?.margem_margemDisponivelEmprestimo) : 0}</span>
                                <p className="value_aproximado">Aproximado.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 col-xl-2">
                        <div className="card">
                            <div className="card-body" style={{ padding: '25px' }}>
                                <h5 style={{ marginBottom: '5px' }}>CARTÃO RMC</h5>
                                <span className="text-warning value_beneficio">{beneficio?.margem_margemDisponivelRmc ? formatarValorEmDinheiro(beneficio?.margem_margemDisponivelRmc) : 0}</span>
                                <p className="value_aproximado">Aproximado.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 col-xl-2">
                        <div className="card">
                            <div className="card-body" style={{ padding: '25px' }}>
                                <h5 style={{ marginBottom: '5px' }}>CARTÃO RCC</h5>
                                <span className="text-danger value_beneficio">{beneficio?.margem_margemDisponivelRcc ? formatarValorEmDinheiro(beneficio?.margem_margemDisponivelRcc) : 0}</span>
                                <p className="value_aproximado">Aproximado.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 col-xl-2">
                        <div className="card">
                            <div className="card-body" style={{ padding: '25px' }}>
                                <h5 style={{ marginBottom: '5px' }}>TOTAL CONTRATOS</h5>
                                <span className="value_beneficio">{beneficio?.quantidadeDeEmprestimos}</span>
                                <p className="value_aproximado">Aproximado.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>)
    }
}