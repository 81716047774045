import { Component } from "react";
import { InputCurrency } from "../../../../helpers/formData";

export default class CartaoRmcInss extends Component {
    render() {
        return (
            <>
                <InputCurrency
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    label={'Cartão RMC de:'}
                    name={"cartao_de"}
                    placeholder={"Cartão RMC de"}
                />
                <InputCurrency
                    classInput={"col-sm-6 col-md-6 col-xl-6"}
                    label={'Cartão RMC até'}
                    name={"cartao_ate"}
                    placeholder={"Cartão RMC até"}
                />
            </>
        )
    }
}