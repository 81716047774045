const formatDate = (isoData) => {
    const date = new Date(isoData);
    const dia = date.getDate().toString().padStart(2, '0');
    const mes = (date.getMonth() + 1).toString().padStart(2, '0'); // Os meses em JavaScript são baseados em zero
    const ano = date.getFullYear();
    const hora = date.getHours().toString().padStart(2, '0');
    const minutos = date.getMinutes().toString().padStart(2, '0');
    const segundos = date.getSeconds().toString().padStart(2, '0');

    return `${dia}/${mes}/${ano} às ${hora}:${minutos}:${segundos}`;
  }

  export default formatDate;