import React, { useEffect, useState } from 'react';
import { AnimationLottie } from "../../../animations/animationLottie";

const DashboardOperador = ({auth}) => {

    return (
        <>
            {!auth.ramal ? (
                <div className="alert alert-ligth" style={{backgroundColor: '#FFC107'}} role="alert">
                    <h4 className="alert-heading">
                        <i className="fa fa-exclamation-triangle fs-5" aria-hidden="true"></i>
                        Atenção!
                    </h4>
                    <h6>VOCÊ NÃO POSSUI UM RAMAL. ISSO PODE GERAR PROBLEMAS AO RECEBER CHAMADAS DA SUA DISCADORA.</h6>
                    <p>Acesse no canto superior direito clicando no nome do seu usuário {'>'} Ramal</p>
                    <hr />
                    <button className="mb-0 btn btn-dark">
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        CADASTRAR RAMAL AGORA
                    </button>
                </div>
            ) : ''}

                <div className="text-center">
                    <AnimationLottie fileJsonName="talk_call" height={350} />
                    <h5 className="fw-bolder text-uppercase mb-2 mt-1">Este painel estará disponível em breve! </h5>
                    Aguarde alguns dias para aproveitar todos os benefícios que ele proporcionará. <br/>
                    Estamos trabalhando duro para tornar essa experiência excepcional para você e sua equipe. <br/>
                    
                    <h6 className="mt-2">Fique atento às atualizações e novidades!</h6>
                </div>
            </>
        
    )
}

export default DashboardOperador;