import { Component } from "react";
import { Form, Input, BtnForm } from '../../../../helpers/formData'
import MessageDisplay from "../../../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../../../messages/load";

export default class UpdateDiscArgus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            urlIsValid: null
        }
        this.checkUrlIsValid = this.checkUrlIsValid.bind(this)
    }
    submitForm(e) {
        e.preventDefault();
        const msg = new MessageDisplay();

        const form = e.target;

        const formData = new FormData(form);
        const serializedForm = Object.fromEntries(formData.entries());
        startLoad();

        const tokenLocalStorage = localStorage.getItem("token");
        const tokenHash = localStorage.getItem("tokenHash");
        const login = localStorage.getItem("login");

        fetch(`${process.env.REACT_APP_HTTP}/teams-insert-discadora/${login}/${tokenHash}`, {
            method: 'POST',
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                authorization: `Bearer ${tokenLocalStorage}`
            },
            body: JSON.stringify({
                ...serializedForm
            })
        })
            .then((res) => res.json())
            .then(async (res) => {
                stopLoad()

                if (res.error) {
                    stopLoad();
                    return msg.error("Discadora", res.data);
                }

                const alertMessageNoParams = document.querySelector('#alert-argus-no-params');
                if (alertMessageNoParams != null)
                    alertMessageNoParams.classList.add('d-none');

                return msg.success("Discadora", 'Discadora Atualizada com Sucesso');
            })
            .catch((e) => {
                stopLoad();
                msg.error("Erro interno", `Não foi possível atualizar a discadora -> ${e}`);
            });
    }

    checkUrlIsValid(){
        const valueInputUrl = document.querySelector('.argus [name="url"]').value;
        console.log(valueInputUrl);
        
        const initValueInputUrl = valueInputUrl.slice(0, 4);

        if ( initValueInputUrl === 'http' &&
            (valueInputUrl.includes("http://") || valueInputUrl.includes("https://")) &&
            valueInputUrl.includes("uploadmailing") &&
            valueInputUrl.includes("apiargus")
        ){
            this.setState({ urlIsValid: true });
            this.fillInputsDemostrative(valueInputUrl)
            return
        }
        
        this.setState({ urlIsValid: false });
        this.clearInputsDemostrative()
    }

    clearInputsDemostrative(){
        document.querySelector('.argus [name="url_base"]').value = '';
        document.querySelector('.argus [name="fila"]').value = '';
    }

    fillInputsDemostrative(valueUrl){
        const urlSplited = valueUrl.split('/apiargus/');
        
        document.querySelector('.argus [name="url_base"]').value = urlSplited[0];
        document.querySelector('.argus [name="fila"]').value = urlSplited[1].replace('/uploadmailing', '');
    }

    render() {
        const { data } = this.props;
        const { urlIsValid } = this.state;
        const discadora = data?.discadoraArgus[0];
        return (
            <>
                <div className="col-xl-12 argus">
                    <Form title={'Discadora Argus'} onSubmit={(e) => this.submitForm(e)}>
                        {(!discadora?.url || discadora?.url === '') &&
                            <>
                            <div id="alert-argus-no-params" className="alert alert-info" role="alert">
                                Sua discadora Argus não está parametrizada. Adicione a <u>URL</u> e o <u>TOKEN</u> e salve as alterações
                            </div>
                            </>
                        }

                        { !urlIsValid && urlIsValid !== null &&
                            <>
                                <div className="alert alert-danger" role="alert">
                                    <h6><strong>Atenção!</strong> URL inválida. Siga o modelo de exemplo abaixo para parametrizar corretamente a sua discadora: </h6>
                                    Ex.: http://apioci.argus.app.br:123456/apiargus/bf1md9kgplnh1234/uploadmailing
                                </div>
                            </> 
                        }                        

                        <Input
                            autoFocus={true}
                            valueInput={discadora?.url}
                            classInput={"col-12"}
                            name={'url'}
                            label={'URL INTEGRAÇÃO (Endpoint para envio de mailing na fila/Skill)'}
                            onChange={() =>
                                this.checkUrlIsValid()
                            }
                            placeholder={"Ex.: http://apioci.argus.app.br:123456/apiargus/bf1md9kgplnh1234/uploadmailing"}
                        />
                        <Input
                            valueInput={discadora?.token}
                            classInput={"col-12"}
                            name={'token'}
                            label={'Token'}
                            onChange={() =>
                                this.checkUrlIsValid()
                            }
                            placeholder={"Token de autenticação da discadora"}
                        />

                        <div className="alert alert-ligth row rounded" role="alert" style={{backgroundColor: '#DEE2E6'}}>
                            <h6 className="text-center">Valores preenchidos automaticamente</h6>
                            <Input
                                readOnly={true}
                                valueInput={discadora?.url}
                                classInput={"col-6"}
                                name={'url_base'}
                                label={'URL (Endpoint base)'}
                                placeholder={"Preencha o valor da URL(primeiro campo)"}
                            />
                            <Input
                                readOnly={true}
                                valueInput={discadora?.fila}
                                classInput={"col-6"}
                                name={'fila'}
                                label={'Fila'}
                                placeholder={"Preencha o valor da URL(primeiro campo)"}
                            />
                        </div>
                        <Input
                            valueInput={'argus'}
                            name={'type_discadora'}
                            type={'hidden'}
                        />
                        <Input
                            valueInput={data?._id}
                            name={'team_id'}
                            type={'hidden'}
                        />
                        <BtnForm name={'Atualizar Argus'} disabled={(!urlIsValid)} title={'Salvar configurações da discadora'} />
                    </Form>
                </div>
            </>
        )
    }
}