import { Component } from "react";
import MessageDisplay from "../../../messages/messageDisplay";
import { startLoad, stopLoad } from "../../../messages/load";

export default class TotalLicenses extends Component {
    constructor(props) {
        super(props)

        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            totalLicense: 0,
            typePost: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.company !== prevProps.company) {
            const { company } = this.props;
            const totalLicense = company ? company.total_users : 0;
            this.setState({ totalLicense });
        }
    }

    decrease() {
        const total = document.getElementById('totalLicense').value
        if (total > 0) {
            this.setState(
                { totalLicense: Number(total) - 1, typePost: 'decrase' },
                () => this.submitLicenses()
            )
        }

    }

    async add() {
        const total = document.getElementById('totalLicense').value
        this.setState(
            { totalLicense: Number(total) + 1, typePost: 'add' },
            () => this.submitLicenses(total)
        );
    }

    async submitLicenses(total) {

        const { totalLicense, typePost } = this.state
        const { company } = this.props
        const id = company._id
        const msg = new MessageDisplay();
        const tokenLocalStorage = window.localStorage.getItem("token");
        const tokenHash = window.localStorage.getItem("tokenHash");
        const login = window.localStorage.getItem("login");
        console.log(typePost)

        startLoad();

        fetch(`${process.env.REACT_APP_HTTP}/add-decrase-license/${login}/${tokenHash}`, {
            method: "POST",
            crossDomain: true,
            headers: { "Content-Type": "application/json", "Accept": "application/json", authorization: `Bearer ${tokenLocalStorage}` },
            body: JSON.stringify({
                id,
                totalLicense,
                typePost
            }),
        })
            .then((res) => res.json())
            .then(async (res) => {
                stopLoad();

                if (res.error) {
                    this.setState(
                        { totalLicense: Number(totalLicense) - 1 }
                    )
                    return msg.error("Empresa", res.data);
                }

                return msg.success("Empresa", res.data);

            })
            .catch((e) => {
                console.log(e)
                stopLoad();
                msg.error("Server", "Not connect");
            });
    }


    render() {
        const { totalLicense } = this.state
        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h5>Total de Licenças</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">

                                    <div className="input-group input-group-lg bootstrap-touchspin">
                                        <button className="btn btn-primary btn-square bootstrap-touchspin-down" onClick={(e) => { this.decrease(e) }} type="button" data-bs-original-title="" title="">
                                            <i className="fa fa-minus"></i>
                                        </button>
                                        <span className="input-group-text bootstrap-touchspin-prefix" style={{ display: "none" }}>
                                        </span>
                                        <input disabled class="touchspin form-control" type="text" id={"totalLicense"} value={totalLicense} style={{ display: "block" }} data-bs-original-title="" title="" />
                                        <span class="input-group-text bootstrap-touchspin-postfix" style={{ display: "none" }}></span>
                                        <button class="btn btn-primary btn-square bootstrap-touchspin-up" type="button" onClick={(e) => { this.add(e) }} data-bs-original-title="" title=""><i class="fa fa-plus"></i></button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}