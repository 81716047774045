import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Badge, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SkeletonLoadDash from '../skeletonLoadDash'
import { faUsers, faUsersViewfinder, faBriefcase, faBusinessTime, faMinimize, faUpLong, faClockRotateLeft, faBuilding, faUserClock, faUsersRays } from '@fortawesome/free-solid-svg-icons'
import { ApiRequest } from "../../../helpers/Api";
import { AnimationLottie } from "../../../animations/animationLottie";

const DashboardWhiteLabel = () => {

    const [loading, setLoading] = useState(false);
    const [totalLicenses, setTotalLicenses] = useState(null);
    const [availableLicenses, setAvailableLicenses] = useState(null);
    const [inUseLicenses, setInUseLicenses] = useState(null);
    const [trialLicenses, setTrialLicenses] = useState(null);
    const [trialCompanies, setTrialCompanies] = useState(null);
    const [totalCompanies, setTotalCompanies] = useState(null);
    const [activeCompanies, setActiveCompanies] = useState(null);
    const [inactiveCompanies, setInactiveCompanies] = useState(null);
    const [rankingCompaniesMoreLicenses, setRankingCompaniesMoreLicenses] = useState(null);
    const [rankingCompaniesUpcomingRenovation, SetRankingCompaniesUpcomingRenovation] = useState(null);

    const setIndicatorsCompanies = (status, value) => {
        switch (status) {
            case 'all':
                setTotalCompanies(value)
                break;
            case 'active':
                setActiveCompanies(value)
                break;
            case 'inactive':
                setInactiveCompanies(value)
                break;
            case 'trial':
                setTrialCompanies(value)
                break;
            default:
                break;
        }
    }

    const setIndicatorsLicenses = (status, value) => {
        switch (status) {
            case 'all':
                setTotalLicenses(value)
                break;
            case 'in_use':
                setInUseLicenses(value)
                break;
            case 'trial':
                setTrialLicenses(value)
                break;
            default:
                break;
        }
    }

    const apiRequest = new ApiRequest();

    const companiesStatus = [
        'all',
        'active',
        'inactive',
        'trial',
    ]

    const licensesStatus = [
        'in_use',
        'all',
        'trial'
    ]

    const getIndicatorsDashboard = async () => {
        for (const status of companiesStatus) {
            const response = await apiRequest.get(`dash/whitelabel/getQuantifyCompaniesByStatus/${status}`, false);
            setIndicatorsCompanies(status, response.data);
        }

        for (const status of licensesStatus) {
            const response = await apiRequest.get(`dash/whitelabel/getQuantifyLicensesByStatus/${status}`, false);
            setIndicatorsLicenses(status, response.data);
        }

        setAvailableLicenses(totalLicenses - inUseLicenses);

        const responseCompaniesMoreLicenses = await apiRequest.get(`dash/whitelabel/getRankingCompaniesMoreLicenses`, false);
        setRankingCompaniesMoreLicenses(responseCompaniesMoreLicenses.data);
        // const responseCompaniesUpcomingRenovation = await apiRequest.get(`dash/whitelabel/getRankingCompaniesUpcomingRenovation/${status}`, false);
        // SetRankingCompaniesUpcomingRenovation(responseCompaniesUpcomingRenovation.data);
    }

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                await getIndicatorsDashboard();
                setLoading(false);
            } catch (error) {
                console.error('Erro ao buscar indicadores do painel:', error);
            }
        };

        fetchData();
    }, []);

    if (loading)
        return <>
            <SkeletonLoadDash />
        </>

    return (
        <>
            <div className="container-fluid gx-3">
                <div className='row'>

                    <div className='col-6'>

                        <div className='row'>
                            <div className='col'>
                                <Card>
                                    <CardBody className='py-4'>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col p-0 m-0'>
                                                <div className='rounded-circle ' style={{ border: '2px solid #198754', padding: '0.8rem', fontSize: '1.8rem', width: '4rem', height: '4rem' }}>
                                                    <FontAwesomeIcon icon={faUsers} style={{ color: '#198754', marginLeft: 'auto', marginRight: 'auto' }} />
                                                </div>
                                            </div>
                                            <div className='col-8 p-0 text-secondary fs-6 text-end'>
                                                <div className='fw-bolder fs-3'>{totalLicenses}</div>
                                                Licenças Totais
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>

                            <div className='col'>
                                <Card>
                                    <CardBody className='py-4'>
                                        <div className='row d-flex align-items-center'>
                                            <div className='col p-0 m-0'>
                                                <div className='rounded-circle ' style={{ border: '2px solid #20C997', padding: '0.8rem', fontSize: '1.8rem', width: '4rem', height: '4rem' }}>
                                                    <FontAwesomeIcon icon={faUsersRays} style={{ color: '#20C997', marginLeft: 'auto', marginRight: 'auto' }} />
                                                </div>
                                            </div>
                                            <div className='col-8 p-0 text-secondary fs-6 text-end'>
                                                <div className='fw-bolder fs-3'>{availableLicenses}</div>
                                                Licenças Disponíveis
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col'>
                                <Card>
                                    <CardBody className='py-4'>
                                        <div className='row'>
                                            <div className='col p-0 m-0'>
                                                <div className='rounded-circle d-flex align-items-center' style={{ border: '2px solid #FD7E14', padding: '0.8rem', fontSize: '1.8rem', width: '4rem', height: '4rem' }}>
                                                    <FontAwesomeIcon icon={faUsersViewfinder} style={{ color: '#FD7E14', marginLeft: 'auto', marginRight: 'auto' }} />
                                                </div>
                                            </div>
                                            <div className='col-8 p-0 text-secondary fs-6 text-end'>
                                                <div className='fw-bolder fs-3'>{inUseLicenses}</div>
                                                Licenças em uso
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className='col'>
                                <Card>
                                    <CardBody className='py-4'>
                                        <div className='row'>
                                            <div className='col p-0 m-0'>
                                                <div className='rounded-circle d-flex align-items-center' style={{ border: '2px solid #ADB5BD', padding: '0.8rem', fontSize: '1.8rem', width: '4rem', height: '4rem' }}>
                                                    <FontAwesomeIcon icon={faUserClock} style={{ color: '#ADB5BD', marginLeft: 'auto', marginRight: 'auto' }} />
                                                </div>
                                            </div>
                                            <div className='col-8 p-0 text-secondary fs-6 text-end text-truncate'>
                                                <div className='fw-bolder fs-3'>{trialLicenses}</div>
                                                Licenças teste disponíveis
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>

                    <div className='col'>
                        <div className='row'>
                            <div className='col'>
                                <Card>
                                    <CardBody className='py-4'>
                                        <div className='row'>
                                            <div className='col p-0 m-0'>
                                                <div className='rounded-circle d-flex align-items-center' style={{ border: '2px solid #3DD5F3', padding: '0.8rem', fontSize: '1.8rem', width: '4rem', height: '4rem' }}>
                                                    <FontAwesomeIcon icon={faBuilding} style={{ color: '#3DD5F3', marginLeft: 'auto', marginRight: 'auto' }} />
                                                </div>
                                            </div>
                                            <div className='col-8 p-0 text-secondary fs-6 text-end'>
                                                <div className='fw-bolder fs-3'>{totalCompanies}</div>
                                                Empresas totais
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>

                            <div className='col'>
                                <Card>
                                    <CardBody className='py-4'>
                                        <div className='row'>
                                            <div className='col p-0 m-0'>
                                                <div className='rounded-circle d-flex align-items-center' style={{ border: '2px solid #3D8BFD', padding: '0.8rem', fontSize: '1.8rem', width: '4rem', height: '4rem' }}>
                                                    <FontAwesomeIcon icon={faBriefcase} style={{ color: '#0A58CA', marginLeft: 'auto', marginRight: 'auto' }} />
                                                </div>
                                            </div>
                                            <div className='col-8 p-0 text-secondary fs-6 text-end'>
                                                <div className='fw-bolder fs-3'>{activeCompanies}</div>
                                                Empresas ativas
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col'>
                                <Card>
                                    <CardBody className='py-4'>
                                        <div className='row'>
                                            <div className='col p-0 m-0'>
                                                <div className='rounded-circle d-flex align-items-center' style={{ border: '2px solid #6C757D', padding: '0.8rem', fontSize: '1.8rem', width: '4rem', height: '4rem' }}>
                                                    <FontAwesomeIcon icon={faBusinessTime} style={{ color: '#6C757D', marginLeft: 'auto', marginRight: 'auto' }} />
                                                </div>
                                            </div>
                                            <div className='col-8 p-0 text-secondary fs-6 text-end'>
                                                <div className='fw-bolder fs-3'>{trialCompanies}</div>
                                                Empresas em teste
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>

                            <div className='col'>
                                <Card>
                                    <CardBody className='py-4'>
                                        <div className='row'>
                                            <div className='col p-0 m-0'>
                                                <div className='rounded-circle d-flex align-items-center' style={{ border: '2px solid #DC3545', padding: '0.8rem', fontSize: '1.8rem', width: '4rem', height: '4rem' }}>
                                                    <FontAwesomeIcon icon={faMinimize} style={{ color: '#DC3545', marginLeft: 'auto', marginRight: 'auto' }} />
                                                </div>
                                            </div>
                                            <div className='col-8 p-0 text-secondary fs-6 text-end'>
                                                <div className='fw-bolder fs-3'>{inactiveCompanies}</div>
                                                Empresas inativas
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col'>
                        <Card>
                            <CardBody className='py-4'>
                                <CardTitle tag="h5">
                                    Ranking Empresas
                                    <Badge className='float float-end' color="success" pill>
                                        {/* <FontAwesomeIcon icon={faUpLong} /> */}
                                        + Licenças
                                    </Badge>
                                </CardTitle>
                                <hr></hr>
                                {rankingCompaniesMoreLicenses &&
                                    rankingCompaniesMoreLicenses.length > 0
                                    ? (<>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        #
                                                    </th>
                                                    <th>
                                                        Empresa
                                                    </th>
                                                    <th>
                                                        CNPJ
                                                    </th>
                                                    <th>
                                                        Total licenças
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    rankingCompaniesMoreLicenses.map((company, index) =>
                                                    (

                                                        <tr key={company._id}>
                                                            <td>
                                                                {index + 1}
                                                            </td>
                                                            <td>
                                                                {company?.name}
                                                            </td>
                                                            <td>
                                                                {company?.cnpj}
                                                            </td>
                                                            <td>
                                                                {company?.total_users}
                                                            </td>
                                                        </tr>
                                                    )
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </>
                                    )
                                    : (
                                        <>
                                            <div className='text-danger text-center fs-6 mt-3'>Nenhum registro de empresas para ranquear</div>
                                        </>
                                    )
                                }

                            </CardBody>
                        </Card>
                    </div>
                    <div className='col'>
                        <Card>
                            <CardBody className='py-4'>
                                <CardTitle tag="h5">
                                    Ranking Empresas
                                    <Badge className='float float-end' color="warning" pill>
                                        {/* <FontAwesomeIcon icon={faClockRotateLeft} /> */}
                                        Renovação próxima
                                    </Badge>
                                </CardTitle>
                                <div className='text-center'>
                                    <AnimationLottie fileJsonName="robot_fly" height={310} />
                                    <h5 className='text-uppercase'>
                                        Em breve!
                                    </h5>
                                    Estamos desenvolvendo e mapeando essa informação, logo estará integrada ao seu painel
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>

            </div>
        </>
    )
}

export default DashboardWhiteLabel;