import { Component } from "react";
import { ApiRequest } from "../../../helpers/Api";
import MessageDisplay from "../../../messages/messageDisplay";

export default class TotalLicensesTrial extends Component {
    constructor(props) {
        super(props)
        this.state = {
            auth: JSON.parse(localStorage.getItem('user')),
            totalLicense: 0,
            typePost: false,
            _id: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.company !== prevProps.company) {
            const { company } = this.props;
            const totalLicense = company ? company.total_users_trial : 0;
            this.setState({ totalLicense, _id: company._id });
        }
    }

    decrease(e) {

        const total = document.getElementById('totalLicenseTrial').value
        if (total > 0) {
            this.setState(
                { totalLicense: Number(total) - 1, typePost: 'decrase' },
                () => this.submitLicenses()
            )
        }

    }

    async add(e) {

        const total = document.getElementById('totalLicenseTrial').value
        this.setState(
            { totalLicense: Number(total) + 1, typePost: 'add' },
            () => this.submitLicenses(total)
        );
    }

    async submitLicenses() {
        const msg = new MessageDisplay();
        const { totalLicense, typePost, _id } = this.state
        const { company } = this.props;

        console.log(_id)
        const api = new ApiRequest();
        const postData = {
            total: totalLicense,
            _id: _id
        }
        const result = await api.post(`add-decrase-license-test`, postData, false);
        if (result.error === true && result) {
            this.setState({ totalLicense: company ? company.total_users_trial : 0 })
            msg.error('Licenças', result.data);
            return;
        }

    }



    // async submitLicenses(total) {

    //     const { totalLicense, typePost } = this.state
    //     const { company } = this.props
    //     const id = company._id
    //     const msg = new MessageDisplay();
    //     const tokenLocalStorage = window.localStorage.getItem("token");
    //     const tokenHash = window.localStorage.getItem("tokenHash");
    //     const login = window.localStorage.getItem("login");


    //     startLoad();

    //     fetch(`${process.env.REACT_APP_HTTP}//${login}/${tokenHash}`, {
    //         method: "POST",
    //         crossDomain: true,
    //         headers: { "Content-Type": "application/json", "Accept": "application/json", authorization: `Bearer ${tokenLocalStorage}` },
    //         body: JSON.stringify({
    //             id,
    //             totalLicense,
    //             typePost
    //         }),
    //     })
    //         .then((res) => res.json())
    //         .then(async (res) => {
    //             stopLoad();

    //             if (res.error) {
    //                 this.setState(
    //                     { totalLicense: Number(totalLicense) - 1 }
    //                 )
    //                 return msg.error("Empresa", res.data);
    //             }

    //             return msg.success("Empresa", res.data);

    //         })
    //         .catch((e) => {
    //             stopLoad();
    //             msg.error("Server", "Not connect");
    //         });
    // }


    render() {
        const { totalLicense } = this.state

        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h5>Total de Licenças Teste</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">

                                    <div className="input-group input-group-lg bootstrap-touchspin">
                                        {/* <button className="btn btn-primary btn-square bootstrap-touchspin-down" onClick={(e) => { this.decrease(e) }} type="button" data-bs-original-title="" title="">
                                            <i className="fa fa-minus"></i>
                                        </button> */}
                                        <span className="input-group-text bootstrap-touchspin-prefix" style={{ display: "none" }}>
                                        </span>
                                        <input className="touchspin form-control" type="text" id={"totalLicenseTrial"} value={totalLicense} style={{ display: "block" }} data-bs-original-title="" title="" />
                                        <span className="input-group-text bootstrap-touchspin-postfix" style={{ display: "none" }}></span>
                                        <button className="btn btn-primary btn-square bootstrap-touchspin-up" type="button" onClick={(e) => { this.add(e) }} data-bs-original-title="" title=""><i className="fa fa-plus"></i></button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}