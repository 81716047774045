import { Component } from "react";
import LogoDefault from "../../assets/images/logo/login.png";
import MessageDisplay from "../messages/messageDisplay";
import { startLoad, stopLoad } from "../messages/load";
import ResetPassword from "./reset-password";
import { Helmet } from "react-helmet";
export default class ForgetPassWord extends Component {
    constructor(props) {
        super(props)
        this.state = {
            whitelabelSettings: JSON.parse(localStorage.getItem('whiteLabelSettings')),
            newComponent: '',
        }
        this.submitForm = this.submitForm.bind(this);
    }

    submitForm(e) {
        e.preventDefault();
        const msg = new MessageDisplay();

        const form = e.target;
        const formData = new FormData(form);
        const serializedForm = Object.fromEntries(formData.entries());
        startLoad();

        fetch(`${process.env.REACT_APP_HTTP}/users-forget-password`, {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                ...serializedForm
            }),
        })
            .then((res) => res.json())
            .then(async (res) => {
                stopLoad();
                if (res.error) {
                    return msg.error("Recuperar Senha", res.data);
                }

                if (!res.error) {
                    this.setState({
                        newComponent: <ResetPassword />
                    })
                    return msg.success('Recuperar Senha', res.data)
                }

            })
            .catch((e) => {
                stopLoad();
                msg.error("Server", "Not connect");
            });
    }

    render() {
        const { newComponent, whitelabelSettings } = this.state
        return (<>
            <Helmet>
                {/* <link rel="icon" type="image/png" href={whitelabelSettings?.logo ? whitelabelSettings?.logo : null} /> */}
                <title>{whitelabelSettings?.name ? whitelabelSettings?.name : 'RVXTECH'} - Recuperar Senha</title>
            </Helmet>
            <body className="light">
                <div className="tap-top">
                    <svg xmlns="http://www.w/3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="feather feather-chevrons-up"><polyline points="17 11 12 6 7 11"></polyline><polyline points="17 18 12 13 7 18"></polyline></svg></div>

                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-12">
                                <div className="login-card">
                                    <div>
                                        <div className="login-main">
                                            <div>
                                                <a className="logo text-start" href="./">
                                                    <img
                                                        className="img-fluid for-light"
                                                        src={whitelabelSettings?.logo ? whitelabelSettings.logo : LogoDefault}
                                                        style={{ width: "50%" }}
                                                        alt="looginpage"
                                                    />
                                                    <img
                                                        className="img-fluid for-dark"
                                                        src={whitelabelSettings?.logo ? whitelabelSettings.logo : LogoDefault}
                                                        alt="looginpage"
                                                    />
                                                </a>
                                            </div>
                                            {newComponent ? newComponent : (
                                                <form className="theme-form" onSubmit={this.submitForm}>
                                                    <h4 className="text-center" >Informe seu E-mail</h4>
                                                    <p className="text-center bg-warning p-2 mt-3">Apenas usuários <b>Administrativos</b> pode recuperar a senha</p>
                                                    <p className="text-center">Você receberá um código por <b>E-mail</b>, <b>WhatsApp</b> e <b>SMS</b> com um link para recuperar sua senha</p>
                                                    <div className="form-group">
                                                        <label className="col-form-label">E-mail</label>
                                                        <div className="form-input position-relative">
                                                            <input className="form-control" type="email" name="email" value={this.state.email} required="" placeholder="teste@teste.com.br" />
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="form-group mb-0">
                                                        <button className="btn btn-primary btn-block w-100" type="submit">Receber Código                         </button>
                                                    </div>
                                                </form>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </body>
        </>
        )
    }
}